import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { getPostDetails } from '../../../api/posts';
import {
  Box,
  CardMedia,
  CssBaseline,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import css from './BlogEditPage.module.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updatePostThunk } from '../../../store/posts/thunk';
import GoBackBtn from '../../../components/Buttons/GoBackBtn/GoBackBtn';
import BlogEditor from './BlogEditor';
import { postsSelector } from 'store/posts/selectors';

const BlogEditPage = () => {
  const { id } = useParams();
  // const [post, setPost] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  // const [prevImageUrl, setPrevImageUrl] = useState(img);
  // const [imageUrl, setImageUrl] = useState(img);
  const [image, setImage] = useState('');

  const { posts } = useSelector(postsSelector);
  const post = useMemo(() => posts.find(post => post._id === id), [posts, id]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const postsDetails = async () => {
  //     const data = await getPostDetails(id);
  //     setPost(data[0]);
  //   };
  //   postsDetails();
  // }, [id]);

  useEffect(() => {
    if (post) {
      setTitle(post.title);
      setBody(post.body);
      setImage(post.image);
    }
  }, [post]);

  const notify = () => toast.success('🦄 The post updated!', {});

  const handleSubmit = e => {
    e.preventDefault();

    if (!title || !body || !Image) {
      toast.error('🚫 Please fill in all fields.', {});
      return;
    }
    dispatch(
      updatePostThunk({
        id: post._id,
        body: { body, title, image },
      })
    );

    notify();
  };

  // const handleImageChange = e => {
  //   const selectedImage = e.target.files[0];

  //   if (selectedImage) {
  //     const newImageUrl = URL.createObjectURL(selectedImage);

  //     setPrevImageUrl(imageUrl);
  //     setImageUrl(newImageUrl);
  //   }
  // };

  // const handleCancelImage = () => {
  //   setImageUrl(prevImageUrl);
  // };

  return (
    <Box
      sx={{
        padding: {
          mobile: '90px 10px 60px 10px',
          tablet: '100px 10px 60px 10px',
          laptop: '110px 0px 0px 0px',
        },
      }}
    >
      <CssBaseline />
      <div className={css.btnWrap}>
        <GoBackBtn />
      </div>

      <div className={css.formWrap}>
        <div className={css.titleWrap}>
          <h2 className={css.title}>Відредагувати пост:</h2>
        </div>
        {post && (
          <form onSubmit={handleSubmit} className={css.form}>
            <p className={css.textLabel}>Змінити назву:</p>

            <TextareaAutosize
              id="outlined-basic"
              label="Змінити назву посту"
              variant="outlined"
              // onChange={({ target: { value } }) => setTitle(value)}
              onChange={({ target: { value } }) => setTitle(value)}
              value={title}
              className={css.text}
            />
            <p className={css.textLabel}>Змінити текст:</p>

            {/* <TextareaAutosize
              id="outlined-basic"
              label="Text"
              variant="outlined"
              onChange={({ target: { value } }) => setBody(value)}
              value={body}
              className={css.text}
              maxRows={10}
            /> */}
            <BlogEditor data={post.body} setData={setBody} />
            <p className={css.textLabel}>Змінити картинку:</p>
            {/* <div className={css.imgWrap}> */}
            <CardMedia
              component="img"
              width="240px"
              height="198px"
              src={post.image}
              alt="post image"
              className={css.image}
            />
            {/* </div> */}

            <TextField
              id="outlined-basic"
              label="Image"
              variant="outlined"
              onChange={({ target: { value } }) => setImage(value)}
              value={image}
              className={css.text}
              size="small"
            />

            {/* <div className={css.imgBtnWrap}>
              <label className={css.customFileInput}>
                Обрати файл
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className={css.fileInput}
                />
              </label>

              <button onClick={handleCancelImage} className={css.cancelBtn}>
                Відмінити вибір
              </button>
            </div> */}

            <div className={css.buttonWrap}>
              <button type="submit" className={css.button}>
                Update post
              </button>
            </div>
          </form>
        )}
      </div>
    </Box>
  );
};

export default BlogEditPage;
