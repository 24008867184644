import React from 'react';
import css from './QuestionCards.module.css';
import RadioGroupTest from './RadioGroupTest';

const QuestionCards = ({
  questions,
  isTestEnded,
  updateStatistics,

}) => {
  return (
    <div className={css.quiz}>
      {questions.map((question, index) => {
        return (
          <RadioGroupTest
            key={question.question}
            question={question.question}
            questionIndex={index}
            options={question.options}
            correctAnswer={question.correctAnswer}
            isTestEnded={isTestEnded}
            updateStatistics={updateStatistics}
          />
        );
      })}
    </div>
  );
};

export default QuestionCards;
