import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#e0e3e8',
      yellow: '#f8bc02',
      main: '#364f6a',
      dark: '#94a4b2',
      blue: '#90ADC6',
      red: '#A53F2B',
      green: '#399E5A',
      orange: '#FF8811',

      contrastText: '#90ADC6',
    },
    secondary: {
      light: '#f8bb0278',
      main: '#f8bc02',
      dark: '#f8bc02',
      contrastText: '#ffcc00',
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#e0e3e8',
      main: '#f8bc02',
      dark: '#94a4b2',
      red: '#f8bc02',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      mobile: 0,
      iphone: 390,
      tablet: 768,
      laptop: 1280,
      desktop: 1400,
    },
  },
});
