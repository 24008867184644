import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson8 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. Ochenta + cincuenta y tres son:',
      options: [
        'ciento veintitrés',
        'ciento treinta y tres',
        'ciento cuarenta y tres',
      ],
      correctAnswer: 'ciento treinta y tres',
    },
    {
      question: '2. Quinientos - trescientos sesenta y ocho son:',
      options: [
        'ochocientos cincuenta y nueve',
        'ciento veinte y cinco',
        'ciento veintidós',
      ],
      correctAnswer: 'ciento veintidós',
    },
    {
      question: '3. Cada mañana ... a las 7 y me ducho.',
      options: ['me levanto', 'te levantas', 'se levanta'],
      correctAnswer: 'me levanto',
    },
    {
      question: '4. Vosotros:',
      options: ['se despiertan', 'os despertáis', 'se despierten'],
      correctAnswer: 'os despertáis',
    },
    {
      question: '5. Me despierto, me levanto y voy a ducharme.',
      options: ['tú', 'él', 'yo'],
      correctAnswer: 'yo',
    },
    {
      question: '6. En el restaurante yo ... .',
      options: ['pido', 'pedo', 'pedimos'],
      correctAnswer: 'pido',
    },
    {
      question: '7. María ... al tenis.',
      options: ['juegas', 'juega', 'jugáis'],
      correctAnswer: 'juega',
    },
    {
      question:
        '8. Cien, doscientos, trescientos, cuatrocientos, quinientos, seiscientos, ... , ochocientos, ...',
      options: ['1000, 300', '100, 100', '700, 1000'],
      correctAnswer: '700, 1000',
    },
    {
      question: '9. Cuando me veo con mi familia, ... mucho.',
      options: ['nos reímos', 'seguimos', 'perseguimos'],
      correctAnswer: 'nos reímos',
    },
    {
      question: '10. ... la chaqueta y me siento a la mesa.',
      options: ['sigo', 'juego', 'me quito'],
      correctAnswer: 'me quito',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson8;
