import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userToggleWordThunk } from '../../../store/auth/thunks';
import { Box, CssBaseline, Stack, Typography } from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Audio from '../Audio/Audio';
import { getAllWordsThunk } from '../../../store/dictionary/thunk';
import useLocales from '../../../hooks/useLocales';
import { theme } from '../../../theme/theme';

const IsLearned = () => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const handleClick = id => {
    dispatch(userToggleWordThunk({ id, remove: true }));
  };

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  const { currentLang } = useLocales();

  return (
    <Box sx={{ pt: { mobile: '30px' } }}>
      <CssBaseline />

      {user.dictionary.isLearned.map(word => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          key={word._id}
          sx={{ padding: { mobile: '1px 0px', tablet: '2px 0px' } }}
        >
          <LibraryAddIcon
            sx={{ color: theme.palette.primary.blue }}
            onClick={() => handleClick(word._id)}
          />

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { mobile: '16px', tablet: '17px', laptop: '18px' },
              color: theme.palette.primary.main,
            }}
          >
            {word.word}
          </Typography>
          <Typography>-</Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { mobile: '16px', laptop: '18px' },
            }}
          >
            {word[`translation_${currentLang.value}`]}
          </Typography>
        </Stack>
      ))}
      <Audio />
    </Box>
  );
};

export default IsLearned;
