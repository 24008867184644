import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson2 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. ¿Cómo te llamas?',
      options: ['Muy bien', 'Me llamo Pedro', 'Buenos días'],
      correctAnswer: 'Me llamo Pedro',
    },
    {
      question: translate('lesson2.test.question1.title'),
      options: ['americano', 'español', 'estadounidense'],
      correctAnswer: 'estadounidense',
    },
    {
      question: '3. - ¡Buenas tardes!, - ¡Muy buenas!, - ¿Qué tal? - ...',
      options: ['Genial', 'Buenas noches', 'No lo sé'],
      correctAnswer: 'Genial',
    },
    {
      question: '4. vida',
      options: ['el', 'la', 'las'],
      correctAnswer: 'la',
    },
    {
      question: '5. el',
      options: [
        'vida, libro, trabajo',
        'agua, hada, alma',
        'trabajo, foto, niños',
      ],
      correctAnswer: 'agua, hada, alma',
    },
    {
      question: '6. la',
      options: ['amigo, matemáticas', 'foto, vida', 'italianas, ucraniana'],
      correctAnswer: 'foto, vida',
    },
    {
      question: '7. ... María, soy Pedro. ¡Mucho gusto en conocerte!',
      options: ['Hola', 'Hasta luego', 'Nos vemos'],
      correctAnswer: 'Hola',
    },
    {
      question: translate('lesson2.test.question8.title'),
      options: ['bélgica', 'belga', 'bélgico'],
      correctAnswer: 'belga',
    },
    {
      question: translate('lesson2.test.question9.title'),
      options: ['Encantado/a', 'Mucho gusto', 'Igualmente'],
      correctAnswer: 'Igualmente',
    },
    {
      question: translate('lesson2.test.question10.title'),
      options: [
        translate('lesson2.test.question10.option1'),
        translate('lesson2.test.question10.option2'),
        translate('lesson2.test.question10.option3'),
      ],
      correctAnswer: translate('lesson2.test.question10.option3'),
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson2;
