import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson5 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. Tengo sueño',
      options: [
        translate('lesson5.test.question1.option1'),
        translate('lesson5.test.question1.option2'),
        translate('lesson5.test.question1.option3'),
      ],
      correctAnswer: translate('lesson5.test.question1.option2'),
    },
    {
      question: '2. María y Lucía ... al supermercado y ... mucha comida.',
      options: ['vamos, compramos', 'vais, compráis', 'van, compran'],
      correctAnswer: 'van, compran',
    },
    {
      question: '3. Ellos pasean ... el parque.',
      options: ['al', 'para', 'por'],
      correctAnswer: 'por',
    },
    {
      question: '4. Pedro habla ... su hermano.',
      options: ['para', 'con', 'al'],
      correctAnswer: 'con',
    },
    {
      question: '5. Nosotros ... muchos textos y leemos muchos libros.',
      options: ['escribo', 'escribís', 'escribimos'],
      correctAnswer: 'escribimos',
    },
    {
      question: '6. Mi amigo tiene ... de vivir solo.',
      options: ['hambre', 'ganas', 'sed'],
      correctAnswer: 'ganas',
    },
    {
      question: '7. Tenéis una casa grande y bonita. Tenéis mucha ...',
      options: ['suerte', 'miedo', 'prisa'],
      correctAnswer: 'suerte',
    },
    {
      question: '8. Yo ... un cuaderno',
      options: ['tomo', 'tomas', 'toma'],
      correctAnswer: 'tomo',
    },
    {
      question: '9. Estudias ... la Universidad.',
      options: ['sobre', 'conmigo', 'en'],
      correctAnswer: 'en',
    },
    {
      question: translate('lesson5.test.question10'),
      options: ['Vosotros', 'Ustedes', 'Nosotros'],
      correctAnswer: 'Nosotros',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson5;
