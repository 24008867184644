import React, { useCallback, useEffect, useState } from 'react';
import css from './QuestionCards.module.css';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const RadioGroupTest = ({
  question,
  options,
  correctAnswer,
  isTestEnded,
  questionIndex,
  updateStatistics,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isCorrect, setIsCorrect] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [isAnswered, setIsAnswered] = useState(false);

  const [answeredApi, setAnsweredApi] = useState('');

  const number = 'test1';

  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = arrPathname[2];
  const lesson = arrPathname[3];

  useEffect(() => {
    setAnsweredApi(user.exercises[module]?.[lesson]?.[number]?.[question]);
  }, [lesson, module, number, question, user]);

  const sendOption = useCallback(
    option => {
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: {
            ...user.exercises,
            [module]: {
              ...(user.exercises[module] || {}),
              [lesson]: {
                ...(user.exercises[module]?.[lesson] || {}),
                [number]: {
                  ...(user.exercises[module]?.[lesson]?.[number] || {}),
                  [question]: option,
                },
              },
            },
          },
        })
      );
    },
    [dispatch, lesson, module, question, user.email, user.exercises]
  );

  const handleRadioChange = event => {
    const selectedAnswer = event.target.value;
    setSelectedOption(selectedAnswer);
    // setIsAnswered(true);
    sendOption(selectedAnswer);

    if (selectedAnswer === correctAnswer) {
      setIsCorrect(true);
    }
    updateStatistics(selectedAnswer, correctAnswer, questionIndex);
  };

  useEffect(() => {
    if (answeredApi) {
      setSelectedOption(answeredApi);
      //  getOptionColor(answeredApi);
    }
  }, [answeredApi]);

  return (
    <div className={css.options}>
      <FormControl>
        <FormLabel
          id={`question-${questionIndex}`}
          sx={{
            marginLeft: { mobile: '2px' },
            marginBottom: { mobile: '8px' },

            display: { mobile: 'flex' },
            flexWrap: { mobile: 'wrap' },
          }}
        >
          {question}
        </FormLabel>
        <RadioGroup
          aria-labelledby={`question-${questionIndex}`}
          value={selectedOption}
          isCorrect={isCorrect}
          onChange={handleRadioChange}
          disabled={isTestEnded}
        >
          {options.map(option => (
            <FormControlLabel
              key={option}
              value={option}
              control={
                <Radio
                  sx={{
                    color: isTestEnded
                      ? option === correctAnswer
                        ? 'green'
                        : 'red'
                      : '#90adc6',
                    '&.Mui-checked': {
                      color: isTestEnded
                        ? option === correctAnswer
                          ? 'green'
                          : 'red'
                        : '#90adc6',
                    },
                  }}
                  disabled={isTestEnded}
                />
              }
              label={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioGroupTest;
