import css from './Lesson1.module.css';
import commonStyles from '../../LessonsPage.module.css';

export default function LessonVideo() {
  return (
    <div className="responsive-video">
      {/* <video ref={videoRef} width="560" height="415" src="https://www.youtube.com/embed/wgbV6DLVezo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></video> */}
      {/* <iframe
        title="123"
        ref={iframeRef}
        width="560"
        height="415"
        src="https://www.youtube.com/embed/wgbV6DLVezo?enablejsapi=1"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}

      {/* <iframe
        width="738"
        height="415"
        ref={iframeRef}
        src="https://www.youtube-nocookie.com/embed/Z8arD6qjeGM?si=Punbc9SgNkE6GW8G"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe> */}

      <div>
        <div className={css.vimeo}>
          <iframe
            src="https://player.vimeo.com/video/865953957?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            width="738"
            height="415"
            allow="autoplay; fullscreen; picture-in-picture"
            className={commonStyles.lessonVideo}
            title="Урок 1. Алфавіт"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </div>
  );
}
