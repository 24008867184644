import useLocales from 'hooks/useLocales';
import TryCounter from '../../TryCounter/TryCounter';
import style from './checkAnswerBtn.module.css';

const CheckAnswerBtn = ({ tryCounter, onCheckBtnClick, disabled = false }) => {
  const { translate } = useLocales();

  return (
    <div className={style.checkBoxContainer}>
      <button
        type="button"
        className={style.checkBtn}
        onClick={onCheckBtnClick}
        disabled={disabled}
      >
        {translate('checkBtn')}
      </button>
      <TryCounter tryCounter={tryCounter} />
    </div>
  );
};

export default CheckAnswerBtn;
