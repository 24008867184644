import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate из React Router
import { Stack, SwipeableDrawer, Typography } from '@mui/material';
import useLocales from 'hooks/useLocales';
import { CustomLessonsButton } from 'customized/customized';
import ListIcon from '@mui/icons-material/List';

export default function SwipeableTemporaryDrawer({ anchor }) {
  const [state, setState] = useState({
    lessons: false,
  });

  const { translate } = useLocales();
  const lessons = translate('footer.lessons');

  const appRoutes = [
    {
      path: '/lessons/lesson1/1',
      displayText: translate('footer.lesson') + ' 1',
    },
    {
      path: '/lessons/lesson2/1',
      displayText: translate('footer.lesson') + ' 2',
    },
    {
      path: '/lessons/lesson3/1',
      displayText: translate('footer.lesson') + ' 3',
    },
    {
      path: '/lessons/lesson4/1',
      displayText: translate('footer.lesson') + ' 4',
    },
    {
      path: '/lessons/lesson5/1',
      displayText: translate('footer.lesson') + ' 5',
    },
    {
      path: '/lessons/lesson6/1',
      displayText: translate('footer.lesson') + ' 6',
    },
    {
      path: '/lessons/lesson7/1',
      displayText: translate('footer.lesson') + ' 7',
    },
    {
      path: '/lessons/lesson8/1',
      displayText: translate('footer.lesson') + ' 8',
    },
    {
      path: '/lessons/lesson9/1',
      displayText: translate('footer.lesson') + ' 9',
    },
    {
      path: '/lessons/lesson10/1',
      displayText: translate('footer.lesson') + ' 10',
    },
    {
      path: '/lessons/lesson11/1',
      displayText: translate('footer.lesson') + ' 11',
    },
    {
      path: '/lessons/lesson12/1',
      displayText: translate('footer.lesson') + ' 12',
    },
  ];

  const navigate = useNavigate();

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(prevState => ({ ...prevState, [anchor]: open }));
  };

  const container = document.body;
  // window !== undefined ? () => window().document.body : undefined;

  const openLessonsListBtn = anchor => (
    <Box
      sx={{ width: { mobile: '100%' } }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <SwipeableDrawer
        sx={{
          width: { mobile: '100%' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: { mobile: '100%' },
            boxSizing: 'border-box',
            borderRight: '0px',
            backgroundColor: '#94a4b2',
            color: 'white',
            padding: { mobile: '86px 20px 90px 20px' },
            zIndex: 999,
          },
        }}
        container={container}
        anchor="bottom"
        open={true}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={56}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {/* <Drawer
        variant="permanent"
        sx={{
          width: { mobile: '100%' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: { mobile: '100%' },
            boxSizing: 'border-box',
            borderRight: '0px',
            backgroundColor: '#94a4b2',
            color: 'white',
            padding: { mobile: '86px 20px 90px 20px' },
            zIndex: 999,
          },
        }}
      > */}
        <List disablePadding>
          {appRoutes.map((route, index) => (
            <Button
              key={index}
              onClick={() => navigate(route.path)}
              variant="text"
              color="primary"
              fullWidth
            >
              <Typography
                sx={{ color: 'white', marginTop: '4px', fontSize: '20px' }}
              >
                {route.displayText}
              </Typography>
            </Button>
          ))}
        </List>
      </SwipeableDrawer>

      {/* </Drawer> */}
    </Box>
  );

  return (
    <div>
      {[lessons].map(anchor => (
        <React.Fragment key={anchor}>
          <Stack
            sx={{
              display: { mobile: 'flex' },
              flexDirection: { mobile: 'row' },
              justifyContent: { mobile: 'center' },
              alignItems: { mobile: 'center' },
            }}
          >
            <CustomLessonsButton
              onClick={toggleDrawer(anchor, true)}
              sx={{
                display: { mobile: 'flex' },
                justifyContent: { mobile: 'flex-start' },
                alignItems: { mobile: 'center' },
                padding: { mobile: '0px 4px' },
                fontSize: { mobile: '16px' },
                fontWeight: { mobile: '600' },
                gap: { mobile: '10px' },
              }}
            >
              <ListIcon />
              {anchor}
            </CustomLessonsButton>
          </Stack>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {openLessonsListBtn(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
