import { Box, CssBaseline, InputLabel, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import { createWord } from '../../api/dictionaries';
import css from './SettingsPage.module.css';
import { toast } from 'react-toastify';
import { createPost } from '../../api/posts';
import useLocales from 'hooks/useLocales';

const SettingsPage = () => {
  const [word, setWord] = useState('');
  const [translation_ua, setTranslation_ua] = useState('');
  const [translation_pl, setTranslation_pl] = useState('');
  const [translation_en, setTranslation_en] = useState('');
  const [translation_ru, setTranslation_ru] = useState('');
  const [lesson, setLesson] = useState('');

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState('');

  const { translate } = useLocales();

  // const [favorite, setFavorite] = useState(false);
  // const [image, setImage] = useState('');

  const notifyWordAdded = () =>
    toast.success(
      translate('settingsPage.addWordToDictionary.notifySuccess'),
      {}
    );

  const notifyWordNotAdded = () => toast.error(translate('errorMessage'), {});

  const notifyPostAdded = () =>
    toast.success(translate('settingsPage.addPost.notifySuccess'), {});

  const notifyPostNotAdded = () => toast.error(translate('errorMessage'), {});

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !word ||
      !translation_ua ||
      !translation_pl ||
      !translation_en ||
      !translation_ru ||
      !lesson
    ) {
      toast.error(translate('settingsPage.addWordToDictionary.notifyError'), {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    try {
      await createWord({
        word,
        translation_ua,
        translation_pl,
        translation_en,
        translation_ru,
        lesson,
      });

      setWord('');
      setTranslation_ua('');
      setTranslation_pl('');
      setTranslation_en('');
      setTranslation_ru('');
      setLesson('');

      notifyWordAdded();
    } catch (error) {
      console.error('Error adding word to dictionary:', error);

      // Вызываем функцию для отображения сообщения об ошибке
      notifyWordNotAdded();
    }
  };

  const handleSubmitPost = async e => {
    e.preventDefault();

    if (!title || !body || !image) {
      toast.error(translate('settingsPage.addWordToDictionary.notifyError'), {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    try {
      await createPost({
        title,
        body,
        image,
      });
      setTitle('');
      setBody('');
      setImage('');

      notifyPostAdded();
    } catch (error) {
      console.error('Error adding post:', error);

      notifyPostNotAdded();
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: { mobile: '80px 0px', laptop: '100px 0px' },
        }}
      >
        <CssBaseline />
        <div className={css.titleWrap}>
          <h2 className={css.title}>
            {translate('settingsPage.addWordToDictionary.task')}
          </h2>
        </div>
        <form onSubmit={handleSubmit} className={css.form}>
          <div className={css.textField}>
            <TextField
              id="outlined-basic"
              label={translate('settingsPage.addWordToDictionary.word')}
              variant="outlined"
              onChange={({ target: { value } }) => setWord(value)}
              value={word}
              className={css.text}
              size="small"
            />
            <div className={css.textFieldWrap}>
              <TextField
                id="outlined-basic"
                label={translate(
                  'settingsPage.addWordToDictionary.translationUA'
                )}
                variant="outlined"
                onChange={({ target: { value } }) => setTranslation_ua(value)}
                value={translation_ua}
                className={css.text}
                size="small"
              />
              <TextField
                id="outlined-basic"
                label={translate(
                  'settingsPage.addWordToDictionary.translationPL'
                )}
                variant="outlined"
                onChange={({ target: { value } }) => setTranslation_pl(value)}
                value={translation_pl}
                className={css.text}
                size="small"
              />
              <TextField
                id="outlined-basic"
                label={translate(
                  'settingsPage.addWordToDictionary.translationEN'
                )}
                variant="outlined"
                onChange={({ target: { value } }) => setTranslation_en(value)}
                value={translation_en}
                className={css.text}
                size="small"
              />
              <TextField
                id="outlined-basic"
                label={translate(
                  'settingsPage.addWordToDictionary.translationRU'
                )}
                variant="outlined"
                onChange={({ target: { value } }) => setTranslation_ru(value)}
                value={translation_ru}
                className={css.text}
                size="small"
              />
            </div>
            <InputLabel htmlFor="grouped-native-select">
              {translate('settingsPage.addWordToDictionary.selectLesson')}
            </InputLabel>
            <Select
              native
              id="grouped-native-select"
              label={translate('settingsPage.addWordToDictionary.lesson')}
              onChange={({ target: { value } }) => setLesson(value)}
              value={lesson}
              className={css.select}
              size="small"
            >
              <option aria-label="lesson" value="" />
              {/* <optgroup label="1">
                <option value={'1.1'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} 1.1
                </option>
                <option value={'1.2'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} 1.2
                </option>
                <option value={'dígrafos'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Dígrafos
                </option>
                <option value={'el acento'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} El
                  acento
                </option>
              </optgroup> */}
              {/* <optgroup label="2">
                <option value={'el artículo'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} El
                  artículo
                </option>
                <option value={'el orden de las palabras en la oración'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} El
                  orden de las palabras en la oración
                </option>
                <option value={'saludos y despedidas'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Saludos
                  y despedidas
                </option>
                <option value={'países y nacionalidad'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Países
                  y nacionalidad
                </option>
              </optgroup> */}
              <optgroup label="3">
                <option value={'ser'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Ser
                </option>
                {/* <option value={'los nùmeros'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Los
                  nùmeros
                </option> */}
                <option value={'llamarse'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  LLamarse
                </option>
                <option value={'profesiones'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Profesiones
                </option>
              </optgroup>

              <optgroup label="4">
                <option value={'género y número de sustantivos'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Género
                  y número de sustantivos
                </option>
                <option value={'genero y número de adjetivos'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Genero
                  y número de adjetivos
                </option>
              </optgroup>

              <optgroup label="5">
                <option value={'presente de indicativo'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Presente de indicativo
                </option>
                <option value={'preposiciones'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Preposiciones
                </option>
                <option value={'verbo tener'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbo
                  tener
                </option>
              </optgroup>

              <optgroup label="6">
                <option value={'oraciones interrogativas'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Oraciones interrogativas
                </option>
                <option value={'forma impersonal hay'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Forma
                  impersonal hay
                </option>
                <option value={'verbos irregulares1'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  irregulares 1
                </option>
              </optgroup>

              <optgroup label="7">
                <option value={'pronombres posesivos y demostrativos'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Pronombres posesivos y demostrativos
                </option>
                <option value={'grados de comparación'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Grados
                  de comparación
                </option>
                <option value={'construcciones gramaticales'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Construcciones gramaticales
                </option>
                <option value={'verbos irregulares2'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  irregulares 2
                </option>
              </optgroup>

              <optgroup label="8">
                <option value={'nùmeros 10-100'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Nùmeros
                  10-100
                </option>
                <option value={'nùmeros 100-1000'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Nùmeros
                  100-1000
                </option>
                <option value={'verbos reflexivos'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  reflexivos
                </option>
                <option value={'verbos irregulares3-4'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  irregulares 3-4
                </option>
              </optgroup>

              <optgroup label="9">
                <option value={'objeto directo e indirecto'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Objeto
                  directo e indirecto
                </option>
                <option value={'verbos irregulares 5-6'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  irregulares 5-6
                </option>
              </optgroup>

              <optgroup label="10">
                <option value={'pronombres y adverbios negativos y relativos'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Pronombres y adverbios negativos y relativos
                </option>
                <option value={'verbos irregulares 7'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  irregulares 7
                </option>
              </optgroup>

              <optgroup label="11">
                <option value={'gerundio, construcciones con gerundio'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Gerundio, construcciones con gerundio
                </option>
                <option value={'también, tampoco'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  También, tampoco
                </option>
                <option value={'verbos irregulares 8-9'}>
                  {translate('settingsPage.addWordToDictionary.lesson')} Verbos
                  irregulares 8-9
                </option>
              </optgroup>

              <optgroup label="12">
                <option value={'pretérito perfecto de indicativo'}>
                  {translate('settingsPage.addWordToDictionary.lesson')}{' '}
                  Pretérito perfecto de indicativo
                </option>
              </optgroup>
            </Select>
          </div>
          <button type="submit" className={css.button}>
            {translate('settingsPage.addWordToDictionary.button')}
          </button>
        </form>

        <div className={css.titleWrap}>
          <h2 className={css.title}>
            {translate('settingsPage.addPost.task')}
          </h2>
        </div>
        <form onSubmit={handleSubmitPost} className={css.form}>
          <div className={css.textField}>
            <TextField
              id="outlined-basic"
              label={translate('settingsPage.addPost.title')}
              variant="outlined"
              onChange={({ target: { value } }) => setTitle(value)}
              value={title}
              className={css.text}
              size="small"
            />
            <div className={css.textFieldWrap}>
              <TextField
                id="outlined-basic"
                label={translate('settingsPage.addPost.text')}
                variant="outlined"
                onChange={({ target: { value } }) => setBody(value)}
                value={body}
                className={css.text}
                size="small"
              />
            </div>
            <TextField
              id="outlined-basic"
              label={translate('settingsPage.addPost.image')}
              variant="outlined"
              onChange={({ target: { value } }) => setImage(value)}
              value={image}
              className={css.text}
              size="small"
            />
          </div>
          <button type="submit" className={css.button}>
            {translate('settingsPage.addPost.button')}
          </button>
        </form>
      </Box>
    </>
  );
};

export default SettingsPage;
