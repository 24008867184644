import { useCallback, useEffect, useMemo, useState } from 'react';
import { BoxWithLetter } from './BoxWithLetter/BoxWithLetter';
import {
  createLetterBoxesState,
  createCorrectAnswer,
} from '../helpers/createLetterBoxesState';
import { setTryStatus } from '../helpers/setTryStatus';
import style from './SortableLetters.module.css';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const CreateWord = ({ correctlyAnswer, letters, title, number }) => {
  const [letterBoxes, setLetterBoxes] = useState(
    createLetterBoxesState(letters)
  );

  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);

  const [id, setId] = useState(1);
  const [currentCard, setCurrentCard] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const userAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[title];

    if (userAnswer) {
      setTryCounter(Object.values(userAnswer)[0].tryCounterData);

      if (Object.keys(userAnswer)[0] === correctlyAnswer) {
        setLetterBoxes(createCorrectAnswer(correctlyAnswer));
      }
    }
  }, [user, module, lesson, number, title, letters, correctlyAnswer]);

  const onSelectedLetterClick = card => {
    if (!card.draggable) return;
    setCurrentCard(card);

    if (currentCard) {
      setLetterBoxes(prevLetterBoxes => {
        return prevLetterBoxes.map(item => {
          if (item.id === card.id) {
            return { ...item, order: currentCard.order };
          }
          if (item.id === currentCard.id) {
            return { ...item, order: card.order };
          }
          return item;
        });
      });
      setCurrentCard(null);
    }
  };

  const onCheckBtnClick = () => {
    const userAnswer = letterBoxes.map(item => item.text).join('');
    if (correctlyAnswer === userAnswer) {
      // setLetterBoxes(createCorrectAnswer(correctlyAnswer));

      setTryCounter(setTryStatus(id, true));
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
      // Обновите свойство draggable в letterBoxes
      const updatedLetterBoxes = letterBoxes.map(item => ({
        ...item,
        draggable: false,
      }));
      setLetterBoxes(updatedLetterBoxes);
    } else {
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [title]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[title] || {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[title]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, title, user.exercises]
  );

  useEffect(() => {
    const showAnswer = () =>
      tryCounter.some(item => item.tryStatus === true) ||
      tryCounter.every(item => item.tryStatus === false);

    const checkAnswer = showAnswer();

    if (checkAnswer) {
      setLetterBoxes(createCorrectAnswer(correctlyAnswer));
    }
  }, [tryCounter, correctlyAnswer]);

  const sortedLetterBoxes = letterBoxes.sort((a, b) => a.order - b.order);

  return (
    <div className={style.container}>
      <div className={style.titleWrap}>
        <p className={style.title}>{title}</p>
      </div>

      <div className={style.boxContainer}>
        {sortedLetterBoxes.map((item, index) => (
          <BoxWithLetter
            key={item.id}
            item={item}
            index={index}
            onSelectedLetterClick={onSelectedLetterClick}
            isSelected={currentCard === item}
          />
        ))}
      </div>
      <CheckAnswerBtn
        tryCounter={tryCounter}
        onCheckBtnClick={onCheckBtnClick}
      />
    </div>
  );
};

export default CreateWord;
