import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import bag from '../../../../assets/images/lesson5/bag.png';
import bocadillo from '../../../../assets/images/lesson5/bocadillo.png';
import car from '../../../../assets/images/lesson5/car.png';
import cartera from '../../../../assets/images/lesson5/cartera.png';
import mochila from '../../../../assets/images/lesson5/mochila.png';
import park from '../../../../assets/images/lesson5/park.png';
import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
// import AddWordsTo3Containers from 'components/Exercises/AddWordsTo3Containers/AddWordsTo3Containers';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import SelectWordsForDialog from 'components/Exercises/SelectWordsForDialog/SelectWordsForDialog';
import SortableWords from 'components/Exercises/SortableWords/SortableWords';
import Move6WordsToText from 'components/Exercises/Move6WordsToText/Move6WordsToText';
import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ChooseLetters from 'components/Exercises/ChooseLetters/ChooseLetters';
import ChooseSameLetters from 'components/Exercises/ChooseSameLetters/ChooseSameLetters';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';

// WriteWordToImage
const images = [bag, bocadillo, car, cartera, mochila, park];

const correctWords = [
  'el bolso',
  'el bocadillo',
  'el coche',
  'la cartera',
  'la mochila',
  'el parque',
];

// ChooseLetters
const correctLetter2InContainer1 = ['a'];
const correctLetter2InContainer2 = ['de'];
const correctLetter2InContainer3 = ['para'];
const correctLetter2InContainer4 = ['en'];

const moveWords5 = [
  'Digo',
  'mi amiga: ¡Hola!',
  'Este cuaderno es',
  'Sonia.',
  'Estas flores son',
  'María.',
  'Vivís',
  'la calle Cervantes, 43.',
];

// Choose same letters

const moveWords6 = [
  'Paseáis',
  'el parque.',
  'Veo',
  'mi padre.',
  'Vamos',
  'la Universidad.',
  'Este vaso es',
  'cristal.',
];

const correctLetter3InContainer1 = ['por'];
const correctLetter3InContainer2 = ['a'];
const correctLetter3InContainer3 = ['a'];
const correctLetter3InContainer4 = ['de'];

// WriteWordsToText
const correctWordsToText = ['o', 'o', 'al', 'se llama', 'e', 'a', 'e'];

const wordsToText1 = [
  'Yo viv',
  'Le',
  'Voy',
  'Mi amigo',
  'Tien',
  'Estudi',
  'Pedro viv',
];

const wordsToText2 = [
  'en España, en Barcelona.',
  'muchos libros interesantes.',
  'supermercado y compro muchas cosas necesarias.',
  'Pedro.',
  '20 años.',
  'en la Universidad.',
  'con sus padres que se llaman María y Jesús.',
];

const sortableWords = [
  {
    words: 'come Sonia comida mucha',
    correctAnswer: ['Sonia come mucha comida'],
  },
  {
    words: 'el bien chico estudia',
    correctAnswer: ['el chico estudia bien'],
  },
  {
    words: 'universidad vosotros trabajáis en la',
    correctAnswer: ['vosotros trabajáis en la universidad'],
  },
  {
    words: 'va Pedro a casa',
    correctAnswer: ['Pedro va a casa'],
  },
  {
    words: 'yo casa leo estudio y en',
    correctAnswer: ['yo leo y estudio en casa'],
  },
  {
    words: 'al voy supermercado',
    correctAnswer: ['voy al supermercado'],
  },
  {
    words: 'ópera salgo la de',
    correctAnswer: ['salgo de la ópera'],
  },
  {
    words: 'de hablas la música',
    correctAnswer: ['hablas de la música'],
  },
];

// SelectWord
const correctWord1 = 'vivo';
const correctWord2 = 'vives';
const correctWord3 = 'vive';
const correctWord4 = 'vivimos';
const correctWord5 = 'vivís';
const correctWord6 = 'viven';

const options1 = ['vivo', 'vives', 'viva'];
const options2 = ['vive', 'vives', 'vivo'];
const options3 = ['vivo', 'vive', 'viva'];
const options4 = ['vivimos', 'vivimas', 'vivis'];
const options5 = ['vivimos', 'vivís', 'vive'];
const options6 = ['vivís', 'vivimos', 'viven'];

const wordsSelectWord = [
  'yo',
  'tú',
  'él, ella, usted',
  'nosotros, nosotras',
  'vosotros, vosotras',
  'ellos, ellas, ustedes',
];

// CreateWords
const defaultWords = [
  'купувати',
  'отримувати',
  'читати',
  'кидати',
  'відпочивати',
  'жити',
];

const defaultWords2 = [
  'бути присутнім',
  'писати',
  'спускатися',
  'зі мною',
  'ці',
  'завтра',
];

const correctWordsToTranslation = [
  'tomar',
  'comer',
  'vivir',
  'mañana',
  'salir',
];

const correctWordsToTranslation2 = [
  'el hambre',
  'el miedo',
  'el sueño',
  'las ganas',
  'la sed',
];

// SelectWordsForDialog
const selectWordsForDialog1 = ['¡Hola!'];
const selectWordsForDialog2 = ['¡Buenas tardes!'];
const selectWordsForDialog3 = ['¿Qué tal tú?'];
const selectWordsForDialog4 = ['¿Hoy estudias?'];
const selectWordsForDialog5 = ['No, hoy no estudio.'];
const selectWordsForDialog6 = ['Genial.'];

const persons = ['Hija:', 'Madre:', 'Hija:', 'Madre:', 'Hija:', 'Madre:'];

const wordsForDialog = [
  ', mamá!',
  ', hija ¿Cómo te va?',
  'Perfecto.',
  'Más o menos.',
  'Descanso.',
];

// AddWordsToContainer
const correctWordsInContainer1 = ['compro', 'estudio', 'miro', 'veo', 'digo'];
const correctWordsInContainer3 = ['lleva', 'pasea', 'saluda', 'tiene', 'baja'];
const correctWordsInContainer2 = ['lees', 'trabajas', 'te llamas'];

const correctWords2InContainer1 = [
  'comprendemos',
  'tomamos',
  'vamos',
  'salimos',
];
const correctWords2InContainer3 = ['viven', 'tienen', 'están sentados'];
const correctWords2InContainer2 = ['metéis', 'coméis', 'sois'];

// MoveWordToContainer
const correctWordInContainer1 = ['compro'];
const correctWordInContainer2 = ['compras'];
const correctWordInContainer3 = ['compra'];
const correctWordInContainer4 = ['compramos'];
const correctWordInContainer5 = ['compráis'];
const correctWordInContainer6 = ['compran'];

const correctWord2InContainer1 = ['comprendo'];
const correctWord2InContainer2 = ['comprendes'];
const correctWord2InContainer3 = ['comprende'];
const correctWord2InContainer4 = ['comprendemos'];
const correctWord2InContainer5 = ['comprendéis'];
const correctWord2InContainer6 = ['comprenden'];

const correctWord4InContainer1 = ['Yo compro 5 cuadernos.'];
const correctWord4InContainer2 = ['Tú vives en una ciudad grande.'];
const correctWord4InContainer3 = ['Ella mira el libro interesante.'];
const correctWord4InContainer4 = ['Nosotros tomamos agua.'];

const correctWord5InContainer1 = ['tomo'];
const correctWord5InContainer2 = ['tomas'];
const correctWord5InContainer3 = ['toma'];
const correctWord5InContainer4 = ['tomamos'];
const correctWord5InContainer5 = ['tomáis'];
const correctWord5InContainer6 = ['toman'];

// MoveWordToText

const correctWord3InContainer1 = ['llamo'];
const correctWord3InContainer2 = ['vivo'];
const correctWord3InContainer3 = ['tengo'];
const correctWord3InContainer4 = ['estudio'];
const correctWord3InContainer5 = ['soy'];
const correctWord3InContainer6 = ['gusta'];

const Exercises1Lesson5 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: '¡Buenas tardes!',
      options: ['Muy buenas', '¿Cuídate?', '¡Hasta la próxima!'],
      correctAnswer: 'Muy buenas',
    },
    {
      question: '¡Chao!',
      options: ['¡Buenas!', '¿Muy buenas!', '¡Hasta pronto!'],
      correctAnswer: '¡Hasta pronto!',
    },
    {
      question: '¡Cuánto tiempo!',
      options: ['Sí, mucho', '¡Que te vaya bien!', '¡Te veo luego!'],
      correctAnswer: 'Sí, mucho',
    },
    {
      question: '¿Cómo estás?',
      options: ['Encantado de conocerte', 'Igualmente', 'Fatal'],
      correctAnswer: 'Fatal',
    },
    {
      question: '¿Cómo se llama ella?',
      options: ['Me llamo María', 'Te llamas María', 'Se llama María'],
      correctAnswer: 'Se llama María',
    },
    {
      question: '¿Qué hay?',
      options: ['¡Cuídate!', 'Mucho gusto', 'Así así'],
      correctAnswer: 'Así así',
    },
    {
      question: '¿Qué haces?',
      options: ['Canto y bailo.', 'Cantas y bailas.', 'Cantáis y bailáis.'],
      correctAnswer: 'Canto y bailo.',
    },
  ];

  const defaultQuestions2 = [
    {
      question: 'yo',
      options: ['comes', 'como', 'comemos'],
      correctAnswer: 'como',
    },
    {
      question: 'tú',
      options: ['bebo', 'bebemos', 'bebes'],
      correctAnswer: 'bebes',
    },
    {
      question: 'él',
      options: ['mete', 'metes', 'metéis'],
      correctAnswer: 'mete',
    },
    {
      question: 'nosotros',
      options: ['comprenden', 'comprendemos', 'comprendéis'],
      correctAnswer: 'comprendemos',
    },
    {
      question: 'vosotros',
      options: ['leen', 'leo', 'leéis'],
      correctAnswer: 'leéis',
    },
  ];

  // WriteTranslation

  const wordsWriteTranslation = [
    translate('lesson5.exercises.writeTranslation.word1'),
    translate('lesson5.exercises.writeTranslation.word2'),
    translate('lesson5.exercises.writeTranslation.word3'),
    translate('lesson5.exercises.writeTranslation.word4'),
    translate('lesson5.exercises.writeTranslation.word5'),
  ];

  const wordsWriteTranslation2 = [
    translate('lesson5.exercises.writeTranslation2.word1'),
    translate('lesson5.exercises.writeTranslation2.word2'),
    translate('lesson5.exercises.writeTranslation2.word3'),
    translate('lesson5.exercises.writeTranslation2.word4'),
    translate('lesson5.exercises.writeTranslation2.word5'),
  ];

  // MoveWordToContainer
  const moveWords4 = [
    translate('lesson5.exercises.task15.word1'),
    translate('lesson5.exercises.task15.word2'),
    translate('lesson5.exercises.task15.word3'),
    translate('lesson5.exercises.task15.word4'),
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  // MoveWordToContainer
  const moveWords = [
    'Yo',
    'Tú',
    'Él, ella, usted',
    'Nosotros, nosotras',
    'Vosotros, vosotras',
    'Ellos, ellas, ustedes',
  ];

  // MoveWordToText
  const moveWords2 = [
    'Me',
    'Jorge.',
    'Soy español y',
    'con mis padres y mi hermano en España en Alicante en la calle Gutiérrez, 5.',
    'una familia pequeña: padre, madre y mi hermano.',
    'Tengo 25 años.',
    'No trabajo,',
    '.',
    'estudiante de la Universidad de Madrid.',
    'Me',
    'cantar, bailar, leer y estudiar.',
  ];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('lesson5.exercises.writeTranslation.title')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('lesson5.exercises.task4')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions2} number={6} />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task6.title')}
      </h2>
      <ChooseSameLetters
        correctLetterInContainer1={correctLetter3InContainer1}
        correctLetterInContainer2={correctLetter3InContainer2}
        correctLetterInContainer3={correctLetter3InContainer3}
        correctLetterInContainer4={correctLetter3InContainer4}
        moveWords={moveWords6}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainer1}
        correctWordsInContainer2={correctWordsInContainer2}
        correctWordsInContainer3={correctWordsInContainer3}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('lesson2.exercises.task8')}
      </h2>
      <SelectWordsForDialog
        correctWordInContainer1={selectWordsForDialog1}
        correctWordInContainer2={selectWordsForDialog2}
        correctWordInContainer3={selectWordsForDialog3}
        correctWordInContainer4={selectWordsForDialog4}
        correctWordInContainer5={selectWordsForDialog5}
        correctWordInContainer6={selectWordsForDialog6}
        moveWords={wordsForDialog}
        persons={persons}
        number={9}
      />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('lesson2.exercises.task7')}
      </h2>
      <SortableWords defaultWords={sortableWords} number={10} />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('lesson5.exercises.task11')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        correctWordsInContainer3={correctWords2InContainer3}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('exercises.task5')}
      </h2>
      <Move6WordsToText
        correctWordInContainer1={correctWord3InContainer1}
        correctWordInContainer2={correctWord3InContainer2}
        correctWordInContainer3={correctWord3InContainer3}
        correctWordInContainer4={correctWord3InContainer4}
        correctWordInContainer5={correctWord3InContainer5}
        correctWordInContainer6={correctWord3InContainer6}
        moveWords={moveWords2}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('lesson2.exercises.task6')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord4InContainer1}
        correctWordInContainer2={correctWord4InContainer2}
        correctWordInContainer3={correctWord4InContainer3}
        correctWordInContainer4={correctWord4InContainer4}
        moveWords={moveWords4}
        number={15}
      />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('lesson5.exercises.task16')}
      </h2>
      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={16}
      />

      <h2 className={commonStyles.lessonTask}>
        1.17 {translate('exercises.task6.title')}
      </h2>
      <ChooseLetters
        correctLetterInContainer1={correctLetter2InContainer1}
        correctLetterInContainer2={correctLetter2InContainer2}
        correctLetterInContainer3={correctLetter2InContainer3}
        correctLetterInContainer4={correctLetter2InContainer4}
        moveWords={moveWords5}
        number={17}
      />

      <h2 className={commonStyles.lessonTask}>
        1.18 {translate('lesson5.exercises.task18')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord5InContainer1}
        correctWordInContainer2={correctWord5InContainer2}
        correctWordInContainer3={correctWord5InContainer3}
        correctWordInContainer4={correctWord5InContainer4}
        correctWordInContainer5={correctWord5InContainer5}
        correctWordInContainer6={correctWord5InContainer6}
        moveWords={moveWords}
        number={18}
      />

      <h2 className={commonStyles.lessonTask}>
        1.19 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords2} number={19} />

      <h2 className={commonStyles.lessonTask}>
        1.20 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation2}
        correctWordsToTranslation={correctWordsToTranslation2}
        number={20}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson5;
