import { Box, FormControl, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import css from './SelectWord.module.css';
import { setTryStatus } from '../helpers/setTryStatus';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import useLocales from 'hooks/useLocales';
import { BootstrapInput } from 'customized/customized';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const CheckSelectWord = ({
  index,
  isCorrect,
  word,
  setIsCorrect,
  options1,
  options2,
  options3,
  options4,
  correctWord1,
  correctWord2,
  correctWord3,
  correctWord4,
  number,
}) => {
  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);
  const [id, setId] = useState(1);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const { currentLang } = useLocales();

  const correctWords = [correctWord1, correctWord2, correctWord3, correctWord4];

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const isAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[word.word];

    if (isAnswer) {
      setTryCounter(Object.values(isAnswer)[0].tryCounterData);
      // const hasAnyCorrect = tryCounter.some(item => item.tryStatus === true);

      // if (hasAnyCorrect) {
      //   setIsCorrect(prevIsCorrect => {
      //     const newIsCorrect = [...prevIsCorrect];
      //     newIsCorrect[index] = true;
      //     return newIsCorrect;
      //   });
      //   setSelectedOption(correctWord1);

      //   setIsDisabled(true);
      // }
    }
  }, [
    correctWord1,
    index,
    lesson,
    module,
    number,
    setIsCorrect,
    tryCounter,
    user.exercises,
    word.word,
  ]);

  const handleChangeOption = event => {
    setSelectedOption(event.target.value);
  };

  const onCheckBtnClick = index => {
    if (index === 0) {
      const correctWord = correctWord1;

      if (selectedOption === correctWord) {
        setIsCorrect(prevIsCorrect => {
          const newIsCorrect = [...prevIsCorrect];
          newIsCorrect[index] = true;
          return newIsCorrect;
        });
        setTryCounter(setTryStatus(id, true));

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: true,
                  };
                }
                return item;
              })
            ),
          })
        );
      } else {
        setTryCounter(setTryStatus(id, false));
        setId(prevState => prevState + 1);
        setIncorrectAttempts(prevAttempts => prevAttempts + 1);

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: false,
                  };
                }
                return item;
              })
            ),
          })
        );

        if (incorrectAttempts + 1 >= 3) {
          // Если неправильных попыток >= 3, установите правильный ответ
          setSelectedOption(correctWord);
          setIsDisabled(true);
        }
      }
    }
    if (index === 1) {
      const correctWord = correctWord2;

      if (selectedOption === correctWord) {
        setIsCorrect(prevIsCorrect => {
          const newIsCorrect = [...prevIsCorrect];
          newIsCorrect[index] = true;
          return newIsCorrect;
        });
        setTryCounter(setTryStatus(id, true));

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: true,
                  };
                }
                return item;
              })
            ),
          })
        );
      } else {
        setTryCounter(setTryStatus(id, false));
        setId(prevState => prevState + 1);
        setIncorrectAttempts(prevAttempts => prevAttempts + 1);

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: false,
                  };
                }
                return item;
              })
            ),
          })
        );

        if (incorrectAttempts + 1 >= 3) {
          // Если неправильных попыток >= 3, установите правильный ответ
          setSelectedOption(correctWord);
          setIsDisabled(true);
        }
      }
    }
    if (index === 2) {
      const correctWord = correctWord3;

      if (selectedOption === correctWord) {
        setIsCorrect(prevIsCorrect => {
          const newIsCorrect = [...prevIsCorrect];
          newIsCorrect[index] = true;
          return newIsCorrect;
        });
        setTryCounter(setTryStatus(id, true));

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: true,
                  };
                }
                return item;
              })
            ),
          })
        );
      } else {
        setTryCounter(setTryStatus(id, false));
        setId(prevState => prevState + 1);
        setIncorrectAttempts(prevAttempts => prevAttempts + 1);

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: false,
                  };
                }
                return item;
              })
            ),
          })
        );

        if (incorrectAttempts + 1 >= 3) {
          // Если неправильных попыток >= 3, установите правильный ответ
          setSelectedOption(correctWord);
          setIsDisabled(true);
        }
      }
    }
    if (index === 3) {
      const correctWord = correctWord4;

      if (selectedOption === correctWord) {
        setIsCorrect(prevIsCorrect => {
          const newIsCorrect = [...prevIsCorrect];
          newIsCorrect[index] = true;
          return newIsCorrect;
        });
        setTryCounter(setTryStatus(id, true));

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: true,
                  };
                }
                return item;
              })
            ),
          })
        );
      } else {
        setTryCounter(setTryStatus(id, false));
        setId(prevState => prevState + 1);
        setIncorrectAttempts(prevAttempts => prevAttempts + 1);

        dispatch(
          updateProfileThunk({
            email: user.email,
            exercises: exerciseData(
              tryCounter.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    tryStatus: false,
                  };
                }
                return item;
              })
            ),
          })
        );
        if (incorrectAttempts + 1 >= 3) {
          // Если неправильных попыток >= 3, установите правильный ответ
          setSelectedOption(correctWord);
          setIsDisabled(true);
        }
      }
    }
  };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [word.word]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[word.word] ||
                  {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[word.word]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises, word]
  );

  return (
    <Box
      key={index}
      sx={{
        display: { mobile: 'flex' },
        justifyContent: { mobile: 'center' },
        alignItems: { mobile: 'center' },
        margin: {
          mobile: '4px auto',
          iphone: '16px auto 0px auto',
          tablet: '0px 0px',
        },
      }}
    >
      <div className={css.exerciseWrap}>
        <div className={css.wordWrap}>
          <p word={word}>{word[`translation_${currentLang.value}`]}</p>
        </div>

        <FormControl
          sx={{ justifyContent: 'center', alignItems: 'center' }}
          variant="standard"
        >
          <Select
            labelId={`demo-customized-select-label-${index}`}
            id={`demo-customized-select-${index}`}
            input={
              <BootstrapInput
                sx={{
                  width: { mobile: '224px', tablet: '200px', laptop: '220px' },
                }}
              />
            }
            value={
              tryCounter.some(item => item.tryStatus === true) ||
              tryCounter.every(item => item.tryStatus === false)
                ? correctWords[index]
                : selectedOption
            }
            onChange={handleChangeOption}
            readOnly={isCorrect[index] || isDisabled}
          >
            {index === 0 &&
              options1.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            {index === 1 &&
              options2.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            {index === 2 &&
              options3.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            {index === 3 &&
              options4.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <CheckAnswerBtn
          tryCounter={tryCounter}
          onCheckBtnClick={() => onCheckBtnClick(index)}
        />
      </div>
    </Box>
  );
};

export default CheckSelectWord;
