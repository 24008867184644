import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const GuardsAdmin = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user.role === "Admin" ? children : <Navigate to=".." />;
};

export default GuardsAdmin;
