import React from 'react';
import style from './boxWithLetter.module.css';

const BoxWithLetter = ({
  index,
  item,
  onLetterClick,
  isSelected,
  onContainerClick,
  onDoubleClick,
}) => {
  return (
    <div
      index={index}
      key={item.id}
      className={`${style.box} ${isSelected ? style.selected : ''}`}
      onClick={() => onLetterClick(item)}
      // onDoubleClick={() => onDoubleClick(item)}
    >
      {item.letterForAnswer}
    </div>
  );
};

export default BoxWithLetter;
