import React, { useState } from 'react';
import { Grid } from '@mui/material';

import CheckCardWriteWord from './CheckCardWriteWord';

const WriteWord = ({ words, correctWords, number }) => {
  const [guesses, setGuesses] = useState(Array(words.length).fill(''));
  const [borderColors, setBorderColors] = useState(
    Array(words.length).fill('')
  );
  const [isCorrect, setIsCorrect] = useState(Array(words.length).fill(false));

  const handleInputChange = (index, event) => {
    if (!isCorrect[index]) {
      const newGuesses = [...guesses];
      newGuesses[index] = event.target.value;
      setGuesses(newGuesses);
    }
  };

  const showCorrectWord = (index, value) => {
    const newGuesses = [...guesses];
    newGuesses[index] = value;
    setGuesses(newGuesses);
  };

  return (
    <div>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: { tablet: 'wrap' },
          columnGap: { tablet: '16px' },
        }}
      >
        {words.map((word, index) => (
          <CheckCardWriteWord
            number={number}
            key={index}
            index={index}
            isCorrect={isCorrect}
            word={word}
            borderColors={borderColors}
            guesses={guesses}
            handleInputChange={handleInputChange}
            setBorderColors={setBorderColors}
            setIsCorrect={setIsCorrect}
            correctWords={correctWords}
            showCorrectWord={showCorrectWord}
          />
        ))}
      </Grid>
    </div>
  );
};

export default WriteWord;
