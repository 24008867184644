import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import BlogCard from './BlogCard';
import { useDispatch, useSelector } from 'react-redux';
import { postsSelector } from '../../store/posts/selectors';
import { getAllPostsThunk } from '../../store/posts/thunk';
import Loader from 'components/Loader/Loader';

const BlogList = () => {
  const { posts, isLoading, error } = useSelector(postsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPostsThunk());
  }, [dispatch]);

  return (
    <Stack
      display="flex"
      justifyContent="center"
      direction="row"
      flexWrap="wrap"
      useFlexGap
      sx={{
        gap: {
          mobile: '16px',
          tablet: '18px',
        },
      }}
    >
      {isLoading && <Loader />}
      {posts.length > 0 &&
        posts.map(post => <BlogCard post={post} key={post._id} />)}
      {error && <h2>{error.message}</h2>}
    </Stack>
  );
};

export default BlogList;
