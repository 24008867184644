import { Drawer, useMediaQuery } from '@mui/material';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import useLocales from 'hooks/useLocales';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

export const SideBar = () => {
  // const [isSideBarOpen, setIsSideBarOpen] = useState(true);

  // const toggleSideBar = () => {
  //   setIsSideBarOpen(!isSideBarOpen);
  // };
  const { translate } = useLocales();

  const appRoutes = [
    {
      path: '/lessons',
      page: '1',
      sidebarProps: {
        displayText: translate('lessons.lesson1.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson1/1',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-1'),
          },
        },
        {
          path: '/lessons/lesson1/2',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-2'),
          },
        },
        {
          path: '/lessons/lesson1/3',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson1/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-3'),
          },
        },
        {
          path: '/lessons/lesson1/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-5'),
          },
        },
        {
          path: '/lessons/lesson1/6',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson1/7',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },
    {
      path: '/lessons',
      page: '2',
      sidebarProps: {
        displayText: translate('lessons.lesson2.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson2/1',
          sidebarProps: {
            displayText: translate('lessons.lesson2.lesson2-article'),
          },
        },
        {
          path: '/lessons/lesson2/2',
          sidebarProps: {
            displayText: translate('lessons.lesson2.lesson2-words-order'),
          },
        },

        {
          path: '/lessons/lesson2/3',
          sidebarProps: {
            displayText: translate('lessons.lesson2.lesson2-greetings'),
          },
        },
        {
          path: '/lessons/lesson2/4',
          sidebarProps: {
            displayText: translate('lessons.lesson2.lesson2-countries'),
          },
        },
        {
          path: '/lessons/lesson2/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson2/6',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },
    {
      path: '/lessons',
      page: '3',
      sidebarProps: {
        displayText: translate('lessons.lesson3.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson3/1',
          sidebarProps: {
            displayText: translate('lessons.lesson3.ser'),
          },
        },
        {
          path: '/lessons/lesson3/2',
          sidebarProps: {
            displayText: translate('lessons.lesson3.numbers10'),
          },
        },
        {
          path: '/lessons/lesson3/3',
          sidebarProps: {
            displayText: translate('lessons.lesson3.llamarse'),
          },
        },
        {
          path: '/lessons/lesson3/4',
          sidebarProps: {
            displayText: translate('lessons.lesson3.professions'),
          },
        },
        {
          path: '/lessons/lesson3/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson3/6',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },
    {
      path: '/lessons',
      page: '4',
      sidebarProps: {
        displayText: translate('lessons.lesson4.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson4/1',
          sidebarProps: {
            displayText: translate('lessons.lesson4.nouns'),
          },
        },
        {
          path: '/lessons/lesson4/2',
          sidebarProps: {
            displayText: translate('lessons.lesson4.adjectives'),
          },
        },
        {
          path: '/lessons/lesson4/3',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson4/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },
    {
      path: '/lessons',
      page: '5',
      sidebarProps: {
        displayText: translate('lessons.lesson5.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson5/1',
          sidebarProps: {
            displayText: translate('lessons.lesson5.present'),
          },
        },
        {
          path: '/lessons/lesson5/2',
          sidebarProps: {
            displayText: translate('lessons.lesson5.prepositions'),
          },
        },
        {
          path: '/lessons/lesson5/3',
          sidebarProps: {
            displayText: translate('lessons.lesson5.tener'),
          },
        },
        {
          path: '/lessons/lesson5/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson5/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },
    {
      path: '/lessons',
      page: '6',
      sidebarProps: {
        displayText: translate('lessons.lesson6.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson6/1',
          sidebarProps: {
            displayText: translate('lessons.lesson6.questions'),
          },
        },
        {
          path: '/lessons/lesson6/2',
          sidebarProps: {
            displayText: translate('lessons.lesson6.impersonalVerbs'),
          },
        },
        {
          path: '/lessons/lesson6/3',
          sidebarProps: {
            displayText: translate('lessons.lesson6.irregularVerbs'),
          },
        },
        {
          path: '/lessons/lesson6/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson6/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },

    {
      path: '/lessons',
      page: '7',
      sidebarProps: {
        displayText: translate('lessons.lesson7.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson7/1',
          sidebarProps: {
            displayText: translate('lessons.lesson7.pronouns'),
          },
        },
        {
          path: '/lessons/lesson7/2',
          sidebarProps: {
            displayText: translate('lessons.lesson7.degreesOfComparison'),
          },
        },
        {
          path: '/lessons/lesson7/3',
          sidebarProps: {
            displayText: translate('lessons.lesson7.constructions'),
          },
        },
        {
          path: '/lessons/lesson7/4',
          sidebarProps: {
            displayText: translate('lessons.lesson7.irregularVerbs2'),
          },
        },
        {
          path: '/lessons/lesson7/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson7/6',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },

    {
      path: '/lessons',
      page: '8',
      sidebarProps: {
        displayText: translate('lessons.lesson8.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson8/1',
          sidebarProps: {
            displayText: translate('lessons.lesson8.numbers100'),
          },
        },
        {
          path: '/lessons/lesson8/2',
          sidebarProps: {
            displayText: translate('lessons.lesson8.numbers1000'),
          },
        },
        {
          path: '/lessons/lesson8/3',
          sidebarProps: {
            displayText: translate('lessons.lesson8.verbosReflexivos'),
          },
        },
        {
          path: '/lessons/lesson8/4',
          sidebarProps: {
            displayText: translate('lessons.lesson8.irregularVerbs3'),
          },
        },
        {
          path: '/lessons/lesson8/5',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson8/6',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },

    {
      path: '/lessons',
      page: '9',
      sidebarProps: {
        displayText: translate('lessons.lesson9.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson9/1',
          sidebarProps: {
            displayText: translate('lessons.lesson9.application'),
          },
        },
        {
          path: '/lessons/lesson9/2',
          sidebarProps: {
            displayText: translate('lessons.lesson9.irregularVerbs5'),
          },
        },
        {
          path: '/lessons/lesson9/3',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson9/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },

    {
      path: '/lessons',
      page: '10',
      sidebarProps: {
        displayText: translate('lessons.lesson10.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson10/1',
          sidebarProps: {
            displayText: translate('lessons.lesson10.pronounsAndAdverbs'),
          },
        },
        {
          path: '/lessons/lesson10/2',
          sidebarProps: {
            displayText: translate('lessons.lesson10.irregularVerbs7'),
          },
        },
        {
          path: '/lessons/lesson10/3',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
        {
          path: '/lessons/lesson10/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-test'),
          },
        },
      ],
    },

    {
      path: '/lessons',
      page: '11',
      sidebarProps: {
        displayText: translate('lessons.lesson11.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson11/1',
          sidebarProps: {
            displayText: translate('lessons.lesson11.gerundio'),
          },
        },
        {
          path: '/lessons/lesson11/2',
          sidebarProps: {
            displayText: translate('lessons.lesson11.tambien'),
          },
        },
        {
          path: '/lessons/lesson11/3',
          sidebarProps: {
            displayText: translate('lessons.lesson11.irregularVerbs8'),
          },
        },
        {
          path: '/lessons/lesson11/4',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
      ],
    },
    {
      path: '/lessons',
      page: '12',
      sidebarProps: {
        displayText: translate('lessons.lesson12.title'),
        icon: <FormatListBulletedOutlinedIcon />,
      },

      child: [
        {
          path: '/lessons/lesson12/1',
          sidebarProps: {
            displayText: translate('lessons.lesson12.past'),
          },
        },
        {
          path: '/lessons/lesson12/2',
          sidebarProps: {
            displayText: translate('lessons.lesson1.lesson1-exercises'),
          },
        },
      ],
    },
  ];

  const isTabletScreen = useMediaQuery('(min-width: 768px)');
  // const isDesktopScreen = useMediaQuery('(min-width: 1280px)');

  return (
    isTabletScreen && (
      <>
        {/* <Stack
          display="flex"
          sx={{
            width: '80px',
            height: '30px',
            marginTop: '90px',
            marginLeft: '10px',
          }}
          direction="row"
        >
          <Button
            onClick={toggleSideBar}
            sx={{ gap: '10px', width: '80px', height: '30px' }}
          >
            <p>Open</p>
            <KeyboardDoubleArrowRightIcon />
          </Button>
        </Stack> */}
        {/* {isSideBarOpen && ( */}
        <Drawer
          variant="permanent"
          sx={{
            width: { tablet: '180px', laptop: '300px' },

            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: { tablet: '180px', laptop: '300px' },
              boxSizing: 'border-box',
              borderRight: '0px',
              backgroundColor: '#94a4b2',
              color: 'white',
              paddingTop: '79px',
            },
          }}
        >
          {/* <List disablePadding={true}> */}
          {/* <Toolbar>
                <Stack
                  display="flex"
                  sx={{ width: '100%' }}
                  direction="row"
                  justifyContent="center"
                >
                  <Button onClick={toggleSideBar} sx={{ gap: '10px' }}>
                    <p>Close</p>
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </Stack>
              </Toolbar> */}
          <ul>
            {appRoutes.map((route, index) =>
              route.sidebarProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} index={index} />
                ) : (
                  <SidebarItem item={route} key={index} index={index} />
                )
              ) : null
            )}
          </ul>
          {/* </List> */}
        </Drawer>
        {/* )} */}
      </>
    )
  );
};
