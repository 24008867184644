import React from 'react';
import css from './QuestionCards.module.css';
import RadioGroup from './RadioGroup';

const QuestionCards = ({ questions, number }) => {
  return (
    <div className={css.quiz}>
      {questions.map(question => {
        return (
          <RadioGroup
            key={question.question}
            question={question.question}
            options={question.options}
            correctAnswer={question.correctAnswer}
            number={number}
          />
        );
      })}
    </div>
  );
};

export default QuestionCards;
