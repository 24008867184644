import * as React from "react";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";

export function LinkTab(props) {
  const navigate = useNavigate();

  return (
    <Tab
      component="button"
      disableRipple
      //   to={props.to}
      onClick={(event) => {
        event.preventDefault();
        navigate(props.to);
      }}
      {...props}
    />
  );
}
