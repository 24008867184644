import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { refreshThunk } from '../store/auth/thunks';
// import Loader from 'components/Loader/Loader';

const GuardsPublic = ({ children }) => {
  // const { token, isLoading } = useSelector(state => state.auth);
  const { token } = useSelector(state => state.auth);

  const localToken = localStorage.getItem('token');

  // console.log('token', token, 'localToken', localToken);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!token && localToken) {
      dispatch(refreshThunk(localToken));
    }
  }, [dispatch, localToken, token]);

  // return isLoading ? <Loader /> : !token ? children : <Navigate to="/" />;
  return !token ? children : <Navigate to="/" />;
};

export default GuardsPublic;
