import {
  // Alert,
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  // Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { countries } from '../../utils/countries';
import { changePassword } from '../../api/auth';
import { updateAvatarThunk, updateProfileThunk } from '../../store/auth/thunks';
import { toast } from 'react-toastify';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import Loader from 'components/Loader/Loader';
import AvatarUpload from './AvatarUpload/AvatarUpload';
import parseDate from 'services/parseData';
import { Form, Formik } from 'formik';
import useLocales from 'hooks/useLocales';
import EditBtn from 'components/Buttons/EditBtn/EditBtn';
import EditCloseBtn from 'components/Buttons/EditCloseBtn/EditCloseBtn';
import css from './ProfilePage.module.css';
import { CustomButton } from 'customized/customized';

const ProfilePage = () => {
  const { user, isLoading, error } = useSelector(state => state.auth);
  const [isCollapse, setIsCollapse] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirm, setErrorConfirm] = useState(false);
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');

  // const [message, setMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_open, setOpen] = useState(false);

  const { translate } = useLocales();
  const dispatch = useDispatch();

  const notifySuccessPassword = () =>
    toast.success(translate('profile.messagePasswordChanged'), {});
  const notifyErrorPassword = () =>
    toast.error(translate('profile.errorOnSave'), {});
  const notifySuccessProfile = () =>
    toast.success(translate('profile.messageDataUpdated'), {});
  const notifyErrorProfile = () => toast.error(translate('errorMessage'), {});

  useEffect(() => {
    if (user) {
      setName(user.name);
      setSurname(user.surname);
      setPhone(user.phone);
      setCountry(user.country);
      setLanguage(user.language);
    }
  }, [user]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name') setName(value);
    else if (name === 'surname') setSurname(value);
    else if (name === 'phone') setPhone(value);
  };

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };

  useEffect(() => {
    if (newPassword && confirmPassword !== newPassword) setErrorConfirm(true);
    else setErrorConfirm(false);
  }, [confirmPassword, newPassword]);

  const closeCollapse = () => {
    setIsCollapse(false);
    setNewPassword('');
    setConfirmPassword('');
    setPassword('');
  };

  const createNewPassword = async body => {
    try {
      await changePassword(body);
      setOpen(true);
      closeCollapse();
      notifySuccessPassword();
    } catch (error) {
      notifyErrorPassword();
    }
  };

  const handleNewPassword = event => {
    event.preventDefault();
    createNewPassword({ password, newPassword, email: user.email });
  };

  const handleSaveChanges = event => {
    event.preventDefault();
    dispatch(
      updateProfileThunk({
        email: user.email,
        name,
        surname,
        ...(phone && { phone }),
        country,
        language,
      })
    )
      .unwrap()
      .then(() => {
        // setMessage(translate('profile.messageDataUpdated'));
        notifySuccessProfile();
        setOpen(true);
      })
      .catch(error => {
        // setMessage(error);
        notifyErrorProfile();

        setOpen(true);
      });
  };

  const handleOnEdit = () => {
    setIsEditing(true);
  };

  const handleOnEditClose = resetForm => {
    return () => {
      setIsEditing(false);
      resetForm();
    };
  };

  const onSubmit = async values => {
    const { avatar } = parseDate(values);

    if (avatar && typeof avatar !== 'string') {
      const formData = new FormData();
      formData.append('avatar', avatar);
      dispatch(updateAvatarThunk(formData))
        .unwrap()
        .then(() => setIsEditing(false));
    }
  };

  useEffect(() => {
    if (error) {
      error === 'File too large' && toast.error(translate('profile.errorImg'));
    }
  }, [error, translate]);

  const isTabletScreen = useMediaQuery(
    '(min-width: 320px) and (max-width: 1279px)'
  );

  const isDesktopScreen = useMediaQuery('(min-width: 1280px)');

  return (
    <>
      <Box
        sx={{
          padding: {
            mobile: '90px 10px 90px 10px',
            tablet: '120px 10px 100px 10px',
            laptop: '160px 40px 80px 40px',
          },
        }}
      >
        <CssBaseline />

        {isLoading && <Loader />}
        <Stack
          display="flex"
          sx={{
            flexDirection: {
              mobile: 'column',
              tablet: 'column',
              laptop: 'row',
            },
            gap: { mobile: '20px', tablet: '36px', laptop: '36px' },
            alignItems: { mobile: 'center', laptop: 'flex-start' },
            justifyContent: { mobile: 'center' },
          }}
        >
          <Stack
            display="flex"
            sx={{
              p: 0,
              width: { laptop: '230px' },
              alignItems: { mobile: 'center', laptop: 'flex-start' },
            }}
          >
            <Formik initialValues={{ avatar: '' }} onSubmit={onSubmit}>
              {({ values, setFieldValue, resetForm, submitForm }) => (
                <Form className={css.sectionAvatar}>
                  {!isEditing && <EditBtn handleOnClick={handleOnEdit} />}
                  {isEditing && (
                    <EditCloseBtn
                      handleOnClick={handleOnEditClose(resetForm)}
                    />
                  )}
                  <AvatarUpload
                    userFile={user.avatar}
                    isEditing={isEditing}
                    setImage={value => {
                      setFieldValue('avatar', value);
                      submitForm();
                    }}
                  />
                </Form>
              )}
            </Formik>
          </Stack>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSaveChanges}
            sx={{
              display: 'flex',
              gap: { mobile: '16px', tablet: '20px', laptop: '30px' },
              flexWrap: { mobile: 'wrap' },
              width: { mobile: '300px', tablet: '600px', laptop: '456px' },
              justifyContent: { mobile: 'center' },
              alignItems: { mobile: 'center' },
              flexDirection: { tablet: 'column' },
              marginTop: { laptop: '24px' },
            }}
          >
            <Stack
              display="flex"
              sx={{
                flexWrap: {
                  mobile: 'wrap',
                  tablet: 'nowrap',
                  laptop: 'nowrap',
                },
                flexDirection: {
                  mobile: 'column',
                  tablet: 'row',
                  laptop: 'row',
                },
                width: { mobile: '250px', tablet: '600px', laptop: '456px' },
                gap: { mobile: '16px' },
                justifyContent: { tablet: 'center' },
              }}
            >
              {isTabletScreen && (
                <>
                  <TextField
                    name="name"
                    value={name}
                    onChange={handleChange}
                    id="name"
                    label={translate('profile.name')}
                    size="small"
                    sx={{
                      width: { mobile: '250px', laptop: '195px' },
                    }}
                  />
                  <TextField
                    name="surname"
                    value={surname}
                    onChange={handleChange}
                    id="surname"
                    label={translate('profile.surname')}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: { mobile: '250px', laptop: '195px' },
                    }}
                  />
                </>
              )}
              {isDesktopScreen && (
                <>
                  <TextField
                    name="name"
                    value={name}
                    onChange={handleChange}
                    id="name"
                    label={translate('profile.name')}
                    size="normal"
                    sx={{
                      width: { mobile: '250px', laptop: '220px' },
                    }}
                  />
                  <TextField
                    name="surname"
                    value={surname}
                    onChange={handleChange}
                    id="surname"
                    label={translate('profile.surname')}
                    size="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: { mobile: '250px', laptop: '220px' },
                    }}
                  />
                </>
              )}
            </Stack>
            <Stack
              display="flex"
              sx={{
                flexWrap: {
                  mobile: 'wrap',
                  tablet: 'nowrap',
                  laptop: 'nowrap',
                },
                flexDirection: {
                  mobile: 'column',
                  tablet: 'row',
                  laptop: 'row',
                },
                width: { mobile: '250px', tablet: '600px', laptop: '456px' },
                gap: { mobile: '16px' },
                justifyContent: { tablet: 'center' },
              }}
            >
              {isTabletScreen && (
                <>
                  <TextField
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    id="outlined-number"
                    label={translate('profile.phone')}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    sx={{
                      width: { mobile: '250px', laptop: '195px' },
                    }}
                  />
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label={translate('profile.email')}
                    value={user.email}
                    size="small"
                    sx={{
                      width: { mobile: '250px', laptop: '195px' },
                    }}
                  />
                </>
              )}
              {isDesktopScreen && (
                <>
                  <TextField
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    id="outlined-number"
                    label={translate('profile.phone')}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="normal"
                    sx={{
                      width: { mobile: '250px', laptop: '220px' },
                    }}
                  />
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label={translate('profile.email')}
                    value={user.email}
                    size="normal"
                    sx={{
                      width: { mobile: '250px', laptop: '220px' },
                    }}
                  />
                </>
              )}
            </Stack>

            <Stack
              display="flex"
              sx={{
                flexWrap: {
                  mobile: 'wrap',
                  tablet: 'nowrap',
                  laptop: 'nowrap',
                },
                flexDirection: {
                  mobile: 'column',
                  tablet: 'row',
                  laptop: 'row',
                },
                width: { mobile: '250px', tablet: '600px', laptop: '456px' },
                gap: { mobile: '16px' },
                justifyContent: { tablet: 'center' },
              }}
            >
              {isTabletScreen && (
                <>
                  <Autocomplete
                    value={country}
                    onChange={(e, newCountry) => setCountry(newCountry)}
                    id="country-select-demo"
                    sx={{
                      width: { mobile: '250px', laptop: '195px' },
                    }}
                    options={countries}
                    autoHighlight
                    getOptionLabel={option => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={translate('profile.chooseCountry')}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        size="small"
                      />
                    )}
                  />

                  <SelectLanguage setLang={lang => setLanguage(lang)} />
                </>
              )}
              {isDesktopScreen && (
                <>
                  <Autocomplete
                    value={country}
                    onChange={(e, newCountry) => setCountry(newCountry)}
                    id="country-select-demo"
                    sx={{
                      width: { mobile: '250px', laptop: '220px' },
                    }}
                    options={countries}
                    autoHighlight
                    getOptionLabel={option => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={translate('profile.chooseCountry')}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        size="normal"
                      />
                    )}
                  />

                  <SelectLanguage etLang={lang => setLanguage(lang)} />
                </>
              )}
            </Stack>
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="0"
            >
              <CustomButton
                type="submit"
                variant="contained"
                sx={{
                  marginTop: { mobile: '15px', tablet: '20px', laptop: '20px' },
                  marginBottom: {
                    mobile: '31px',
                    tablet: '40px',
                    laptop: '50px',
                  },

                  width: { mobile: '195px', laptop: '220px' },
                  height: { mobile: '37px', laptop: '42px' },
                }}
              >
                {translate('profile.save')}
              </CustomButton>
            </Stack>
          </Box>
          {/* <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {message}
            </Alert>
          </Snackbar> */}
        </Stack>

        <Stack
          display="flex"
          spacing={2}
          sx={{
            flexWrap: { mobile: 'wrap', laptop: 'nowrap' },
            flexDirection: { mobile: 'column', laptop: 'row' },
            justifyContent: { mobile: 'center', tablet: 'center' },
            alignItems: { mobile: 'center', laptop: 'flex-start' },
          }}
        >
          {!isCollapse ? (
            <Stack
              sx={{
                flexWrap: {
                  mobile: 'wrap',
                  tablet: 'nowrap',
                  laptop: 'nowrap',
                },
                flexDirection: {
                  mobile: 'column',
                  tablet: 'column',
                  laptop: 'row',
                },
                width: { mobile: '250px', tablet: '600px', laptop: '920px' },
                gap: { mobile: '16px', tablet: '40px', laptop: '16px' },
                justifyContent: { mobile: 'center' },
                alignItems: { mobile: 'center' },
                paddingLeft: { laptop: '266px' },
              }}
            >
              {isTabletScreen && (
                <>
                  <TextField
                    disabled
                    id="outlined-password-input"
                    label={translate('profile.password')}
                    type="password"
                    autoComplete="current-password"
                    defaultValue="password"
                    sx={{
                      width: { mobile: '250px', laptop: '195px' },
                    }}
                    size="small"
                  />
                  <CustomButton
                    variant="contained"
                    onClick={() => setIsCollapse(true)}
                    sx={{
                      marginTop: {
                        mobile: '15px',
                        tablet: '0px',
                        laptop: '0px',
                      },

                      width: { mobile: '195px', laptop: '220px' },
                      height: { mobile: '37px', laptop: '40px' },
                    }}
                  >
                    {translate('profile.changePassword')}
                  </CustomButton>
                </>
              )}
              {isDesktopScreen && (
                <>
                  <TextField
                    disabled
                    id="outlined-password-input"
                    label={translate('profile.password')}
                    type="password"
                    autoComplete="current-password"
                    defaultValue="password"
                    sx={{
                      width: { mobile: '250px', laptop: '220px' },
                    }}
                    size="normal"
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setIsCollapse(true)}
                    sx={{
                      marginTop: {
                        mobile: '15px',
                        tablet: '0px',
                        laptop: '0px',
                      },

                      width: { mobile: '195px', laptop: '220px' },
                      height: { mobile: '37px', laptop: '56px' },
                    }}
                  >
                    {translate('profile.changePassword')}
                  </Button>
                </>
              )}
            </Stack>
          ) : (
            <>
              <form onSubmit={handleNewPassword}>
                <Stack
                  display="flex"
                  sx={{
                    flexDirection: { mobile: 'column', laptop: 'column' },
                    // justifyContent: { mobile: 'center', tablet: 'center' },
                    alignItems: { laptop: 'center' },
                    gap: { mobile: '16px', laptop: '50px' },
                  }}
                >
                  {isTabletScreen && (
                    <>
                      <TextField
                        id="outlined-password-old"
                        label={translate('profile.enterOldPassword')}
                        type="text"
                        value={password}
                        onChange={({ target: { value } }) => setPassword(value)}
                        size="small"
                      />
                      <Stack
                        display="flex"
                        sx={{
                          flexDirection: { mobile: 'column', laptop: 'row' },
                          width: { mobile: '250px', laptop: '456px' },
                          gap: { mobile: '16px', laptop: '0px' },
                        }}
                      >
                        <TextField
                          id="outlined-password-new"
                          label={translate('profile.enterNewPassword')}
                          type="text"
                          onChange={({ target: { value } }) =>
                            setNewPassword(value)
                          }
                          size="small"
                        />
                        <TextField
                          id="outlined-password-confirm"
                          label={translate('profile.repeatNewPassword')}
                          type="text"
                          size="small"
                          onChange={({ target: { value } }) =>
                            setConfirmPassword(value)
                          }
                          error={errorConfirm}
                          helperText={
                            errorConfirm && (
                              <Typography variant="caption" color="error">
                                {translate('error')}
                              </Typography>
                            )
                          }
                        />
                      </Stack>
                      <Button
                        variant="outlined"
                        onClick={() => closeCollapse()}
                      >
                        {translate('profile.cancel')}
                      </Button>
                      <Button
                        variant="outlined"
                        type="submit"
                        disabled={errorConfirm}
                      >
                        {translate('profile.savePassword')}
                      </Button>
                    </>
                  )}
                  {isDesktopScreen && (
                    <>
                      <Stack
                        display="flex"
                        sx={{
                          flexDirection: { mobile: 'column', laptop: 'row' },
                          gap: { laptop: '16px' },
                          padding: { laptop: '0px 0px 0px 30px' },
                        }}
                      >
                        <TextField
                          id="outlined-password-old"
                          label={translate('profile.enterOldPassword')}
                          type="text"
                          value={password}
                          onChange={({ target: { value } }) =>
                            setPassword(value)
                          }
                          size="normal"
                          sx={{
                            width: { mobile: '250px', laptop: '220px' },
                          }}
                        />
                        <Stack
                          display="flex"
                          sx={{
                            flexDirection: { mobile: 'column', laptop: 'row' },
                            width: { mobile: '250px', laptop: '456px' },
                            gap: { mobile: '16px' },
                          }}
                        >
                          <TextField
                            id="outlined-password-new"
                            label={translate('profile.enterNewPassword')}
                            type="text"
                            onChange={({ target: { value } }) =>
                              setNewPassword(value)
                            }
                            size="normal"
                            sx={{
                              width: { mobile: '250px', laptop: '220px' },
                            }}
                          />
                          <TextField
                            id="outlined-password-confirm"
                            label={translate('profile.repeatNewPassword')}
                            type="text"
                            size="normal"
                            sx={{
                              width: { mobile: '250px', laptop: '220px' },
                            }}
                            onChange={({ target: { value } }) =>
                              setConfirmPassword(value)
                            }
                            error={errorConfirm}
                            helperText={
                              errorConfirm && (
                                <Typography variant="caption" color="error">
                                  {translate('error')}
                                </Typography>
                              )
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack
                        display="flex"
                        sx={{
                          flexDirection: { laptop: 'row' },
                          // width: { mobile: '250px', laptop: '456px' },
                          gap: { mobile: '16px' },
                          paddingLeft: { laptop: '266px' },
                        }}
                      >
                        <CustomButton
                          variant="contained"
                          onClick={() => closeCollapse()}
                          sx={{
                            width: { laptop: '220px' },
                            height: { laptop: '42px' },
                          }}
                        >
                          {translate('profile.cancel')}
                        </CustomButton>
                        <CustomButton
                          variant="contained"
                          type="submit"
                          disabled={errorConfirm}
                          sx={{
                            width: { laptop: '220px' },
                            height: { laptop: '42px' },
                          }}
                        >
                          {translate('profile.savePassword')}
                        </CustomButton>
                      </Stack>
                    </>
                  )}
                </Stack>
              </form>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default ProfilePage;
