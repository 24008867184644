import { useCallback, useEffect, useMemo, useState } from 'react';
import EmptyBox from './EmptyBox/EmptyBox';
import BoxWithLetter from './BoxWithLetter/BoxWithLetter';
import { setTryStatus } from '../helpers/setTryStatus';
import style from './CreateWord.module.css';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';
import {
  createLetterBoxes,
  createCorrectAnswer,
} from '../helpers/createLetterBoxesState';

const CreateWordLibrary = ({ correctlyAnswer, letters, title, number }) => {
  const [letterBoxes, setLetterBoxes] = useState(createLetterBoxes(letters));
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [isCorrectAttempt, setIsCorrectAttempt] = useState(false);

  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);

  const [id, setId] = useState(1);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const userAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[title];

    if (userAnswer) {
      setTryCounter(Object.values(userAnswer)[0].tryCounterData);

      if (Object.keys(userAnswer)[0] === correctlyAnswer) {
        setLetterBoxes(createCorrectAnswer(correctlyAnswer));
      }
    }
  }, [user, module, lesson, number, title, letters, correctlyAnswer]);

  const onLetterClick = letter => {
    setSelectedLetter(letter);
  };

  const onEmptyBoxClick = index => {
    if (selectedLetter !== null) {
      const updatedLetterBoxes = [...letterBoxes];

      // Найдем индекс буквы, которая должна быть заменена
      const existingLetterIndex = updatedLetterBoxes.findIndex(
        item => item.order === index + 1
      );

      // Если нашли букву, которую нужно заменить
      if (existingLetterIndex !== -1) {
        // Найдем индекс буквы, которая пришла
        const previousLetterIndex = updatedLetterBoxes.findIndex(
          item => item.id === updatedLetterBoxes[index].from
        );

        // Если нашли такую букву
        if (previousLetterIndex !== -1) {
          // Сделаем ее видимой
          updatedLetterBoxes[previousLetterIndex].visible = true;
        }
      }

      // Обновляем пустую ячейку новой буквой
      updatedLetterBoxes[index].letterForUser = selectedLetter.text;
      updatedLetterBoxes[index].from = selectedLetter.order;
      selectedLetter.visible = false;

      setLetterBoxes(updatedLetterBoxes);
      setSelectedLetter(null);
    }
  };

  const onCheckBtnClick = () => {
    const userAnswer = letterBoxes.map(item => item.letterForUser).join('');
    const isCorrect = correctlyAnswer === userAnswer;

    if (isCorrect) {
      setTryCounter(setTryStatus(id, true));
      setIsCorrectAttempt(true);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);
      setLetterBoxes(createLetterBoxes(letters));
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [title]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[title] || {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[title]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, title, user.exercises]
  );

  useEffect(() => {
    const showAnswer = () =>
      tryCounter.some(item => item.tryStatus === true) ||
      tryCounter.every(item => item.tryStatus === false);

    const checkAnswer = showAnswer();

    if (checkAnswer) {
      setLetterBoxes(createCorrectAnswer(correctlyAnswer));
      setIsCorrectAttempt(true);
    }
  }, [tryCounter, correctlyAnswer]);

  const dbClickHandler = (e, clickedItem) => {
    if (!isCorrectAttempt) {
      const idOfLetterToAdd = clickedItem.from;

      // Определите индекс элемента в массиве letterBoxes
      const clickedItemIndex = letterBoxes.findIndex(
        item => item.id === clickedItem.id
      );

      // Обновите letterBoxes, добавив выбранную букву обратно в список букв
      setLetterBoxes(prevState => {
        return prevState.map((item, index) => {
          if (index === clickedItemIndex) {
            setTimeout(() => {
              setLetterBoxes(prevState => {
                return prevState.map((innerItem, innerIndex) => {
                  if (idOfLetterToAdd === innerItem.order) {
                    return {
                      ...innerItem,
                      visible: true,
                    };
                  }
                  return innerItem;
                });
              });
            }, 50); // задержка в 50 миллисекунд
            return {
              ...item,
              letterForUser: null,
              from: null,
            };
          }
          return item;
        });
      });
    }
  };

  return (
    <div className={style.container}>
      <p className={style.title}>{title}</p>

      <div className={style.boxContainer}>
        {letterBoxes.map((item, index) => (
          <EmptyBox
            key={index}
            index={index}
            item={item}
            onContainerClick={onEmptyBoxClick}
            dbClickHandler={dbClickHandler}
          />
        ))}
      </div>

      <div className={style.boxContainer}>
        {letterBoxes
          .filter(item => item.visible)
          .map((item, index) => (
            <BoxWithLetter
              key={index}
              item={item}
              onLetterClick={onLetterClick}
              isSelected={selectedLetter === item}
              onContainerClick={onEmptyBoxClick}
            />
          ))}
      </div>

      <CheckAnswerBtn
        tryCounter={tryCounter}
        onCheckBtnClick={onCheckBtnClick}
      />
    </div>
  );
};

export default CreateWordLibrary;
