import { Box, Paper } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { setTryStatus } from '../helpers/setTryStatus';
import css from './WriteWord.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const CheckCardWriteWord = ({
  index,
  isCorrect,
  word,
  borderColors,
  guesses,
  handleInputChange,
  correctWords,
  setIsCorrect,
  setBorderColors,
  showCorrectWord,
  number,
}) => {
  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);

  const [id, setId] = useState(1);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const isAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[word];

    if (isAnswer) {
      setTryCounter(Object.values(isAnswer)[0].tryCounterData);
    }
  }, [lesson, module, number, user.exercises, word]);

  const onCheckBtnClick = index => {
    const guess = guesses[index].toLowerCase().trim();
    const correctWord = correctWords[index];

    if (guess === correctWord) {
      setIsCorrect(prevIsCorrect => {
        const newIsCorrect = [...prevIsCorrect];
        newIsCorrect[index] = true;
        return newIsCorrect;
      });
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'green';
      setBorderColors(newBorderColors);
      setTryCounter(setTryStatus(id, true));

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'red';
      setBorderColors(newBorderColors);
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [word]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[word] || {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[word]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises, word]
  );

  useEffect(() => {
    const showAnswer = () => {
      return tryCounter.every(
        item => !item.tryStatus && item.tryStatus !== null
      );
    };
    const checkAnswer = showAnswer();

    if (checkAnswer && !isCorrect[index]) {
      showCorrectWord(index, correctWords[index]);
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'green';
      setBorderColors(newBorderColors);
      setIsCorrect(prevIsCorrect => {
        const newIsCorrect = [...prevIsCorrect];
        newIsCorrect[index] = true;
        return newIsCorrect;
      });
    }
  }, [
    showCorrectWord,
    index,
    correctWords,
    borderColors,
    setBorderColors,
    setIsCorrect,
    isCorrect,
    tryCounter,
  ]);

  return (
    <Box
      // item
      key={index}
      sx={{
        marginBottom: { mobile: '16px' },
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { mobile: '10px', laptop: '18px' },
          gap: { mobile: '8px', tablet: '10px' },
          borderColor: borderColors[index],
          // transition: 'border-color 0.3s ease',
          width: {
            mobile: '288px',
            iphone: '358px',
            tablet: '270px',
            laptop: '250px',
          },
          boxShadow:
            'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
          border: { mobile: '4px' },
        }}
      >
        <p>{word}</p>
        <input
          type="text"
          className={`${css.input} ${
            isCorrect[index] ? css.correctAnswer : css.incorrectAnswer
          }`}
          value={
            tryCounter.some(item => item.tryStatus === true) ||
            tryCounter.every(item => item.tryStatus === false)
              ? correctWords[index]
              : guesses[index]
          }
          onChange={event => handleInputChange(index, event)}
          style={{
            borderColor:
              tryCounter.some(item => item.tryStatus === true) ||
              tryCounter.every(item => item.tryStatus === false)
                ? 'green'
                : borderColors[index],
          }}
          readOnly={isCorrect[index]}
        />
        <CheckAnswerBtn
          tryCounter={tryCounter}
          onCheckBtnClick={() => onCheckBtnClick(index)}
        />
      </Paper>
    </Box>
  );
};

export default CheckCardWriteWord;
