import commonStyles from '../../LessonsPage.module.css';

export default function Video() {
  return (
    <div>
      <div>
        <iframe
          src="https://player.vimeo.com/video/891872264?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          width="738"
          height="415"
          allow="autoplay; fullscreen; picture-in-picture"
          className={commonStyles.lessonVideo}
          title="Урок 5. Tener"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}
