import React, { useEffect } from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import { useDispatch } from 'react-redux';
import { getAllWordsThunk } from 'store/dictionary/thunk';

import Vocabulary100 from './Vocabulary100';

const Numbers100 = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const url =
    'https://drive.google.com/file/d/1va_tOMXsYNhOz-WWe33vwGnboOX5kmSM/preview';

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson3.numbers10')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1. {translate('lesson1.summary-2')}
      </h2>

      <iframe
        title="pdf"
        src={url}
        frameBorder="*"
        allowFullScreen="allowfullscreen"
        width="738"
        height="415"
        className={commonStyles.summary}
      ></iframe>

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('lesson1.task3')} Vocabulario. Puede añadir las palabras
        a su propio vocabulario.
      </h2>

      <Vocabulary100 />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Numbers100;
