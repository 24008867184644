import { Card, Stack, Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import Login from '../../components/Login/Login';
import { loginThunk } from '../../store/auth/thunks';
import useLocales from 'hooks/useLocales';
import Logo from 'components/Header/Logo/Logo';
import css from './LoginPage.module.css';
import { toast } from 'react-toastify';

export const Container = styled(Card)`
  box-shadow: 0px 0px 48px gray;
  margin: 0 auto;
  width: 600px;
  border-radius: 12px;
  padding: 24px 0 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  a.homeLink {
    padding-left: 24px;
    color: blue;
  }
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  // const error = useSelector(state => state.auth.error);

  const notifyErrorPassword = () =>
    toast.error(translate('profile.errorOnSave'), {});

  const handleSubmit = body => {
    dispatch(loginThunk(body))
      .unwrap()
      .catch(error => {
        notifyErrorPassword();
      });
  };

  return (
    <>
      <div className={css.loginHeader}>
        <Logo />
      </div>

      <Container
        sx={{
          width: {
            mobile: 290,
            iphone: 340,
            tablet: 460,
            laptop: 500,
            desktop: 600,
          },
          m: '0 auto',
          padding: { mobile: ' 20px 0 20px', tablet: '32px 0 32px' },
          boxShadow: {
            mobile: '0px 0px 12px gray',
            tablet: '0px 0px 20px gray',
          },
        }}
      >
        <Stack
        // sx={{ margin: { mobile: '20px 0px', laptop: '48px 0px' } }}
        >
          <Typography
            sx={{
              m: '0 auto',
              fontSize: { mobile: '26px', tablet: '28px', laptop: '30px' },
            }}
          >
            {translate('login.login')}
          </Typography>
        </Stack>

        <Login handleSubmit={handleSubmit} />
        {/* <Link to="/forget-password" className={css.forgetPsw}>
          Forget pasword?
        </Link> */}

        {/* {error && <p>{error}</p>} */}
      </Container>
    </>
  );
};

export default LoginPage;
