import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson10 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. Tengo hambre, ¿ conoces ... restaurante bueno ?',
      options: ['algún', 'alguno', 'algo'],
      correctAnswer: 'algún',
    },
    {
      question: '2. ... me puede ayudar, estoy desesperado.',
      options: ['ninguno', 'nadie', 'ninguno de'],
      correctAnswer: 'nadie',
    },
    {
      question: '3. ¿ Vamos a tomar ... ?',
      options: ['algo', 'nada', 'alguna'],
      correctAnswer: 'algo',
    },
    {
      question: '4. El texto tiene ... errores.',
      options: ['ninguno', 'ningunas', 'algunos'],
      correctAnswer: 'algunos',
    },
    {
      question: '5. Estamos de vacaciones, queremos ir a ... .',
      options: ['alguna parte', 'ninguna parte', 'nada'],
      correctAnswer: 'alguna parte',
    },
    {
      question: '6. Yo te ... el trabajo que haces para mí.',
      options: ['agradeces', 'agradece', 'agradezco'],
      correctAnswer: 'agradezco',
    },
    {
      question: '7. Jorge se ... mucho a su madre.',
      options: ['parece', 'parecen', 'parezco'],
      correctAnswer: 'parece',
    },
    {
      question: '8. Saber esperar y escuchar- con este don no ... cada uno.',
      options: ['nacemos', 'nace', 'nazco'],
      correctAnswer: 'nace',
    },
    {
      question: '9. Yo no ... muy bien esta ciudad.',
      options: ['conoce', 'conozco', 'conocemos'],
      correctAnswer: 'conozco',
    },
    {
      question:
        '10. ... de nosotros quiere quedarse en casa esta noche, queremos salir a tomar ... postre después de la cena.',
      options: ['Alguien, algo', 'Alguno, nada', 'Ninguno, algún'],
      correctAnswer: 'Ninguno, algún',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson10;
