import { useTranslation } from 'react-i18next';
import { allLangs, defaultLang } from '../configs/i18n';
import { useState } from 'react';
// import getUserLocale from 'get-user-locale';
// import detectBrowserLanguage from 'detect-browser-language';

export default function useLocales() {
  const { t: translate } = useTranslation();

  const [currentLang, setCurrentLang] = useState(() => {
    const langStorage = localStorage.getItem('i18nextLng');

    const lang =
      allLangs.find(_lang => _lang.value === langStorage) || defaultLang;
    return lang;
  });

  // const userLocale = getUserLocale();
  // const browserLanguage = detectBrowserLanguage();
  // console.log(browserLanguage);

  const handleChangeLanguage = async (language, loginPage) => {
    const newLang = allLangs.find(({ value }) => value === language);
    setCurrentLang(newLang);

    // i18n.changeLanguage(language);
    // localStorage.setItem('i18nextLng', language);
    // return !loginPage && (await putApi("users/change-language", { language }));
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs,
  };
}
