import { ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const SidebarItem = ({ item }) => {
  const location = useLocation();
  const isActive = location.pathname === item.path;

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      // disabled={
      //   index >= user.lessonPage.slice(user.lessonPage.indexOf('/') + 1)
      // }
      to={item.path}
      sx={{
        '&: hover': {
          backgroundColor: '#e0e3e8',
          color: '#90adc6',
          fontWeight: '500',
        },

        backgroundColor: '#e0e3e8',
        // backgroundColor: '#fbfbfd',
        fontWeight: isActive ? '500' : '400',
        color: isActive ? '#90adc6' : '#364f6a', // Устанавливаем цвет текста в зависимости от активности
        paddingY: '10px',
        paddingLeft: { tablet: '42px', laptop: '58px' },
        paddingRight: { tablet: '10px', laptop: '14px' },

        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
