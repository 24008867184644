import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPosts, updatePost } from "../../api/posts";

// export const getAllPostsThunk = createAsyncThunk(
//   "posts/getAllPosts",
//   async ({ page, limit }, { rejectWithValue }) => {
//     try {
//       const data = await getPosts();
//       console.log(data);
//       return await getPosts(page, limit);
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const getAllPostsThunk = createAsyncThunk(
  "posts/getAllPosts",
  (params = { page: 1, limit: 10 }) => {
    return getPosts(params.page, params.limit);
  }
);

export const updatePostThunk = createAsyncThunk(
  "posts/updatePost",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const data = await updatePost(body);
      dispatch(getAllPostsThunk());
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
