import { useMediaQuery } from '@react-hook/media-query';

import BurgerMenu from './BurgerMenu/BurgerMenu';
import css from './Header.module.css';
import Nav from './Navigation/Nav/Nav';
import UserNav from './Navigation/UserNav/UserNav';
import Logo from './Logo/Logo';

const Header = () => {
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  return (
    <>
      {isTabletScreen && (
        <header>
          <div className={css.headerNav}>
            <Nav />
            <UserNav />
          </div>
        </header>
      )}
      {isMobileScreen && (
        <div className={css.headerNav}>
          <Logo />
          <BurgerMenu />
        </div>
      )}
    </>
  );
};

export default Header;
