export const PATH_LESSON_1 = {
  lesson1_1: '/lessons/lesson1/1',
  lesson1_2: '/lessons/lesson1/2',
  lesson1_3: '/lessons/lesson1/3',
  lesson1_4: '/lessons/lesson1/4',
  lesson1_5: '/lessons/lesson1/5',
  lesson1_6: '/lessons/lesson1/6',
  lesson1_7: '/lessons/lesson1/7',
};
export const PATH_LESSON_1_ROUTES = Object.values(PATH_LESSON_1);

export const PATH_LESSON_2 = {
  lesson2_1: '/lessons/lesson2/1',
  lesson2_2: '/lessons/lesson2/2',
  lesson2_3: '/lessons/lesson2/3',
  lesson2_4: '/lessons/lesson2/4',
  lesson2_5: '/lessons/lesson2/5',
  lesson2_6: '/lessons/lesson2/6',
};
export const PATH_LESSON_2_ROUTES = Object.values(PATH_LESSON_2);

export const PATH_LESSON_3 = {
  lesson3_1: '/lessons/lesson3/1',
  lesson3_2: '/lessons/lesson3/2',
  lesson3_3: '/lessons/lesson3/3',
  lesson3_4: '/lessons/lesson3/4',
  lesson3_5: '/lessons/lesson3/5',
  lesson3_6: '/lessons/lesson3/6',
};
export const PATH_LESSON_3_ROUTES = Object.values(PATH_LESSON_3);

export const PATH_LESSON_4 = {
  lesson4_1: '/lessons/lesson4/1',
  lesson4_2: '/lessons/lesson4/2',
  lesson4_3: '/lessons/lesson4/3',
  lesson4_4: '/lessons/lesson4/4',
};
export const PATH_LESSON_4_ROUTES = Object.values(PATH_LESSON_4);

export const PATH_LESSON_5 = {
  lesson5_1: '/lessons/lesson5/1',
  lesson5_2: '/lessons/lesson5/2',
  lesson5_3: '/lessons/lesson5/3',
  lesson5_4: '/lessons/lesson5/4',
  lesson5_5: '/lessons/lesson5/5',
};
export const PATH_LESSON_5_ROUTES = Object.values(PATH_LESSON_5);

export const PATH_LESSON_6 = {
  lesson6_1: '/lessons/lesson6/1',
  lesson6_2: '/lessons/lesson6/2',
  lesson6_3: '/lessons/lesson6/3',
  lesson6_4: '/lessons/lesson6/4',
  lesson6_5: '/lessons/lesson6/5',
};
export const PATH_LESSON_6_ROUTES = Object.values(PATH_LESSON_6);

export const PATH_LESSON_7 = {
  lesson7_1: '/lessons/lesson7/1',
  lesson7_2: '/lessons/lesson7/2',
  lesson7_3: '/lessons/lesson7/3',
  lesson7_4: '/lessons/lesson7/4',
};
export const PATH_LESSON_7_ROUTES = Object.values(PATH_LESSON_7);

export const PATH_LESSON_8 = {
  lesson8_1: '/lessons/lesson8/1',
  lesson8_2: '/lessons/lesson8/2',
  lesson8_3: '/lessons/lesson8/3',
};
export const PATH_LESSON_8_ROUTES = Object.values(PATH_LESSON_8);

export const PATH_LESSON_9 = {
  lesson9_1: '/lessons/lesson9/1',
  lesson9_2: '/lessons/lesson9/2',
};
export const PATH_LESSON_9_ROUTES = Object.values(PATH_LESSON_9);

export const PATH_LESSON_10 = {
  lesson10_1: '/lessons/lesson10/1',
  lesson10_2: '/lessons/lesson10/2',
};
export const PATH_LESSON_10_ROUTES = Object.values(PATH_LESSON_10);

export const PATH_LESSON_11 = {
  lesson11_1: '/lessons/lesson11/1',
  lesson11_2: '/lessons/lesson11/2',
  lesson11_3: '/lessons/lesson11/3',
};
export const PATH_LESSON_11_ROUTES = Object.values(PATH_LESSON_11);

export const PATH_LESSON_12 = {
  lesson12_1: '/lessons/lesson12/1',
};
export const PATH_LESSON_12_ROUTES = Object.values(PATH_LESSON_12);

export const LESSON = {
  1: PATH_LESSON_1_ROUTES,
  2: PATH_LESSON_2_ROUTES,
  3: PATH_LESSON_3_ROUTES,
  4: PATH_LESSON_4_ROUTES,
  5: PATH_LESSON_5_ROUTES,
  6: PATH_LESSON_6_ROUTES,
  7: PATH_LESSON_7_ROUTES,
  8: PATH_LESSON_8_ROUTES,
  9: PATH_LESSON_9_ROUTES,
  10: PATH_LESSON_10_ROUTES,
  11: PATH_LESSON_11_ROUTES,
  12: PATH_LESSON_12_ROUTES,
};
