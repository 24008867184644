import React, { useEffect } from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import { Button, ButtonGroup, Grid, Stack } from '@mui/material';
import cero from '../../../../assets/images/lesson3/zero.png';
import uno from '../../../../assets/images/lesson3/one.png';
import dos from '../../../../assets/images/lesson3/two.png';
import tres from '../../../../assets/images/lesson3/three.png';
import cuatro from '../../../../assets/images/lesson3/four.png';
import cinco from '../../../../assets/images/lesson3/five.png';
import seis from '../../../../assets/images/lesson3/six.png';
import siete from '../../../../assets/images/lesson3/seven.png';
import ocho from '../../../../assets/images/lesson3/eight.png';
import nueve from '../../../../assets/images/lesson3/nine.png';
import diez from '../../../../assets/images/lesson3/ten.png';
import CardWord from './CardWord';
import { useDispatch, useSelector } from 'react-redux';
import { wordsSelector } from 'store/dictionary/selectors';
import { getAllWordsThunk } from 'store/dictionary/thunk';
import { userToggleWordThunk } from 'store/auth/thunks';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { theme } from 'theme/theme';
import { getAudio } from 'api/textToSpeech';

const images = [
  cero,
  uno,

  dos,
  tres,
  cuatro,
  cinco,
  seis,
  siete,
  ocho,
  nueve,
  diez,
];
const correctWords = [
  'cero',
  'uno',

  'dos',
  'tres',
  'cuatro',
  'cinco',
  'seis',
  'siete',
  'ocho',
  'nueve',
  'diez',
];

const Numbers10 = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { words } = useSelector(wordsSelector);

  const { currentLang } = useLocales();

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  const handleClick = id => {
    dispatch(userToggleWordThunk({ id }));
  };

  const filteredWords = words.filter(word => word.lesson === 'los nùmeros');

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson3.numbers10')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('lesson1.task3')} Vocabulario. Puede añadir las palabras
        a su propio vocabulario.
      </h2>

      <div>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: { tablet: 'wrap' },
            columnGap: { mobile: '16px' },
            rowGap: { mobile: '16px' },
          }}
        >
          {images.map((image, index) => {
            const filteredWord = filteredWords.find(
              word => word.word === correctWords[index]
            );

            return (
              <Stack
                key={index}
                sx={{
                  flexDirection: { laptop: 'column' },
                  // alignItems: { mobile: 'center' },
                }}
              >
                <CardWord
                  key={index}
                  index={index}
                  image={image}
                  correctWords={correctWords}
                  images={images}
                />
                {filteredWord && (
                  <Stack
                    display="flex"
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    key={filteredWord._id}
                    image={index}
                  >
                    <ButtonGroup variant="text" aria-label="text button group">
                      <Button
                        onClick={() => handleClick(filteredWord._id)}
                        disabled={
                          user.dictionary.isOnLearning.find(
                            item => item._id === filteredWord._id
                          ) ||
                          user.dictionary.isLearned.find(
                            item => item._id === filteredWord._id
                          )
                        }
                      >
                        {user.dictionary.isOnLearning.find(
                          item => item._id === filteredWord._id
                        ) ||
                        user.dictionary.isLearned.find(
                          item => item._id === filteredWord._id
                        ) ? (
                          <LibraryAddCheckIcon
                            sx={{ color: theme.palette.primary.blue }}
                          />
                        ) : (
                          <LibraryAddIcon
                            sx={{ color: theme.palette.primary.blue }}
                          />
                        )}
                      </Button>
                      <Button
                        onClick={async () =>
                          await getAudio({ text: filteredWord.word })
                        }
                      >
                        <VolumeUpIcon
                          sx={{ color: theme.palette.primary.blue }}
                        />
                      </Button>
                    </ButtonGroup>

                    {isMobileScreen && (
                      <Stack sx={{ flexDirection: 'row', gap: '4px' }}>
                        <p className={commonStyles.spanishWord}>
                          {filteredWord.word}
                        </p>{' '}
                        <p> - </p>
                        <p className={commonStyles.spanishWordTranslation}>
                          {filteredWord[`translation_${currentLang.value}`]}
                        </p>
                      </Stack>
                    )}
                    {isTabletScreen && (
                      <>
                        <p className={commonStyles.spanishWord}>
                          {filteredWord.word}
                        </p>
                        <p className={commonStyles.spanishWordTranslation}>
                          - {filteredWord[`translation_${currentLang.value}`]}
                        </p>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            );
          })}
        </Grid>
      </div>

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Numbers10;
