import Digraphs from 'pages/Lessons/Lesson1/Digraphs/Digraphs';
import Emphasis from 'pages/Lessons/Lesson1/Emphasis/Emphasis';
import Exercises from 'pages/Lessons/Lesson1/Exercises/Exercises';
import Exercises2 from 'pages/Lessons/Lesson1/Exercises2/Exercises2';
import Instructions from 'pages/Lessons/Lesson1/Instructions/Instructions';
import TestLesson1 from 'pages/Lessons/Lesson1/Test/TestLesson1';
import React from 'react';
import { useParams } from 'react-router-dom';
import Alphabet from 'pages/Lessons/Lesson1/Alphabet/Alphabet';
import Article from 'pages/Lessons/Lesson2/Article/Article';
import WordsOrder from 'pages/Lessons/Lesson2/WordsOrder/WordsOrder';
import GreetingsAndFarewells from 'pages/Lessons/Lesson2/GreetingsAndFarewells/GreetingsAndFarewells';
import Countries from 'pages/Lessons/Lesson2/Countries/Countries';
import Ser from 'pages/Lessons/Lesson3/Ser/Ser';
import Numbers10 from 'pages/Lessons/Lesson3/Numbers10/Numbers10';
import Llamarse from 'pages/Lessons/Lesson3/Llamarse/Llamarse';
import Professions from 'pages/Lessons/Lesson3/Professions/Professions';
import Exercises1Lesson2 from 'pages/Lessons/Lesson2/Exercises/Exercises1Lesson2';
import TestLesson2 from 'pages/Lessons/Lesson2/Test/TestLesson2';
import Nouns from 'pages/Lessons/Lesson4/Nouns/Nouns';
import Adjectives from 'pages/Lessons/Lesson4/Adjectives/Adjectives';
import Present from 'pages/Lessons/Lesson5/Present/Present';
import Prepositions from 'pages/Lessons/Lesson5/Prepositions/Prepositions';
import Tener from 'pages/Lessons/Lesson5/Tener/Tener';
import Questions from 'pages/Lessons/Lesson6/Questions/Questions';
import ImpersonalVerb from 'pages/Lessons/Lesson6/ImpersonalVerb/ImpersonalVerb';
import IrregularVerbs from 'pages/Lessons/Lesson6/IrregularVerbs/IrregularVerbs';
import Pronouns from 'pages/Lessons/Lesson7/Pronouns/Pronouns';
import DegreesOfComparison from 'pages/Lessons/Lesson7/DegreesOfComparison/DegreesOfComparison';
import IrregularVerbs2 from 'pages/Lessons/Lesson7/IrregularVerbs2/IrregularVerbs2';
import GrammarСonstr from 'pages/Lessons/Lesson7/GrammarConstr/GrammarСonstr';
import Numbers100 from 'pages/Lessons/Lesson8/Numbers100/Numbers100';
import VerbosReflexivos from 'pages/Lessons/Lesson8/VerbosReflexivos/VerbosReflexivos';
import IrregularVerbs3 from 'pages/Lessons/Lesson8/IrregularVerbs3/IrregularVerbs3';
import Application from 'pages/Lessons/Lesson9/Application/Application';
import IrregularVerbs5 from 'pages/Lessons/Lesson9/IrregularVerbs5/IrregularVerbs5';
import PronounsAndAdverbs from 'pages/Lessons/Lesson10/PronounsAndAdverbs/PronounsAndAdverbs';
import IrregularVerbs7 from 'pages/Lessons/Lesson10/IrregularVerbs7/IrregularVerbs7';
import Gerundio from 'pages/Lessons/Lesson11/Gerundio/Gerundio';
import Tambien from 'pages/Lessons/Lesson11/Tambien/Tambien';
import IrregularVerbs8 from 'pages/Lessons/Lesson11/IrregularVerbs8/IrregularVerbs8';
import PastTense from 'pages/Lessons/Lesson12/PastTense/PastTense';
import Exercises1Lesson3 from 'pages/Lessons/Lesson3/Exercises/Exercises1Lesson3';
import TestLesson3 from 'pages/Lessons/Lesson3/Test/TestLesson3';
import Exercises1Lesson4 from 'pages/Lessons/Lesson4/Exercises/Exercises1Lesson4';
import TestLesson4 from 'pages/Lessons/Lesson4/Test/TestLesson4';
import Exercises1Lesson5 from 'pages/Lessons/Lesson5/Exercises/Exercises1Lesson5';
import TestLesson5 from 'pages/Lessons/Lesson5/Test/TestLesson5';
import Exercises1Lesson6 from 'pages/Lessons/Lesson6/Exercises/Exercises1Lesson6';
import TestLesson6 from 'pages/Lessons/Lesson6/Test/TestLesson6';
import Exercises1Lesson7 from 'pages/Lessons/Lesson7/Exercises/Exercises1Lesson7';
import TestLesson7 from 'pages/Lessons/Lesson7/Test/TestLesson7';
import Exercises1Lesson8 from 'pages/Lessons/Lesson8/Exercises/Exercises1Lesson8';
import Numbers1000 from 'pages/Lessons/Lesson8/Numbers1000/Numbers1000';
import TestLesson8 from 'pages/Lessons/Lesson8/Test/TestLesson8';
import Exercises1Lesson9 from 'pages/Lessons/Lesson9/Exercises/Exercises1Lesson9';
import TestLesson9 from 'pages/Lessons/Lesson9/Test/TestLesson9';
import Exercises1Lesson10 from 'pages/Lessons/Lesson10/Exercises/Exercises1Lesson10';
import TestLesson10 from 'pages/Lessons/Lesson10/Test/TestLesson10';
import Exercises1Lesson11 from 'pages/Lessons/Lesson11/Exercises/Exercises1Lesson11';
import Exercises1Lesson12 from 'pages/Lessons/Lesson12/Exercises/Exercises1Lesson12';

const LessonWrap = () => {
  const { module, lesson } = useParams();

  const moduleNumber = Number(module.slice(6));

  if (moduleNumber === 1) {
    if (lesson === '1') {
      return <Instructions />;
    }
    if (lesson === '2') {
      return <Alphabet />;
    }
    if (lesson === '3') {
      return <Exercises />;
    }
    if (lesson === '4') {
      return <Digraphs />;
    }
    if (lesson === '5') {
      return <Emphasis />;
    }
    if (lesson === '6') {
      return <Exercises2 />;
    }
    if (lesson === '7') {
      return <TestLesson1 />;
    }
  }

  if (moduleNumber === 2) {
    if (lesson === '1') {
      return <Article />;
    }
    if (lesson === '2') {
      return <WordsOrder />;
    }
    if (lesson === '3') {
      return <GreetingsAndFarewells />;
    }
    if (lesson === '4') {
      return <Countries />;
    }
    if (lesson === '5') {
      return <Exercises1Lesson2 />;
    }
    if (lesson === '6') {
      return <TestLesson2 />;
    }
  }

  if (moduleNumber === 3) {
    if (lesson === '1') {
      return <Ser />;
    }
    if (lesson === '2') {
      return <Numbers10 />;
    }
    if (lesson === '3') {
      return <Llamarse />;
    }
    if (lesson === '4') {
      return <Professions />;
    }
    if (lesson === '5') {
      return <Exercises1Lesson3 />;
    }
    if (lesson === '6') {
      return <TestLesson3 />;
    }
  }

  if (moduleNumber === 4) {
    if (lesson === '1') {
      return <Nouns />;
    }
    if (lesson === '2') {
      return <Adjectives />;
    }
    if (lesson === '3') {
      return <Exercises1Lesson4 />;
    }
    if (lesson === '4') {
      return <TestLesson4 />;
    }
  }

  if (moduleNumber === 5) {
    if (lesson === '1') {
      return <Present />;
    }
    if (lesson === '2') {
      return <Prepositions />;
    }
    if (lesson === '3') {
      return <Tener />;
    }
    if (lesson === '4') {
      return <Exercises1Lesson5 />;
    }
    if (lesson === '5') {
      return <TestLesson5 />;
    }
  }

  if (moduleNumber === 6) {
    if (lesson === '1') {
      return <Questions />;
    }
    if (lesson === '2') {
      return <ImpersonalVerb />;
    }
    if (lesson === '3') {
      return <IrregularVerbs />;
    }
    if (lesson === '4') {
      return <Exercises1Lesson6 />;
    }
    if (lesson === '5') {
      return <TestLesson6 />;
    }
  }

  if (moduleNumber === 7) {
    if (lesson === '1') {
      return <Pronouns />;
    }
    if (lesson === '2') {
      return <DegreesOfComparison />;
    }
    if (lesson === '3') {
      return <GrammarСonstr />;
    }
    if (lesson === '4') {
      return <IrregularVerbs2 />;
    }
    if (lesson === '5') {
      return <Exercises1Lesson7 />;
    }
    if (lesson === '6') {
      return <TestLesson7 />;
    }
  }

  if (moduleNumber === 8) {
    if (lesson === '1') {
      return <Numbers100 />;
    }
    if (lesson === '2') {
      return <Numbers1000 />;
    }
    if (lesson === '3') {
      return <VerbosReflexivos />;
    }
    if (lesson === '4') {
      return <IrregularVerbs3 />;
    }
    if (lesson === '5') {
      return <Exercises1Lesson8 />;
    }
    if (lesson === '6') {
      return <TestLesson8 />;
    }
  }

  if (moduleNumber === 9) {
    if (lesson === '1') {
      return <Application />;
    }
    if (lesson === '2') {
      return <IrregularVerbs5 />;
    }
    if (lesson === '3') {
      return <Exercises1Lesson9 />;
    }
    if (lesson === '4') {
      return <TestLesson9 />;
    }
  }

  if (moduleNumber === 10) {
    if (lesson === '1') {
      return <PronounsAndAdverbs />;
    }
    if (lesson === '2') {
      return <IrregularVerbs7 />;
    }
    if (lesson === '3') {
      return <Exercises1Lesson10 />;
    }
    if (lesson === '4') {
      return <TestLesson10 />;
    }
  }

  if (moduleNumber === 11) {
    if (lesson === '1') {
      return <Gerundio />;
    }
    if (lesson === '2') {
      return <Tambien />;
    }
    if (lesson === '3') {
      return <IrregularVerbs8 />;
    }
    if (lesson === '4') {
      return <Exercises1Lesson11 />;
    }
  }

  if (moduleNumber === 12) {
    if (lesson === '1') {
      return <PastTense />;
    }
    if (lesson === '2') {
      return <Exercises1Lesson12 />;
    }
  }
};

export default LessonWrap;
