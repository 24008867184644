import { Box, Paper } from '@mui/material';
import css from './Numbers10.module.css';

const CardWord = ({
  index,

  image,

  correctWords,
}) => {
  return (
    <Box
      // item
      key={index}
      sx={{
        marginBottom: { mobile: '16px' },
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { mobile: '10px', laptop: '18px' },
          // gap: { mobile: '8px', tablet: '10px' },
          // transition: 'border-color 0.3s ease',
          width: {
            mobile: '260px',
            iphone: '358px',
            tablet: '270px',
            laptop: '250px',
          },
          boxShadow: {
            mobile: 'none',
            // iphone: 'none',
            iphone:
              'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
          },
          // boxShadow: { mobile: '0px 0px 6px gray' },
          border: { mobile: '4px' },
        }}
      >
        <img
          src={image}
          alt={`ImagesToTranslate ${index}`}
          className={css.img}
        />
      </Paper>
    </Box>
  );
};

export default CardWord;
