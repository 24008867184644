import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { postsSelector } from '../../../store/posts/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CssBaseline,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';
import { wrapUrlsInAnchorTags } from '../../../utils/wrapUrl';
import GoBackBtn from '../../../components/Buttons/GoBackBtn/GoBackBtn';
import { updateFavoritesThunk } from 'store/auth/thunks';

const BlogDetailedPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { posts } = useSelector(postsSelector);
  const post = useMemo(() => posts.find(post => post._id === id), [posts, id]);
  const { user } = useSelector(state => state.auth);

  const handleFavorite = () => {
    dispatch(updateFavoritesThunk(post._id));
  };
  const text = wrapUrlsInAnchorTags(post.body);
  return (
    post && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: {
            mobile: '82px 10px 80px 10px',
            tablet: '92px 10px 80px 10px',
            laptop: '100px 50px 50px 50px',
          },
          backgroundColor: '#e0e3e8',
        }}
      >
        <CssBaseline />

        <Stack
          sx={{
            display: 'flex',
          }}
        >
          <GoBackBtn />

          <Card
            sx={{
              maxWidth: '1000px',
              mt: { mobile: '14px', tablet: '14px', laptop: '24px' },
            }}
          >
            <CardHeader
              title={post.title}
              disableTypography={true}
              sx={{
                padding: { mobile: '14px 16px 0px 16px ' },
                fontSize: { mobile: '18px', tablet: '20px', laptop: '22px' },
                height: { mobile: '78px', tablet: '78px' },
                alignItems: 'flex-start',
              }}
            />
            <CardHeader
              subheader={new Date(post.createdAt).toLocaleDateString()}
              disableTypography={true}
              sx={{
                pt: 0,
                pb: '5px',
                fontSize: { mobile: '12px', tablet: '14px' },
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            />

            <CardMedia
              component="img"
              image={post.image}
              alt="post image"
              sx={{
                objectFit: 'cover',
                height: { mobile: '198px', tablet: '300px' },
              }}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                aria-label="add to favorites"
                onClick={handleFavorite}
              >
                {user.favoritePosts.includes(post._id) && (
                  <FavoriteIcon sx={{ color: '#f8bc02' }} />
                )}
                {!user.favoritePosts.includes(post._id) && (
                  <FavoriteIcon sx={{ color: 'inherit' }} />
                )}
                {/* <FavoriteIcon
                  color={
                    user.favoritePosts.includes(post._id)
                      ? '#f8bc02'
                      : 'inherit'
                  }
                /> */}
              </IconButton>
              {user.role === 'Admin' && (
                <IconButton
                  aria-label="add to favorites"
                  onClick={() => navigate(`/blog/edit/${post._id}`)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </CardActions>
          </Card>
        </Stack>
      </Box>
    )
  );
};

export default BlogDetailedPage;
