import { Button, ButtonGroup, Stack } from '@mui/material';
import { theme } from '../../../../theme/theme';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import useLocales from 'hooks/useLocales';
import { getAudio } from 'api/textToSpeech';

const vowels = ['A', 'E', 'I', 'O', 'U'];
const consonants = [
  'B',
  'C',
  'D',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'Ñ',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const Lesson1Alphabet = () => {
  const { translate } = useLocales();

  return (
    <div>
      <p>{translate('lesson1.vowels')} Letras vocales.</p>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: { mobile: '16px' },
          marginBottom: { mobile: '16px' },
          flexWrap: { mobile: 'wrap', laptop: 'nowrap' },
          rowGap: { mobile: '14px' },
          columnGap: {
            mobile: '44px',
            iphone: '78px',
            tablet: '46px',
            laptop: '40px',
          },
        }}
      >
        {vowels.map(letter => (
          <Stack
            sx={{
              flexDirection: { mobile: 'row' },
              justifyContent: { mobile: 'center' },
              alignItems: { mobile: 'center' },
              gap: { mobile: '8px' },
              width: { mobile: '64px' },
            }}
            key={letter}
          >
            <ButtonGroup variant="text" aria-label="text button group">
              <Button onClick={async () => await getAudio({ text: letter })}>
                <VolumeUpIcon sx={{ color: theme.palette.primary.blue }} />
              </Button>
            </ButtonGroup>
            <p
              style={{
                fontWeight: 600,
                fontSize: '20px',
                color: theme.palette.primary.main,
              }}
            >
              {letter}
            </p>
          </Stack>
        ))}
      </Stack>

      <p>{translate('lesson1.consonants')} Letras consonantes.</p>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: { mobile: '16px' },
          marginBottom: { mobile: '16px' },
          flexWrap: { mobile: 'wrap' },
          rowGap: { mobile: '14px' },
          columnGap: {
            mobile: '44px',
            iphone: '78px',
            tablet: '46px',
            laptop: '40px',
          },
        }}
      >
        {consonants.map(letter => (
          <Stack
            sx={{
              flexDirection: { mobile: 'row' },
              justifyContent: { mobile: 'center' },
              alignItems: { mobile: 'center' },
              gap: { mobile: '8px' },
              width: { mobile: '64px' },
            }}
            key={letter}
          >
            <ButtonGroup variant="text" aria-label="text button group">
              <Button onClick={async () => await getAudio({ text: letter })}>
                <VolumeUpIcon sx={{ color: theme.palette.primary.blue }} />
              </Button>
            </ButtonGroup>
            <p
              style={{
                fontWeight: 600,
                fontSize: '20px',
                color: theme.palette.primary.main,
              }}
            >
              {letter}
            </p>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default Lesson1Alphabet;
