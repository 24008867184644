import React from 'react';
import { SideBar } from '../../components/SideBar/Sidebar';
import { Outlet } from 'react-router-dom';
import { Box, Container, CssBaseline, useMediaQuery } from '@mui/material';
import ContainerFooter from 'components/Containers/ContainerFooter/ContainerFooter';
import Footer from 'components/Footer/Footer';

const LessonsPage = () => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <CssBaseline />
        <SideBar />
        <Container
          fixed
          sx={{
            marginBottom: { mobile: '90px', tablet: '30px' },
            marginTop: { mobile: '68px', tablet: '79px' },
            padding: { laptop: '0px 124px 0px 38px' },
          }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: {
                mobile: '24px, 20px, 20px, 20px',
                laptop: '24px, 144px, 20px, 8px',
              },
            }}
          >
            <Outlet />
          </Box>
        </Container>
        {isSmallScreen && (
          <ContainerFooter>
            <Footer />
          </ContainerFooter>
        )}
      </Box>
    </>
  );
};

export default LessonsPage;
