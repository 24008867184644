import { Card, Stack, Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { forgetPasswordThunk } from '../../store/auth/thunks';
import useLocales from 'hooks/useLocales';
import Logo from 'components/Header/Logo/Logo';
import css from '../Login/LoginPage.module.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ForgetPassword from 'components/ForgetPassword/ForgetPassword';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { toast } from 'react-toastify';

export const Container = styled(Card)`
  box-shadow: 0px 0px 48px gray;
  margin: 0 auto;
  width: 600px;
  border-radius: 12px;
  padding: 24px 0 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  a.homeLink {
    padding-left: 24px;
    color: blue;
  }
`;

const RestorePassword = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  // const error = useSelector(state => state.auth.error);

  const notifyError = () =>
    toast.error(translate('login.error-user-not-found'), {});

  const forgetPasswordSend = useSelector(
    state => state.auth.forgetPasswordSend
  );

  const handleSubmit = body => {
    dispatch(forgetPasswordThunk(body))
      .unwrap()
      .catch(error => {
        notifyError();
      });
  };

  return (
    <>
      <div className={css.loginHeader}>
        <Logo />
      </div>

      <Container
        sx={{
          width: {
            mobile: 290,
            iphone: 340,
            tablet: 460,
            laptop: 500,
            desktop: 600,
          },
          m: '0 auto',
          padding: { mobile: ' 20px 0 20px', tablet: '32px 20px 32px' },
          boxShadow: {
            mobile: '0px 0px 12px gray',
            tablet: '0px 0px 20px gray',
          },
        }}
      >
        {/* <GoBackBtn /> */}

        <Link to="/login" className={css.restorePswReturn}>
          <KeyboardBackspaceIcon />
          {translate('login.return-btn')}
        </Link>

        <Stack
        // sx={{ margin: { mobile: '20px 0px', laptop: '48px 0px' } }}
        >
          {!forgetPasswordSend && (
            <Typography
              sx={{
                m: '0 auto',
                fontSize: { mobile: '26px', tablet: '28px', laptop: '30px' },
              }}
            >
              {translate('login.forgot')}
            </Typography>
          )}
        </Stack>
        {!forgetPasswordSend && <ForgetPassword handleSubmit={handleSubmit} />}
        {forgetPasswordSend && (
          <div className={css.messageEmailSent}>
            <p>{translate('login.message-email-sent')}</p>
          </div>
        )}
        {/* {error && <p>{error}</p>} */}
      </Container>
    </>
  );
};

export default RestorePassword;
