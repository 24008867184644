import commonStyles from '../../LessonsPage.module.css';

export default function Video() {
  // const iframeRef = useRef(null);

  // useEffect(() => {
  //   const iframe = iframeRef.current;
  //   let player;

  //   const tag = document.createElement('script');
  //   tag.src = 'https://www.youtube.com/iframe_api';
  //   const firstScriptTag = document.getElementsByTagName('script')[0];
  //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //   window.onYouTubeIframeAPIReady = () => {
  //     player = new window.YT.Player(iframe, {
  //       events: {
  //         onReady: handlePlayerReady,
  //         onStateChange: handlePlayerStateChange,
  //       },
  //     });
  //   };

  //   const handlePlayerReady = () => {
  //     const savedTime = localStorage.getItem('videoTime');
  //     if (savedTime) {
  //       const time = parseFloat(savedTime);
  //       player.seekTo(time);
  //       player.playVideo();
  //     }
  //   };

  //   const handlePlayerStateChange = event => {
  //     if (event.data === window.YT.PlayerState.PLAYING) {
  //       const currentTime = player.getCurrentTime();
  //       localStorage.setItem('videoTime', currentTime.toString());
  //     }
  //   };

  //   return () => {
  //     window.onYouTubeIframeAPIReady = null;
  //   };
  // }, []);

  return (
    <div>
      {/* <div style={{ padding: "flex-start", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/866026077?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
     
          style={{
        
            width: "738px",
            height: "415px",
          }}
          title="Introduction"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script> */}

      <div>
        <iframe
          src="https://player.vimeo.com/video/866043008?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          className={commonStyles.lessonVideo}
          title="В тебе все вийде"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}
