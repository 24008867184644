import React from 'react';
import style from './TryCounter.module.css';

// const TryCounter = ({ tryCounter }) => {
//   return (
//     <div className={css.circleContainer}>
//       {[...tryCounter].reverse().map((item) => (
//         <span
//           key={item.id}
//           className={`${css.circle} ${
//             item.tryStatus !== null &&
//             (item.tryStatus ? css.correctly : css.incorrectly)
//           }`}
//         ></span>
//       ))}
//     </div>
//   );
// };

// export default TryCounter;

const TryCounter = ({ tryCounter }) => {
  return (
    <div className={style.circleContainer}>
      {[...tryCounter].reverse().map(item => (
        <span
          key={item.id}
          className={`${style.circle} ${
            item.tryStatus !== null &&
            (item.tryStatus ? style.correctly : style.incorrectly)
          }`}
        ></span>
      ))}
    </div>
  );
};

export default TryCounter;
