export function wrapUrlsInAnchorTags(html) {
  const oembedRegex =
    /<oembed url="(https:\/\/www\.youtube\.com\/watch\?v=([\w-]+))"><\/oembed>/g;

  return html.replace(
    oembedRegex,

    '<iframe width="560" height="315" src="https://www.youtube.com/embed/$2" frameBorder="0" allowfullscreen></iframe>'
  );
}
