import React, { useState } from 'react';
// import { Grid } from '@mui/material';
import css from './WriteWordToText.module.css';

import CheckCardWriteWord from './CheckCardWriteWord';

const WriteLetterToWord = ({ words1, words2, correctWords, number }) => {
  const [guesses, setGuesses] = useState(Array(words1.length).fill(''));
  const [borderColors, setBorderColors] = useState(
    Array(words1.length).fill('')
  );
  const [isCorrect, setIsCorrect] = useState(Array(words1.length).fill(false));

  const handleInputChange = (index, event) => {
    if (!isCorrect[index]) {
      const newGuesses = [...guesses];
      newGuesses[index] = event.target.value.trim();
      setGuesses(newGuesses);
    }
  };

  const showCorrectWord = (index, value) => {
    const newGuesses = [...guesses];
    newGuesses[index] = value;
    setGuesses(newGuesses);
  };

  return (
    <div className={css.textWrap}>
      {words1.map((word, index) => (
        <div key={index}>
          <CheckCardWriteWord
            number={number}
            index={index}
            isCorrect={isCorrect}
            word={word}
            words2={words2}
            borderColors={borderColors}
            guesses={guesses}
            handleInputChange={handleInputChange}
            setBorderColors={setBorderColors}
            setIsCorrect={setIsCorrect}
            correctWords={correctWords}
            showCorrectWord={showCorrectWord}
          />
        </div>
      ))}
    </div>
  );
};

export default WriteLetterToWord;
