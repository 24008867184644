import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import coffee from '../../../../assets/images/lesson4/coffee.png';
import jabali from '../../../../assets/images/lesson4/jabali.png';
import chica from '../../../../assets/images/lesson4/chica.png';
import mesa from '../../../../assets/images/lesson4/mesa.png';
import moto from '../../../../assets/images/lesson4/moto.png';
import radio from '../../../../assets/images/lesson4/radio.png';
import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
// import AddWordsTo3Containers from 'components/Exercises/AddWordsTo3Containers/AddWordsTo3Containers';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import AddWordsToContainerLibrary from 'components/Exercises/AddWordsToContainerLibrary/AddWordsToContainerLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';

// WriteWordToImage
const images = [coffee, jabali, chica, mesa, moto, radio];

const correctWords = [
  'el café',
  'el jabalí',
  'la chica',
  'la mesa',
  'la moto',
  'la radio',
];

// SelectWord
const correctWord1 = 'el';
const correctWord2 = 'el';
const correctWord3 = 'la';
const correctWord4 = 'el';
const correctWord5 = 'el';
const correctWord6 = 'el';

const options1 = ['el', 'la'];
const options2 = ['el', 'la'];
const options3 = ['el', 'la'];
const options4 = ['el', 'la'];
const options5 = ['el', 'la'];
const options6 = ['el', 'la'];

const wordsSelectWord = [
  'amor',
  'árbol',
  'facultad',
  'café',
  'sistema',
  'tema',
];

// CreateWords
const defaultWords = [
  'гроші',
  'драма',
  'проблема',
  'місто',
  'станція',
  'мотоцикл',
];

const correct2WordsToTranslation = [
  'el sistema',
  'el jabalí',
  'el amor',
  'el café',
  'el árbol',
];

const correctWordsToTranslation3 = [
  'los poetas',
  'los masajes',
  'los peces',
  'las fotos',
  'los optimistas',
];

// AddWordsToContainer
const correctWordsInContainer1 = ['caro', 'famoso', 'malo'];
const correctWordsInContainer3 = ['triste', 'azul', 'marrón', 'optimista'];
const correctWordsInContainer2 = ['bonita', 'hermosa'];

const correctWords2InContainer1 = [
  'problema',
  'trabajador',
  'sistema',
  'tema',
  'amor',
  'árbol',
  'café',
  'día',
  'dinero',
  'drama',
];
const correctWords2InContainer2 = [
  'chica',
  'ciudad',
  'estación',
  'facultad',
  'flor',
  'foto',
];

// MoveWordToContainer
const correctWordInContainer1 = ['el trabajador'];
const correctWordInContainer2 = ['el pez'];
const correctWordInContainer3 = ['el comilón'];
const correctWordInContainer4 = ['marrón'];

const correctWord2InContainer1 = ['bonito'];
const correctWord2InContainer2 = ['azul'];
const correctWord2InContainer3 = ['famoso'];
const correctWord2InContainer4 = ['caro'];

const Exercises1Lesson4 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: '¡Hola! ¿Qué tal?',
      options: [
        '¡Buenos días! Muy bien, gracias, y ¿tú?',
        '¡Cuídate!',
        'Muchas gracias',
      ],
      correctAnswer: '¡Buenos días! Muy bien, gracias, y ¿tú?',
    },
    {
      question: '¿Cómo te llamas?',
      options: [
        'Nos llamamos Pedro y María',
        'Se llama Pedro',
        'Me llamo Pedro',
      ],
      correctAnswer: 'Me llamo Pedro',
    },
    {
      question: 'Me llamo Lucia.',
      options: ['Mucho gusto, Lucía', 'Así así', '¡Buenas!'],
      correctAnswer: 'Mucho gusto, Lucía',
    },
    {
      question: '¿Cómo te va?',
      options: ['Genial', '¡Nos vemos!', 'Igualmente'],
      correctAnswer: 'Genial',
    },
    {
      question: '¡Hasta la próxima!',
      options: ['Encantado', 'Bien', '¡Hasta pronto!'],
      correctAnswer: '¡Hasta pronto!',
    },
    {
      question: 'Encantada de conocerte',
      options: ['Tirando', 'Me llamo María', 'Igualmente'],
      correctAnswer: 'Igualmente',
    },
  ];

  // WriteTranslation

  const wordsWriteTranslation = [
    translate('lesson4.exercises.writeTranslation.word1'),
    translate('lesson4.exercises.writeTranslation.word2'),
    translate('lesson4.exercises.writeTranslation.word3'),
    translate('lesson4.exercises.writeTranslation.word4'),
    translate('lesson4.exercises.writeTranslation.word5'),
  ];

  const wordsWriteTranslation3 = [
    'el poeta',
    'el masaje',
    'el pez',
    'la foto',
    'el optimista',
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'Género masculino';
  const textInRightContainer = 'Género femenino';
  const textInCenterContainer = 'Masculino y femenino';

  const textInLeftContainer2 = 'masculino';
  const textInRightContainer2 = 'femenino';

  // MoveWordToContainer
  const moveWords = [
    translate('lesson4.exercises.task2.word1'),
    translate('lesson4.exercises.task2.word2'),
    translate('lesson4.exercises.task2.word3'),
    translate('lesson4.exercises.task2.word4'),
  ];

  const moveWords2 = [
    translate('lesson4.exercises.task3.word1'),
    translate('lesson4.exercises.task3.word2'),
    translate('lesson4.exercises.task3.word3'),
    translate('lesson4.exercises.task3.word4'),
  ];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correct2WordsToTranslation}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        textInLeftContainer={textInLeftContainer2}
        textInRightContainer={textInRightContainer2}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('lesson2.exercises.task3')} Escriba en plural.
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation3}
        correctWordsToTranslation={correctWordsToTranslation3}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('lesson2.exercises.task8')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        moveWords={moveWords}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task1')} Elija la opción correcta.
      </h2>

      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainer1}
        correctWordsInContainer2={correctWordsInContainer2}
        correctWordsInContainer3={correctWordsInContainer3}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={9}
      />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('lesson2.exercises.task8')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        moveWords={moveWords2}
        number={10}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson4;
