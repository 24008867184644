import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wordsSelector } from '../../../store/dictionary/selectors';
import useLocales from '../../../hooks/useLocales';
import { getAllWordsThunk } from '../../../store/dictionary/thunk';
import QuestionCards from './QuestionCards';

const shuffleArray = array => {
  const shuffledArray = [...array]; // Создаем копию массива, чтобы не менять оригинал
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Перемешиваем элементы
  }
  return shuffledArray;
};

const QuestionCardsTranslate = ({
  wordsToTranslate,
  translateQuestions,
  number,
}) => {
  const [lessonWords, setLessonWords] = useState('');
  const dispatch = useDispatch();
  const { words } = useSelector(wordsSelector);
  const { currentLang } = useLocales();

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  useEffect(() => {
    words.length &&
      setLessonWords(
        words.filter(word => wordsToTranslate.includes(word.word))
      );
  }, [currentLang, words, wordsToTranslate]);

  const shuffledOptionsRef = useRef([]);

  const questions =
    lessonWords &&
    translateQuestions.map((question, i) => {
      const wordIndex = wordsToTranslate.indexOf(lessonWords[i].word);
      const correctAnswer =
        lessonWords[wordIndex][`translation_${currentLang.value}`];

      const options = [...question.options, correctAnswer];

      // Check if options have been shuffled for this question before
      const shuffledOptions = shuffledOptionsRef.current[i]
        ? shuffledOptionsRef.current[i]
        : shuffleArray(options);

      // Save the shuffled options to the ref
      shuffledOptionsRef.current[i] = shuffledOptions;

      return {
        ...question,
        correctAnswer,
        options: shuffledOptions,
      };
    });

  return lessonWords && <QuestionCards questions={questions} number={number} />;
};

export default QuestionCardsTranslate;
