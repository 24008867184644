import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { setTryStatus } from '../helpers/setTryStatus';
import css from './WriteWordToText.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';
import CheckAnswerWithoutBtn from 'components/Buttons/CheckAnswerWithoutBtn/CheckAnswerBtn';

const CheckCardWriteWord = ({
  index,
  isCorrect,
  word,
  borderColors,
  guesses,
  handleInputChange,
  correctWords,
  setIsCorrect,
  setBorderColors,
  showCorrectWord,
  number,
  words2,
}) => {
  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);

  const [id, setId] = useState(1);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const isAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[word];

    if (isAnswer) {
      setTryCounter(Object.values(isAnswer)[0].tryCounterData);
    }
  }, [lesson, module, number, user.exercises, word]);

  const handleBlur = index => {
    const guess = guesses[index].toLowerCase();
    const correctWord = correctWords[index];

    if (guess === correctWord) {
      setIsCorrect(prevIsCorrect => {
        const newIsCorrect = [...prevIsCorrect];
        newIsCorrect[index] = true;
        return newIsCorrect;
      });
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'green';
      setBorderColors(newBorderColors);
      setTryCounter(setTryStatus(id, true));

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'red';
      setBorderColors(newBorderColors);
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);

      // Если достигнуто 3 неправильных попытки, отобразите правильное слово
      // if (currentId === 3) {
      //   const newGuesses = [...guesses];
      //   newGuesses[index] = correctWord;
      //   handleInputChange(index, newGuesses[index]);
      // }

      // Увеличиваем currentId
      // setCurrentId(currentId + 1);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  // const onCheckBtnClick = index => {
  //   const guess = guesses[index].toLowerCase();
  //   const correctWord = correctWords[index];

  //   if (guess === correctWord) {
  //     setIsCorrect(prevIsCorrect => {
  //       const newIsCorrect = [...prevIsCorrect];
  //       newIsCorrect[index] = true;
  //       return newIsCorrect;
  //     });
  //     const newBorderColors = [...borderColors];
  //     newBorderColors[index] = 'green';
  //     setBorderColors(newBorderColors);
  //     setTryCounter(setTryStatus(id, true));

  //     dispatch(
  //       updateProfileThunk({
  //         email: user.email,
  //         exercises: exerciseData(
  //           tryCounter.map(item => {
  //             if (item.id === id) {
  //               return {
  //                 ...item,
  //                 tryStatus: true,
  //               };
  //             }
  //             return item;
  //           })
  //         ),
  //       })
  //     );
  //   } else {
  //     const newBorderColors = [...borderColors];
  //     newBorderColors[index] = 'red';
  //     setBorderColors(newBorderColors);
  //     setTryCounter(setTryStatus(id, false));
  //     setId(prevState => prevState + 1);

  //     dispatch(
  //       updateProfileThunk({
  //         email: user.email,
  //         exercises: exerciseData(
  //           tryCounter.map(item => {
  //             if (item.id === id) {
  //               return {
  //                 ...item,
  //                 tryStatus: false,
  //               };
  //             }
  //             return item;
  //           })
  //         ),
  //       })
  //     );
  //   }
  // };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [word]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[word] || {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[word]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises, word]
  );

  useEffect(() => {
    const showAnswer = () => {
      return tryCounter.every(
        item => !item.tryStatus && item.tryStatus !== null
      );
    };
    const checkAnswer = showAnswer();

    if (checkAnswer && !isCorrect[index]) {
      showCorrectWord(index, correctWords[index]);
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'green';
      setBorderColors(newBorderColors);
      setIsCorrect(prevIsCorrect => {
        const newIsCorrect = [...prevIsCorrect];
        newIsCorrect[index] = true;
        return newIsCorrect;
      });
    }
  }, [
    showCorrectWord,
    index,
    correctWords,
    borderColors,
    setBorderColors,
    setIsCorrect,
    isCorrect,
    tryCounter,
  ]);

  return (
    <div className={css.text}>
      <div className={css.wordWrap}>
        <p className={css.words}>{word}</p>
        <input
          type="text"
          className={`${css.input} ${
            isCorrect[index] ? css.correctAnswer : css.incorrectAnswer
          }`}
          value={
            tryCounter.some(item => item.tryStatus === true) ||
            tryCounter.every(item => item.tryStatus === false)
              ? correctWords[index]
              : guesses[index]
          }
          onChange={event => handleInputChange(index, event)}
          onBlur={() => handleBlur(index)}
          style={{
            borderColor:
              tryCounter.some(item => item.tryStatus === true) ||
              tryCounter.every(item => item.tryStatus === false)
                ? 'green'
                : borderColors[index],
          }}
          readOnly={isCorrect[index]}
        />
      </div>
      <CheckAnswerWithoutBtn tryCounter={tryCounter} />

      {/* Отображаем только одно слово из words2 */}
      {words2[index] && <p className={css.words}>{words2[index]}</p>}
    </div>
  );
};

export default CheckCardWriteWord;
