import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson6 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. ¿De dónde eres?',
      options: ['Somos de Colombia.', 'Soy de Ucrania.', 'Estoy en España.'],
      correctAnswer: 'Soy de Ucrania.',
    },
    {
      question: '2. ¿En qué ciudad vives?',
      options: ['Me gusta Madrid.', 'Estamos en Málaga.', 'Vivo en Barcelona.'],
      correctAnswer: 'Vivo en Barcelona.',
    },
    {
      question: '3. ¿A quién quieres regalar estas flores?',
      options: ['A mi madre.', 'Son de mi madre.', 'Con mi madre.'],
      correctAnswer: 'A mi madre.',
    },
    {
      question: '4. En la habitación ... una mesa, una silla y una ventana.',
      options: ['están', 'hay', 'está'],
      correctAnswer: 'hay',
    },
    {
      question: '5. ¿Dónde ... el supermercado por aquí?',
      options: ['hay', 'es', 'está'],
      correctAnswer: 'está',
    },
    {
      question: '6. Hablas muy rápido. No ... nada.',
      options: ['cierro', 'empiezo', 'entiendo'],
      correctAnswer: 'entiendo',
    },
    {
      question: '7. ¿Qué ... un té o un café?',
      options: ['te sientas', 'piensas', 'prefieres'],
      correctAnswer: 'prefieres',
    },
    {
      question: '8. Las clases ... por la mañana.',
      options: ['empiezan', 'piensan', 'entienden'],
      correctAnswer: 'empiezan',
    },
    {
      question: '9. Tú ... la ventana.',
      options: ['sientes', 'comienzas', 'cierras'],
      correctAnswer: 'cierras',
    },
    {
      question: translate('lesson6.test.question10'),
      options: [
        'No prefiero español.',
        'No entiendo español.',
        'No hablo español.',
      ],
      correctAnswer: 'No entiendo español.',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson6;
