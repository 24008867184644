import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LinearProgress, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import useLocales from '../../hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import img from '../../assets/images/level-A1-small.png';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'customized/customized';

const CardContentStyled = styled(CardContent)({
  '&:last-child': {
    paddingBottom: '0px',
  },
});

const HomeCard = () => {
  const { translate } = useLocales();
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();

  const isMobileScreen = useMediaQuery('(max-width: 389px)');
  const isIphoneScreen = useMediaQuery(
    '(min-width: 390px) and (max-width: 767px)'
  );
  const isTabletDesktopScreen = useMediaQuery('(min-width: 768px)');
  // const isDesktopScreen = useMediaQuery('(min-width: 1280px)');

  const handleClick = () => {
    navigate(`lessons/lesson${user.lessonPage}`);
  };

  const completedLessons = Number.parseInt(user.lessonPage);
  const totalLessons = 12;
  const progressPercentage = (completedLessons / totalLessons) * 100;

  return (
    <Card
      sx={{
        width: { mobile: 300, iphone: 360, tablet: 680, laptop: 800 },
        margin: '0 auto',
        mt: { mobile: '40px', laptop: '60px' },
      }}
    >
      <Stack
        sx={{
          flexDirection: { mobile: 'column', tablet: 'row' },
          padding: { mobile: '13px 12px', tablet: '13px 20px' },
          gap: { mobile: '20px', iphone: '28px', tablet: '20px' },
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          {/* <Typography
            sx={{
              fontSize: { mobile: "20px", tablet: "20px" },
              padding: { mobile: "10px 0px", tablet: "10px 0px" },
            }}
          >
            {translate("home.title")}
          </Typography> */}

          <CardContentStyled
            sx={{
              padding: { mobile: '0px 0px', tablet: '0px 0px' },
              // paddingBottom: { mobile: '0px' },
            }}
          >
            <Stack
              direction="row"
              // spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                sx={{
                  gap: { mobile: '13px', iphone: '20px', tablet: '28px' },
                  justifyContent: { tablet: 'center' },
                  alignItems: { tablet: 'center' },
                }}
              >
                {isMobileScreen && (
                  <img
                    src={img}
                    // src="assets/images/logo-home-card.png"
                    alt="123"
                    width="80px"
                    height="80px"
                  />
                )}
                {isIphoneScreen && (
                  <img
                    src={img}
                    // src="assets/images/logo-home-card.png"
                    alt="123"
                    width="96px"
                    height="96px"
                  />
                )}
                {isTabletDesktopScreen && (
                  <img
                    src={img}
                    // src="assets/images/logo-home-card.png"
                    alt="123"
                    width="140px"
                    height="140px"
                  />
                )}
                <Stack>
                  {/* {isDesktopScreen && (
                    <Chip
                      label="A1"
                      sx={{
                        width: '80px',
                        color: '#364f6a',
                      }}
                    />
                  )} */}

                  <Typography
                    sx={{
                      fontSize: {
                        mobile: '20px',
                        iphone: '24px',
                        tablet: '28px',
                      },
                      marginTop: {
                        mobile: '25px',
                        iphone: '29px',
                        tablet: '52px',
                      },
                      marginBottom: {
                        mobile: '1px',
                        iphone: '3px',
                        tablet: '20px',
                      },
                    }}
                  >
                    {translate('home.level')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        mobile: '10px',
                        iphone: '12px',
                        tablet: '14px',
                      },
                    }}
                  >
                    {completedLessons} {translate('home.from')} 12{' '}
                    {translate('home.progress')}
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <LinearProgress
                      value={progressPercentage}
                      variant="determinate"
                      color="success"
                      sx={{
                        width: {
                          mobile: '100%',
                          tablet: '232px',
                          laptop: '255px',
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: '10px',
                          iphone: '12px',
                          tablet: '14px',
                        },
                      }}
                      color="text.secondary"
                    >
                      {`${Math.round(progressPercentage)}%`}
                    </Typography>
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </CardContentStyled>
        </Stack>
        <Stack sx={{ marginTop: { mobile: '0px' } }}>
          <CustomButton
            variant="contained"
            sx={{
              height: { mobile: '30px', iphone: '34px', tablet: '34px' },
              fontSize: { mobile: '10px', iphone: '12px', tablet: '12px' },
            }}
            endIcon={<SendIcon />}
            onClick={handleClick}
          >
            {translate('home.button')}
          </CustomButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default HomeCard;
