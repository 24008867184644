import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import css from "../Buttons.module.css";

const GoBackBtn = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button onClick={() => navigate("/blog")} className={css.goBackBtn}>
        <KeyboardBackspaceIcon />
        <p className={css.goBackBtnText}>Назад</p>
      </button>
    </div>
  );
};

export default GoBackBtn;
