import React from 'react';
import style from './boxWithLetter.module.css';

export const BoxWithLetter = ({
  index,
  item,
  onSelectedLetterClick,
  isSelected,
}) => {
  return (
    <div
      index={index}
      key={item.id}
      onClick={() => onSelectedLetterClick(item)}
      className={`${style.box} ${isSelected ? style.selected : ''} ${
        !item.clickable ? style.box : ''
      }`}
      // style={{ order: item.order }}
    >
      {item.letterForAnswer}
    </div>
  );
};
