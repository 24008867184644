import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import css from './BlogEditPage.module.css';

const BlogEditor = ({ data, setData }) => {
  return (
    <CKEditor
      className={css.scroll}
      editor={ClassicEditor}
      data={data}
      onChange={(event, editor) => {
        const data = editor.getData();
        setData(data);
      }}
      config={{
        toolbar: {
          items: [
            'undo',
            'redo',
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            'bulletedList',
            'numberedList',

            '|',
            'indent',
            'outdent',
            '|',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'uploadImage',
            '|',
          ],
        },
        wordWrap: {
          shouldWrap: true, // Включите перенос слов
        },
      }}
    />
  );
};

export default BlogEditor;
