import { useState } from 'react';
import useLocales from '../../hooks/useLocales';
import {
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SelectLanguage = ({ title = null, setLang }) => {
  const { onChangeLang, currentLang, allLangs } = useLocales();
  const [open, setOpen] = useState(null);

  console.log('currentLang', currentLang);

  const handleOpen = ({ currentTarget }) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChange = async value => {
    onChangeLang(value);
    setLang(value);
    handleClose();
  };

  const getIcon = src => (
    <img
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${src}.png`}
      srcSet={`https://flagcdn.com/w40/${src}.png 2x`}
      alt=""
    />
  );

  const ICONS = {
    ua: getIcon('ua'),
    gb: getIcon('gb'),
    pl: getIcon('pl'),
    ru: getIcon('ru'),
  };

  return (
    <>
      <IconButton
        dataTestId="button-change-language"
        onClick={handleOpen}
        sx={{
          width: { mobile: '250px', laptop: '220px' },
          height: { mobile: '40px', laptop: '56px' },

          p: 0,
          borderRadius: '4px',
          backgroundColor: 'inherit',
          border: '1px solid rgba(0, 0, 0, 0.26)',
          padding: '9px 14px',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: 'inherit',
            border: '1px solid rgba(0, 0, 0, 0.87)',
          },

          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
            },
          }),
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          spacing={1}
          color="black"
          width="100%"
        >
          {ICONS[currentLang.icon]}
          <Typography>{currentLang.label}</Typography>
          <ArrowDropDownIcon sx={{ fill: 'rgba(0, 0, 0, 0.6)' }} />
        </Stack>
      </IconButton>

      <MenuPopover
        dir="ltr"
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0,

          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.5,
          },
        }}
      >
        {allLangs.map(({ label, value, code }) => (
          <MenuItem
            key={value}
            onClick={() => handleChange(value)}
            // disabled={['pl', 'gb', 'en', 'ru'].includes(value)}
          >
            <Stack alignItems="center" direction="row" spacing={1}>
              <Stack>{ICONS[code]}</Stack>

              <Typography>{label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
};

export default SelectLanguage;

const ArrowStyle = styled('span')(({ arrow, theme }) => {
  const SIZE = 13;
  const POSITION = -(SIZE / 2);
  const borderStyle = `solid 1px ${theme.palette.grey[500_12]}`;
  const topStyle = {
    borderRadius: '0 0 3px 0',
    top: POSITION,
    borderBottom: borderStyle,
    borderRight: borderStyle,
  };

  return {
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      zIndex: 1,
      width: SIZE,
      height: SIZE,
      content: "''",
      display: 'block',
      position: 'absolute',
      transform: 'rotate(-135deg)',
      background: theme.palette.background.paper,
    },

    ...(arrow === 'top-right' && { ...topStyle, right: 20 }),
  };
});

function MenuPopover({
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: 'inherit',
          ...sx,
        },
      }}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} />}
      {children}
    </Popover>
  );
}
