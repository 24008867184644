import React, { useState } from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import WriteTranslationWithoutTranslation from 'components/Exercises/WriteTranslationWithoutTranslation/WriteTranslationWithoutTranslation';
import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';
import WriteLetterToWord from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import WriteWordToImage from 'components/Exercises/WriteWordToImage/WriteWordToImage';
import thank from '../../../../assets/images/lesson10/to-thank.png';
import born from '../../../../assets/images/lesson10/to-born.png';
import drive from '../../../../assets/images/lesson10/to-drive.png';
import WriteTranslation from 'components/Exercises/WriteTranslation/WriteTranslation';

// WriteWordToImage
const images = [thank, born, drive];
const correctWords = ['agradecer', 'nacer', 'conducir'];

// Write translation
const correctWordsToTranslation2 = [
  'agradecer',
  'conducir',
  'conocer',
  'introducir',
  'nacer',
  'parecer',
  'traducir',
];

// CreateWords
const defaultWords = [
  'ніколи',
  'зовсім ніколи',
  'хтось',
  'ніхто',
  'жодний',
  'хтось з',
];

// WriteWordsToText
const correctWordsToText = [
  'ninguna',
  'ninguno',
  'mi',
  'alguien',
  'nada',
  'algún',
  'alguna',
  'alguna',
  'como',
];

const wordsToText1 = [
  'En la escuela,',
  'No conozco a',
  'En mi mochila, no tengo',
  'Jamás pruebo un sándwich tan delicioso',
  'En el recreo, juego con',
  'Siempre que voy al parque, encuentro',
  'Nunca olvido',
  'Mi hermana pequeña nunca come',
  'Es divertido encontrar',
];

const wordsToText2 = [
  'me presta algo especial.',
  'de los nuevos estudiantes.',
  'de comida.',
  'el de mi madre.',
  'de mis amigas.',
  'regalos cuando visitan a alguien.',
  'juguete interesante.',
  'tarea en casa.',
  'fruta que no le guste.',
  'sorpresa en mi habitación.',
];

const correctWordsToText2 = [
  'y',
  'ir',
  'e',
  'e',
  'imos',
  'e',
  'a',
  'amos',
  'imos',
  'o',
];

const words2ToText1 = [
  'Hoy es un día emocionante porque vo',
  'Mi amigo me está enseñando cómo conduc',
  'Estoy emocionado de conocer cómo se sient',
  'Antes de empezar, él me introduc',
  'Mientras conduc',
  'vemos un hermoso paisaje y parec',
  'Durante el viaje, mi amigo me cuent',
  'Después de un rato, par',
  'Traduc',
  'Al final del día, me sient',
];

const words2ToText2 = [
  'a conducir por primera vez.',
  '(infinitivo) su coche.',
  'estar al volante.',
  'a las reglas básicas de la carretera.',
  '(nosotros),',
  'que la naturaleza nos da la bienvenida.',
  'historias interesantes sobre los lugares que visitamos.',
  '(nosotros) en un café.',
  '(nosotros) el menú juntos, ya que está escrito en otro idioma.',
  'feliz por haber aprendido a conducir y por haber conocido nuevos lugares y personas.',
];

// AddWordsToContainer
const correctWordsInContainerYo = [
  'traduzco',
  'conduzco',
  'agradezco',
  'quepo',
];
const correctWordsInContainerElla = ['parece', 'pide', 'sigue', 'trae'];
const correctWordsInContainerTu = ['recuerdas', 'caes', 'duermes'];

const correctWords2InContainerNosotros = [
  'nos reímos',
  'conocemos',
  'competimos',
];
const correctWords2InContainerEllos = [
  'vuelven',
  'introducen',
  'valen',
  'encuentran',
];
const correctWords2InContainerVosotros = [
  'sonreís',
  'nacéis',
  'jugáis',
  'veis,',
];

// MoveWordToContainer

const moveWords = [
  'Yo',
  'Tú',
  'Él, ella, usted',
  'Nosotros, nosotras',
  'Vosotros, vosotras',
  'Ellos, ellas, ustedes',
];

const moveWords2 = [
  'Yo',
  'Tú',
  'Él, ella, usted',
  'Nosotros, nosotras',
  'Vosotros, vosotras',
  'Ellos, ellas, ustedes',
];

const correctWordInContainer1 = ['espero'];
const correctWordInContainer2 = ['esperas'];
const correctWordInContainer3 = ['espera'];
const correctWordInContainer4 = ['esperamos'];
const correctWordInContainer5 = ['esperáis'];
const correctWordInContainer6 = ['esperan'];

const correctWord2InContainer1 = ['traduzco'];
const correctWord2InContainer2 = ['traduces'];
const correctWord2InContainer3 = ['traduce'];
const correctWord2InContainer4 = ['traducimos'];
const correctWord2InContainer5 = ['traducís'];
const correctWord2InContainer6 = ['traducen'];

// MoveWordToContainer
const correctWord3InContainer1 = ['algo'];
const correctWord3InContainer2 = ['alguno'];
const correctWord3InContainer3 = ['alguna'];
const correctWord3InContainer4 = ['algún'];

const moveWords3 = [
  'Me voy de compras, me compro ...',
  '¿ ... de vosotros dos me puede ayudar ?',
  '¿ Tienes monedas ? - Sí, tengo ... ',
  'Siento ... dolor en la cabeza.',
];

// WriteTranslation
const wordsWriteTranslation = [
  'yo',
  'tú',
  'él, ella, usted',
  'nosotros/as',
  'vosotros/as',
  'ellos, ellas, ustedes',
];

const correctWordsToTranslation = [
  'parezco',
  'pareces',
  'parece',
  'parecemos',
  'parecéis',
  'parecen',
];

const correctWordsToTranslation3 = [
  'agradezco',
  'agradeces',
  'agradece',
  'agradecemos',
  'agradecéis',
  'agradecen',
];

const Exercises1Lesson10 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const [words, setWords] = useState(
    correctWordsToText.sort().map(word => ({ text: word, clicked: false }))
  );

  const handleWordClick = index => {
    setWords(currentWords =>
      currentWords.map((word, idx) =>
        idx === index ? { ...word, clicked: !word.clicked } : word
      )
    );
  };

  // QuestionCards
  const defaultQuestions = [
    {
      question: '¿ Quieres tomar ... ?',
      options: ['alguien', 'alguno', 'algo'],
      correctAnswer: 'algo',
    },
    {
      question: 'Le traigo ... bebida.',
      options: ['algún', 'alguna', 'nada'],
      correctAnswer: 'alguna',
    },
    {
      question: 'Nos encantan ... los animales.',
      options: ['cada', 'todos', 'algunos'],
      correctAnswer: 'todos',
    },
    {
      question: '¿ Les doy ... libro ? – Sí, dame ... . ',
      options: ['algún, alguno', 'ningún, ninguno', 'algo, nada'],
      correctAnswer: 'algún, alguno',
    },
    {
      question: '¿ Vienen tus amigas ? – No, no viene ... .',
      options: ['nada', 'alguna', 'ninguna'],
      correctAnswer: 'ninguna',
    },
    {
      question: '... de ellos puede contestar a esta pregunta.',
      options: ['Nadie', 'Alguien', 'Alguno'],
      correctAnswer: 'Alguno',
    },
  ];

  const defaultQuestions2 = [
    {
      question: 'No veo ... interesante aquí.',
      options: ['nada', 'nadie', 'ninguno'],
      correctAnswer: 'nada',
    },
    {
      question: '... de nosotros parece a nuestra abuela materna.',
      options: ['ninguna', 'nadie', 'ninguno'],
      correctAnswer: 'ninguno',
    },
    {
      question: '... tengo sed, siempre tengo hambre.',
      options: ['nada', 'nunca', 'a ninguna parte'],
      correctAnswer: 'nunca',
    },
    {
      question: 'Estoy cansado, hoy no voy a salir a ...',
      options: ['nada', 'ninguna parte', 'jamás'],
      correctAnswer: 'ninguna parte',
    },
    {
      question: '... quiere trabajar, todos quieren descansar.',
      options: ['nada', 'nadie', 'algo'],
      correctAnswer: 'nadie',
    },
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>1.1 Elija la opción correcta.</h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 Componga las palabras de estas letras.
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 Escriba la traducción de las palabras.
      </h2>
      <WriteTranslationWithoutTranslation
        correctWordsToTranslation={correctWordsToTranslation2}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 Arrastre la frase al contenedor correspondiente.
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord3InContainer1}
        correctWordInContainer2={correctWord3InContainer2}
        correctWordInContainer3={correctWord3InContainer3}
        correctWordInContainer4={correctWord3InContainer4}
        moveWords={moveWords3}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainerYo}
        correctWordsInContainer2={correctWordsInContainerTu}
        correctWordsInContainer3={correctWordsInContainerElla}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>1.6 Conjugue "parecer"</h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task11')}
      </h2>

      <div className={commonStyles.availableWordsWrap}>
        {words.map((word, index) => (
          <div
            className={commonStyles.availableWords}
            key={index}
            onClick={() => handleWordClick(index)}
            style={{
              backgroundColor: word.clicked ? '#e0e3e8' : 'transparent',
            }}
          >
            <p>{word.text}</p>
          </div>
        ))}
      </div>

      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords2}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>1.9 Elija la opción correcta.</h2>
      <QuestionCards questions={defaultQuestions2} number={9} />

      <h2 className={commonStyles.lessonTask}>
        1.10 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainerNosotros}
        correctWordsInContainer2={correctWords2InContainerVosotros}
        correctWordsInContainer3={correctWords2InContainerEllos}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task13')}
      </h2>
      <WriteLetterToWord
        correctWords={correctWordsToText2}
        words1={words2ToText1}
        words2={words2ToText2}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 Escriba la palabra que corresponda a la imagen.
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>1.14 Conjugue "agradecer"</h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation3}
        number={14}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson10;
