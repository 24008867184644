import React, { useEffect, useState } from 'react';
// import css from "./WriteTranslation.module.css";
import { Grid } from '@mui/material';
import CheckCardWord from './CheckCardWord';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { wordsSelector } from 'store/dictionary/selectors';
import useLocales from 'hooks/useLocales';
import { getAllWordsThunk } from 'store/dictionary/thunk';

const WriteTranslationWithoutTranslation = ({
  correctWordsToTranslation,
  number,
}) => {
  const [lessonWords, setLessonWords] = useState([]);
  const dispatch = useDispatch();
  const { words } = useSelector(wordsSelector);
  const { currentLang } = useLocales();

  const [guesses, setGuesses] = useState(
    Array(correctWordsToTranslation.length).fill('')
  );
  const [borderColors, setBorderColors] = useState(
    Array(correctWordsToTranslation.length).fill('')
  );
  const [isCorrect, setIsCorrect] = useState(
    Array(correctWordsToTranslation.length).fill(false)
  );

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  useEffect(() => {
    words.length &&
      setLessonWords(
        words.filter(word => correctWordsToTranslation.includes(word.word))
      );
  }, [correctWordsToTranslation, currentLang, words]);

  const handleInputChange = (index, event) => {
    if (!isCorrect[index]) {
      const newGuesses = [...guesses];
      newGuesses[index] = event.target.value;
      setGuesses(newGuesses);
    }
  };

  const showCorrectWord = (index, value) => {
    const newGuesses = [...guesses];
    newGuesses[index] = value;
    setGuesses(newGuesses);
  };

  return (
    <div>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {lessonWords.map((word, index) => (
          <CheckCardWord
            number={number}
            key={index}
            index={index}
            isCorrect={isCorrect}
            word={word?.[`translation_${currentLang.value}`]}
            borderColors={borderColors}
            guesses={guesses}
            handleInputChange={handleInputChange}
            setBorderColors={setBorderColors}
            setIsCorrect={setIsCorrect}
            correctWords={correctWordsToTranslation}
            words={word}
            showCorrectWord={showCorrectWord}
          />
        ))}
      </Grid>
    </div>
  );
};

export default WriteTranslationWithoutTranslation;
