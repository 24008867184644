import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
// import { updatePostThunk } from '../../store/posts/thunk';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { Button, CardMedia, Stack } from '@mui/material';
import { updateFavoritesThunk } from 'store/auth/thunks';
// import { textCropp } from '../../utils/textCropp';
// import { useMemo } from 'react';

export default function BlogCard({ post }) {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const handleExpandClick = id => {
    navigate(id);
  };
  // const text = useMemo(() => textCropp(post.body), [post.body]);
  const handleFavorite = () => {
    dispatch(updateFavoritesThunk(post._id));
  };

  return (
    <Card
      sx={{
        maxWidth: 400,
        width: {
          mobile: '300px',
          iphone: '350px',
          tablet: '300px',
          laptop: '300px',
        },
      }}
    >
      <CardHeader
        title={post.title}
        disableTypography={true}
        sx={{
          padding: { mobile: '14px 16px 0px 16px ' },
          fontSize: { mobile: '18px', tablet: '18px' },
          height: { mobile: '78px' },
          alignItems: 'flex-start',
        }}
      />
      <CardHeader
        subheader={new Date(post.createdAt).toLocaleDateString()}
        disableTypography={true}
        sx={{
          pt: 0,
          pb: '5px',
          fontSize: { mobile: '12px', tablet: '14px' },
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      />
      <CardMedia
        component="img"
        height="198"
        // width="100%"
        image={post.image}
        alt="Post image"
        sx={{ objectFit: 'cover' }}
      />
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
      </CardContent> */}
      <CardActions
        disableSpacing
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: '16px',
        }}
      >
        <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            aria-label="add to favorites"
            onClick={handleFavorite}
            sx={{
              '&:hover': {
                color: '#f8bc02',
              },
            }}
          >
            {user.favoritePosts.includes(post._id) && (
              <FavoriteIcon sx={{ color: '#f8bc02' }} />
            )}
            {!user.favoritePosts.includes(post._id) && (
              <FavoriteIcon sx={{ color: 'inherit' }} />
            )}
          </IconButton>
          {user.role === 'Admin' && (
            <IconButton
              aria-label="add to favorites"
              onClick={() => navigate(`edit/${post._id}`)}
              sx={{
                '&:hover': {
                  color: '#f8bc02',
                },
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>

        <Button
          size="small"
          variant="outlined"
          sx={{
            fontSize: '14px',
            border: '1px solid #90ADC6',
            cursor: 'pointer',
            ':hover': {
              border: '1px solid #f8bc02',
              color: '#364f6a',
              backgroundColor: 'white',
            },
          }}
          onClick={() => handleExpandClick(post._id)}
        >
          Читати пост
        </Button>
      </CardActions>
    </Card>
  );
}
