import React from 'react';
import style from './emptyBox.module.css';

const EmptyBox = ({
  index,
  item,
  onContainerClick,
  selectedLetter,
  dbClickHandler,
}) => {
  return (
    <div
      index={index}
      key={item.id}
      className={style.box}
      onClick={() => onContainerClick(index, selectedLetter)}
      onDoubleClick={e => dbClickHandler(e, item)}
    >
      {item.letterForUser}
    </div>
  );
};

export default EmptyBox;
