import { Button, Card, Stack, Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createNewPasswordThunk } from '../../store/auth/thunks';
import useLocales from 'hooks/useLocales';
import Logo from 'components/Header/Logo/Logo';
import css from '../Login/LoginPage.module.css';
import { useSelector } from 'react-redux';
import CreateNewPassword from 'components/CreateNewPassword/CreateNewPassword';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const Container = styled(Card)`
  box-shadow: 0px 0px 48px gray;
  margin: 0 auto;
  width: 600px;
  border-radius: 12px;
  padding: 24px 0 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  a.homeLink {
    padding-left: 24px;
    color: blue;
  }
`;

const LoginButton = styled(Button)({
  '&:hover': {
    backgroundColor: '#364f6a',
    color: '#f8bc02',
  },
});

const RestorePassword = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const restoreCode = searchParams.get('restoreCode');

  const error = useSelector(state => state.auth.error);

  const restorePasswordSend = useSelector(
    state => state.auth.restorePasswordSend
  );

  const handleSubmit = body => {
    dispatch(createNewPasswordThunk({ ...body, restoreCode }));
  };
  const handleClick = () => {
    navigate('/login');
  };

  return (
    <>
      <div className={css.loginHeader}>
        <Logo />
      </div>

      <Container
        sx={{
          width: {
            mobile: 290,
            iphone: 340,
            tablet: 460,
            laptop: 500,
            desktop: 600,
          },
          m: '0 auto',
          padding: { mobile: ' 20px 0 20px', tablet: '32px 0 32px' },
          boxShadow: {
            mobile: '0px 0px 12px gray',
            tablet: '0px 0px 20px gray',
          },
        }}
      >
        <Stack
        // sx={{ margin: { mobile: '20px 0px', laptop: '48px 0px' } }}
        >
          <Typography
            sx={{
              m: '0 auto',
              fontSize: { mobile: '26px', tablet: '28px', laptop: '30px' },
            }}
          >
            {translate('login.new-password')}
          </Typography>
        </Stack>
        {/* <Link to="/forget-password">Forget pasword?</Link> */}
        {!restorePasswordSend && (
          <CreateNewPassword handleSubmit={handleSubmit} />
        )}

        {restorePasswordSend && (
          <div className={css.messageEmailSent}>
            <p> {translate('login.password-changed')}</p>

            <LoginButton
              type="submit"
              variant="contained"
              size="large"
              sx={{
                minWidth: {
                  mobile: '120px',
                  tablet: '180px',
                  laptop: '220px',
                },
                fontSize: { tablet: '16px' },
              }}
              onClick={handleClick}
            >
              {/* <Link to="/login" className={css.messageEmailSentLink}> */}
              {translate('login.btn')}
              {/* </Link> */}
            </LoginButton>

            {/* <Link to="/login" className={css.messageEmailSentLink}>
              {translate('login.login')}
            </Link> */}
          </div>
        )}
        {error && <p>{error}</p>}
      </Container>
    </>
  );
};

export default RestorePassword;
