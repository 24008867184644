import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import css from './WriteTranslation.module.css';
import { setTryStatus } from '../helpers/setTryStatus';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const CheckCardWord = ({
  number,
  index,
  isCorrect,
  word,
  borderColors,
  guesses,
  handleInputChange,
  correctWords,
  setIsCorrect,
  setBorderColors,
  showCorrectWord,
}) => {
  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);
  const [id, setId] = useState(1);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const isAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[word];

    if (isAnswer) {
      setTryCounter(Object.values(isAnswer)[0].tryCounterData);
    }
  }, [lesson, module, number, user.exercises, word]);

  const onCheckBtnClick = index => {
    const guess = guesses[index].toLowerCase();
    const correctWord = correctWords[index];

    if (guess === correctWord) {
      setIsCorrect(prevIsCorrect => {
        const newIsCorrect = [...prevIsCorrect];
        newIsCorrect[index] = true;
        return newIsCorrect;
      });
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'green';
      setBorderColors(newBorderColors);
      setTryCounter(setTryStatus(id, true));

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'red';
      setBorderColors(newBorderColors);
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [word]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[word] || {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[word]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises, word]
  );

  useEffect(() => {
    const showAnswer = () => {
      return tryCounter.every(
        item => !item.tryStatus && item.tryStatus !== null
      );
    };
    const checkAnswer = showAnswer();

    if (checkAnswer && !isCorrect[index]) {
      showCorrectWord(index, correctWords[index]);
      const newBorderColors = [...borderColors];
      newBorderColors[index] = 'green';
      setBorderColors(newBorderColors);
      setIsCorrect(prevIsCorrect => {
        const newIsCorrect = [...prevIsCorrect];
        newIsCorrect[index] = true;
        return newIsCorrect;
      });
    }
  }, [
    showCorrectWord,
    index,
    correctWords,
    borderColors,
    setBorderColors,
    setIsCorrect,
    isCorrect,
    tryCounter,
  ]);

  return (
    <Box
      key={index}
      sx={{
        display: { mobile: 'flex' },
        justifyContent: { mobile: 'center' },
        alignItems: { mobile: 'center' },
        margin: {
          mobile: '4px auto',
          iphone: '16px auto 0px auto',
          tablet: '0px 0px',
        },
      }}
    >
      <div className={css.exerciseWrap}>
        <div className={css.wordWrap}>
          <p word={word}>{word}</p>
        </div>
        <input
          type="text"
          className={`${css.input} ${
            isCorrect[index] ? css.correctAnswer : css.incorrectAnswer
          }`}
          value={
            tryCounter.some(item => item.tryStatus === true) ||
            tryCounter.every(item => item.tryStatus === false)
              ? correctWords[index]
              : guesses[index]
          }
          onChange={event => handleInputChange(index, event)}
          style={{
            borderColor:
              tryCounter.some(item => item.tryStatus === true) ||
              tryCounter.every(item => item.tryStatus === false)
                ? 'green'
                : borderColors[index],
          }}
          readOnly={isCorrect[index]}
        />
        <CheckAnswerBtn
          tryCounter={tryCounter}
          onCheckBtnClick={() => onCheckBtnClick(index)}
        />
      </div>
    </Box>
  );
};

export default CheckCardWord;

/* <Paper
        elevation={3}
        className={css.paper}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: 12,
          gap: 8,
          borderColor: borderColors[index],
          transition: "border-color 0.3s ease",
        }}
      > */
