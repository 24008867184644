import { instance } from './api';

export const getPosts = async (page, limit) => {
  const { data } = await instance(`posts?page=${page}&limit=${limit}`);
  return data;
};

export const updatePost = async ({ id, body }) => {
  const { data } = await instance.post(`posts/${id}`, body);
  return data;
};

export const getPostDetails = async id => {
  const { data } = await instance(`posts/${id}`);
  return data;
};

export const createPost = async body => {
  const { data } = await instance.post(`posts`, body);
  return data;
};
