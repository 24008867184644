import { Box, CssBaseline, Stack, Tabs, Typography } from '@mui/material';
import React from 'react';
import { LinkTab } from './LinkTab';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useLocales from 'hooks/useLocales';

const DictionaryPage = () => {
  const { user } = useSelector(state => state.auth);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { translate } = useLocales();

  // const { patternMacthed } = useRouteMatch({
  //   patterns: ["/isOnLearning", "/isLearned"],
  //   basePath: "/dictionary",
  //   defaultMatch: "/isOnLearning",
  // });

  return (
    <Box
      sx={{
        padding: {
          mobile: '86px 14px 64px 14px',
          tablet: '106px 44px 64px 44px',
          laptop: '120px 160px 64px 160px',
        },
      }}
    >
      <Stack
        sx={{
          backgroundColor: '#e0e3e8',
          borderRadius: '4px',
          padding: { mobile: '4px 0px', laptop: '4px 0px 6px 0px' },
          gap: { laptop: '10px' },
        }}
      >
        <Typography
          sx={{
            marginLeft: '10px',
            marginBottom: '0px',
            fontSize: { mobile: '24px', laptop: '32px' },
            fontWeight: { mobile: 500 },
            color: '#364f6a',
          }}
        >
          {translate('myVocabulary.title')}
        </Typography>

        <Stack
          sx={{
            display: 'flex',
            flexDirection: { mobile: 'column', laptop: 'row' },
            gap: { mobile: '4px', laptop: '8px' },
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'row' },
              gap: { mobile: '4px', laptop: '8px' },
            }}
          >
            <Typography
              sx={{
                marginLeft: '10px',
                fontSize: { mobile: '15px', tablet: '16px', laptop: '18px' },
                fontWeight: { mobile: 400 },
              }}
            >
              {translate('myVocabulary.wordsOnLearning')}:
            </Typography>

            <Typography
              sx={{
                fontSize: { mobile: '15px', tablet: '16px', laptop: '18px' },
                fontWeight: { mobile: 400 },
              }}
            >
              {user.dictionary.isOnLearning.length}
            </Typography>
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'row' },
              gap: { mobile: '4px', laptop: '8px' },
            }}
          >
            <Typography
              sx={{
                marginLeft: '10px',
                fontSize: { mobile: '15px', tablet: '16px', laptop: '18px' },
                fontWeight: { mobile: 400 },
              }}
            >
              {translate('myVocabulary.wordsLearned')}:
            </Typography>

            <Typography
              sx={{
                fontSize: { mobile: '15px', tablet: '16px', laptop: '18px' },
                fontWeight: { mobile: 400 },
              }}
            >
              {user.dictionary.isLearned.length}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          paddingTop: { mobile: '14px', laptop: '30px' },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab
            label={translate('myVocabulary.onLearning')}
            to="/dictionary"
          />
          <LinkTab label={translate('myVocabulary.learned')} to="./isLearned" />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default DictionaryPage;
