import React from 'react';
import Video from './Video';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import Vocabulary from './Vocabulary';
import { Button, ButtonGroup, Stack } from '@mui/material';
import { getAudio } from 'api/textToSpeech';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { theme } from 'theme/theme';

const wordsToRepeat = ['niño', 'ocho', 'padre', 'quien', 'Roma', 'siete'];

const wordsToRepeat2 = [
  'jardín',
  'humano',
  'vaca',
  'examen',
  'zanahoria',
  'lluvia',
];

const Emphasis = () => {
  const url =
    'https://drive.google.com/file/d/1A-OyQ__jqI_1j3e3oPyoDcUISOAeRfK8/preview';

  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-emphasis.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        {translate('lesson1.video')}
      </h2>
      <Video />

      <h2 className={commonStyles.lessonTask}>
        {translate('lesson1.summary')}
      </h2>
      <div>
        <iframe
          title="pdf"
          src={url}
          frameBorder="*"
          allowFullScreen="allowfullscreen"
          width="738"
          height="415"
          className={commonStyles.summary}
        ></iframe>
      </div>

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('lesson1-emphasis.task1')} Repita las siguientes
        palabras.
      </h2>

      <Stack
        sx={{
          flexDirection: { mobile: 'column', tablet: 'row' },
          alignItems: { tablet: 'center' },
          marginBottom: { mobile: '4px', tablet: '7px' },
        }}
      >
        <ButtonGroup variant="text" aria-label="text button group">
          <Button
            onClick={async () => await getAudio({ text: wordsToRepeat })}
            sx={{
              width: '24px',
              padding: { mobile: '6px 0px 6px 0px' },
              marginBottom: { mobile: '8px', tablet: '0px' },
              justifyContent: { mobile: 'flex-start' },
            }}
            size="small"
          >
            <VolumeUpIcon sx={{ color: theme.palette.primary.blue }} />
          </Button>
        </ButtonGroup>
        <Stack
          direction="row"
          sx={{
            flexWrap: { mobile: 'wrap' },
            rowGap: { mobile: '10px' },
            columnGap: {
              mobile: '14px',
              iphone: '15px',
              tablet: '20px',
              laptop: '30px',
            },
            marginLeft: { tablet: '12px' },
          }}
        >
          {wordsToRepeat.map(word => (
            <Stack direction="row" spacing={1} alignItems="center" key={word}>
              <p className={commonStyles.text}>{word}</p>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack
        sx={{
          flexDirection: { mobile: 'column', tablet: 'row' },
          alignItems: { tablet: 'center' },
        }}
      >
        <ButtonGroup variant="text" aria-label="text button group">
          <Button
            onClick={async () => await getAudio({ text: wordsToRepeat2 })}
            sx={{
              width: '24px',
              padding: { mobile: '6px 0px 6px 0px' },
              marginBottom: { mobile: '8px', tablet: '0px' },
              marginTop: { mobile: '8px', tablet: '0px' },

              justifyContent: { mobile: 'flex-start' },
            }}
            size="small"
          >
            <VolumeUpIcon sx={{ color: theme.palette.primary.blue }} />
          </Button>
        </ButtonGroup>
        <Stack
          direction="row"
          sx={{
            flexWrap: { mobile: 'wrap' },
            rowGap: { mobile: '10px' },
            columnGap: {
              mobile: '14px',
              iphone: '15px',
              tablet: '20px',
              laptop: '30px',
            },
            marginLeft: { tablet: '12px' },
          }}
        >
          {wordsToRepeat2.map(word => (
            <Stack direction="row" spacing={1} alignItems="center" key={word}>
              <p className={commonStyles.text}>{word}</p>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('lesson1.task3')} Vocabulario. Puede añadir las palabras
        a su propio vocabulario.
      </h2>
      <Vocabulary />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Emphasis;
