import React, { useState } from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import WriteTranslationWithoutTranslation from 'components/Exercises/WriteTranslationWithoutTranslation/WriteTranslationWithoutTranslation';
import WriteLetterToWord from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import WriteTranslation from 'components/Exercises/WriteTranslation/WriteTranslation';
// import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';

// Write translation
const correctWordsToTranslation2 = [
  'abrir',
  'cubrir',
  'desayunar',
  'descubrir',
  'freir',
  'resolver',
  'romper',
  'satisfacer',
];

// CreateWords
const defaultWords = [
  'смажити',
  'подорож',
  'вирішення',
  'відкрити для себе',
  'задовольняти',
  'поснідати',
];

// WriteWordsToText
const correctWordsToText = [
  'hecho',
  'frito',
  'abierto',
  'desayunado',
  'cubierto',
  'descubierto',
];

const wordsToText1 = [
  'Esta mañana he',
  'He',
  'y he',
  'Hace poco, he',
  'Esta semana he',
  'Esta tarde, he',
];

const wordsToText2 = [
  'la ventana para dejar entrar el sol.',
  'tostadas con mermelada',
  'la mesa con un mantel bonito.',
  'un nuevo restaurante cerca de mi casa y esta noche voy a ir a cenar allí.',
  'todos mis deberes a tiempo.',
  'unas patatas para acompañar la cena.',
];

const correctWordsToText2 = [
  'durmiendo',
  'pidiendo',
  'prefiriendo',
  'diciendo',
  'siguiendo',
  'oyendo',
  'yendo',
];

const words2ToText1 = [
  'En este momento, María está (dormir)',
  'Pedro está (pedir)',
  'Luis está (preferir)',
  'Ana está (decir)',
  'Los niños están (seguir)',
  'Estoy (oir)',
  'Mi hermana está (ir)',
];

const words2ToText2 = [
  'en su cama. Ella siempre duerme profundamente por la noche.',
  'un café en la cafetería. Le gusta tomar café por la mañana antes de ir al trabajo.',
  'ver películas en lugar de salir esta noche. Le encanta relajarse en casa.',
  'que quiere ir al parque. A ella le gusta caminar al aire libre.',
  'a su madre al supermercado. Quieren ayudarla a hacer las compras.',
  'música en mi habitación. Me gusta escuchar música cuando estoy relajándome.',
  'al colegio en autobús. Ella siempre va en autobús porque le gusta mirar por la ventana.',
];

const correctWordsToText3 = [
  'tiene',
  'viene',
  'dice',
  'pidiendo',
  'está leyendo',
  'va',
  'está',
];

const words3ToText1 = [
  'En la ciudad de Barcelona, ​​María (tener)',
  'Todos los días, Tomás (venir)',
  'María (decir):',
  'Pero él no la escucha y sigue (pedir)',
  'Algunas veces, cuando María (estar leyendo)',
  'Entonces (ir)',
  'María (estar)',
];

const words3ToText2 = [
  'un gato llamado Tomás. Él es muy juguetón y le encanta jugar con sus juguetes.',
  'la cocina cuando María prepara su desayuno. A veces, él quiere un poco de leche.',
  'Tomás, no puedes beber leche, eres un gato.',
  'más.',
  'un libro, oye un ruido extraño.',
  'a la sala y encuentra a Tomás jugando.',
  'feliz de tener a Tomás en su vida. Él es su compañero y amigo fiel.',
];

// AddWordsToContainer
const correctWordsInContainerYo = [
  'he estudiado',
  'he bailado',
  'me he acostado',
];
const correctWordsInContainerElla = ['ha trabajado', 'ha metido'];
const correctWordsInContainerTu = [
  'has cantado',
  'has cansado',
  'te has levantado',
];

const correctWords2InContainerNosotros = [
  'creemos',
  'cocinamos',
  'oímos',
  'estamos',
];
const correctWords2InContainerEllos = ['dicen', 'leen', 'tienen', 'van'];
const correctWords2InContainerVosotros = ['venís', 'habéis', 'estáis', 'sois'];

// MoveWordToContainer

const moveWords = [
  'Yo',
  'Tú',
  'Él, ella, usted',
  'Nosotros, nosotras',
  'Vosotros, vosotras',
  'Ellos, ellas, ustedes',
];

const moveWords2 = [
  'Yo',
  'Tú',
  'Él, ella, usted',
  'Nosotros, nosotras',
  'Vosotros, vosotras',
  'Ellos, ellas, ustedes',
];

const correctWordInContainer1 = ['oigo'];
const correctWordInContainer2 = ['oyes'];
const correctWordInContainer3 = ['oye'];
const correctWordInContainer4 = ['oímos'];
const correctWordInContainer5 = ['oís'];
const correctWordInContainer6 = ['oyen'];

const correctWord2InContainer1 = ['he'];
const correctWord2InContainer2 = ['has'];
const correctWord2InContainer3 = ['ha'];
const correctWord2InContainer4 = ['hemos'];
const correctWord2InContainer5 = ['habéis'];
const correctWord2InContainer6 = ['han'];

// const moveWords3 = [
//   'Yo',
//   'Tú',
//   'Él, ella, usted',
//   'Nosotros, nosotras',
//   'Vosotros, vosotras',
//   'Ellos, ellas, ustedes',
// ];

// const correctWord3InContainer1 = ['Mi amiga estudia matemáticas.'];
// const correctWord3InContainer2 = ['María es estudiante.'];
// const correctWord3InContainer3 = ['Olga toma el libro.'];
// const correctWord3InContainer4 = ['Yo hablo muy bien el español.'];

// WriteTranslation
const wordsWriteTranslation = [
  'yo',
  'tú',
  'él, ella, usted',
  'nosotros/as',
  'vosotros/as',
  'ellos, ellas, ustedes',
];

const correctWordsToTranslation = [
  'digo',
  'dices',
  'dice',
  'decimos',
  'decís',
  'dicen',
];

// const wordsWriteTranslation3 = [
//   'dormir',
//   'morir',
//   'pedir',
//   'preferir',
//   'decir',
//   'seguir',
//   'oir',
//   'ir',
// ];

// const correctWordsToTranslation3 = [
//   'durmiendo',
//   'muriendo',
//   'pidiendo',
//   'prefiriendo',
//   'diciendo',
//   'siguiendo',
//   'oyendo',
//   'yendo',
// ];

const Exercises1Lesson12 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const [words, setWords] = useState(
    correctWordsToText.sort().map(word => ({ text: word, clicked: false }))
  );

  const handleWordClick = index => {
    setWords(currentWords =>
      currentWords.map((word, idx) =>
        idx === index ? { ...word, clicked: !word.clicked } : word
      )
    );
  };

  // QuestionCards
  const defaultQuestions = [
    {
      question: 'Esta semana nosotros no ... muchos problemas.',
      options: ['hemos resuelto', 'hemos desayunado', 'hemos frito'],
      correctAnswer: 'hemos resuelto',
    },
    {
      question: '... los deberes muy rápido.',
      options: ['he satisfecho', 'he roto', 'he hecho'],
      correctAnswer: 'he hecho',
    },
    {
      question:
        'Esta semana todos los días Marta ... un café con leche y una tostada con mermelada.',
      options: ['ha desayunado', 'he desayunado', 'has desayunado'],
      correctAnswer: 'ha desayunado',
    },
    {
      question:
        'Esta tarde José y Pedro ... las hamburguesas con verduras para la cena.',
      options: ['han descubierto', 'han frito', 'hemos frito'],
      correctAnswer: 'han frito',
    },
    {
      question: '¿A qué hora os ...?',
      options: ['han despertado', 'habéis despertado', 'hemos despertado'],
      correctAnswer: 'habéis despertado',
    },
  ];

  const defaultQuestions2 = [
    {
      question:
        '... me gusta comer helado los días calurosos, me encantan los helados.',
      options: ['También', 'Tampoco'],
      correctAnswer: 'También',
    },
    {
      question: '... me gusta el brócoli, siempre lo tiro del plato.',
      options: ['También', 'Tampoco'],
      correctAnswer: 'Tampoco',
    },
  ];

  const defaultQuestions3 = [
    {
      question: 'Nunca me contestas a mis preguntas. ... quiero contestarte.',
      options: ['También', 'Tampoco', 'Nunca'],
      correctAnswer: 'Tampoco',
    },
    {
      question: 'María ... pidiendo mucho.',
      options: ['está', 'estoy', 'estamos'],
      correctAnswer: 'está',
    },
    {
      question: 'Los niños ... al fútbol.',
      options: ['estamos jugando', 'están jugando', 'estáis jugando'],
      correctAnswer: 'están jugando',
    },
    {
      question: '¿Qué estás ..., un té o un café?',
      options: ['durmiendo', 'diciendo', 'prefiriendo'],
      correctAnswer: 'prefiriendo',
    },
    {
      question: 'Si me llama mi mamá, siempre ... al teléfono.',
      options: ['me pongo', 'estoy yendo', 'estoy oyendo'],
      correctAnswer: 'me pongo',
    },
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>1.1 Elija la opción correcta.</h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 Componga las palabras de estas letras.
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords2}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainerYo}
        correctWordsInContainer2={correctWordsInContainerTu}
        correctWordsInContainer3={correctWordsInContainerElla}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task11')}
      </h2>

      <div className={commonStyles.availableWordsWrap}>
        {words.map((word, index) => (
          <div
            className={commonStyles.availableWords}
            key={index}
            onClick={() => handleWordClick(index)}
            style={{
              backgroundColor: word.clicked ? '#e0e3e8' : 'transparent',
            }}
          >
            <p>{word.text}</p>
          </div>
        ))}
      </div>

      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 Escriba la traducción de las palabras.
      </h2>
      <WriteTranslationWithoutTranslation
        correctWordsToTranslation={correctWordsToTranslation2}
        number={6}
      />

      {/* <h2 className={commonStyles.lessonTask}>
        1.7 {translate('lesson2.exercises.task6')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord3InContainer1}
        correctWordInContainer2={correctWord3InContainer2}
        correctWordInContainer3={correctWord3InContainer3}
        correctWordInContainer4={correctWord3InContainer4}
        moveWords={moveWords}
        number={3}
      /> */}

      {/* <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task14')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation3}
        correctWordsToTranslation={correctWordsToTranslation3}
        number={7}
      /> */}

      <h2 className={commonStyles.lessonTask}>
        1.8 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainerNosotros}
        correctWordsInContainer2={correctWords2InContainerVosotros}
        correctWordsInContainer3={correctWords2InContainerEllos}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>1.9 Elija la opción correcta.</h2>
      <QuestionCards questions={defaultQuestions2} number={9} />

      <h2 className={commonStyles.lessonTask}>
        1.10 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task13')}
      </h2>
      <WriteLetterToWord
        correctWords={correctWordsToText2}
        words1={words2ToText1}
        words2={words2ToText2}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>1.12 Conjugue "decir"</h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions3} number={13} />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('exercises.task13')}
      </h2>
      <WriteLetterToWord
        correctWords={correctWordsToText3}
        words1={words3ToText1}
        words2={words3ToText2}
        number={14}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson12;
