import { deleteToken, instance, setToken } from './api';

export const login = async ({ email, password }) => {
  const { data } = await instance.post(`auth/login`, { email, password });
  setToken(data.token);
  localStorage.setItem('token', data.token);
  return data;
};

export const forgetPassword = async ({ email }) => {
  const { data } = await instance.post(`auth/forget-password`, { email });
  return data;
};

export const createPassword = async body => {
  const { data } = await instance.post(`auth/restore-password`, body);
  return data;
};

export const refresh = async token => {
  // instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  setToken(token);

  try {
    const { data } = await instance.get(`auth/refresh`);
    setToken(data.token);
    localStorage.setItem('token', data.token);

    return data;
  } catch (error) {
    localStorage.removeItem('token');
    throw error;
  }
};

// const initializeAuth = () => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     setToken(token);
//     instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//   }
// };

// const refreshTokenIfNeeded = async () => {
//   const token = localStorage.getItem('token');
//   if (token && isTokenExpired(token)) {
//     try {
//       const data = await refresh(token);
//       localStorage.setItem('token', data.token);
//       setToken(data.token);
//       instance.defaults.headers.common[
//         'Authorization'
//       ] = `Bearer ${data.token}`;
//     } catch (error) {
//       console.log('Error refreshing token:', error);
//     }
//   }
// };

// const isTokenExpired = token => {
//   return false;
// };

export const logout = async userId => {
  const { data } = await instance.post(`auth/logout`, {
    user: { _id: userId },
  });
  deleteToken();
  localStorage.removeItem('token');
  return data;
};

export const changePassword = async body => {
  const data = await instance.post(`auth/changePassword`, body);
  return data;
};
