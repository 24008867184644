export const setTryStatus = (id, tryStatus) => {
  return prevState =>
    prevState.map(item => {
      if (item.id === id) {
        return {
          ...item,
          tryStatus: tryStatus,
        };
      }
      return item;
    });
};

// export const setTryStatusCreateWord = (tryCounter, id, tryStatus) => {
//   const updatedTryCounter = Array.isArray(tryCounter) ? tryCounter : [];
//   return updatedTryCounter.map(item =>
//     item.id === id ? { ...item, tryStatus } : item
//   );
// };

export const setTryStatusArray = (id, tryStatus, index) => {
  return prevState =>
    prevState.map((item, i) => {
      if (i === index) {
        return item.map(item2 => {
          if (item2.id === id) {
            return {
              ...item2,
              tryStatus: tryStatus,
            };
          }
          return item2;
        });
      } else return item;
    });
};
