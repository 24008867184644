import styled from '@emotion/styled';
import { Button, InputBase } from '@mui/material';
import { theme } from 'theme/theme';

export const CustomButton = styled(Button)({
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.yellow,
  },
});

export const CustomChangePageButton = styled(Button)({
  '&:hover': {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.yellow,
  },
});

export const CustomLessonsButton = styled(Button)({
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.yellow,
  },
});

export const CustomVolumeButton = styled(Button)({
  '.MuiButtonBase-root': {
    minWidth: '30px',
  },
});

// SelectWordBtn
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  // 'label + &': {
  //   marginTop: theme.spacing(3),
  // },

  '& .MuiInputBase-input': {
    // width: '162px',
    height: '24px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    fontSize: 16,
    padding: '2px 26px 2px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));
