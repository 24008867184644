import React, { useCallback, useEffect, useState } from 'react';
import css from './QuestionCards.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileThunk } from 'store/auth/thunks';
import { useLocation } from 'react-router-dom';

const RadioGroup = ({ question, options, correctAnswer, number }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [answered, setAnswered] = useState(false);
  const [answeredApi, setAnsweredApi] = useState('');

  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = arrPathname[2];
  const lesson = arrPathname[3];

  useEffect(() => {
    setAnsweredApi(user.exercises[module]?.[lesson]?.[number]?.[question]);
  }, [lesson, module, number, question, user]);

  const sendOption = useCallback(
    option => {
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: {
            ...user.exercises,
            [module]: {
              ...(user.exercises[module] || {}),
              [lesson]: {
                ...(user.exercises[module]?.[lesson] || {}),
                [number]: {
                  ...(user.exercises[module]?.[lesson]?.[number] || {}),
                  [question]: option,
                },
              },
            },
          },
        })
      );
    },
    [dispatch, lesson, module, number, question, user.email, user.exercises]
  );

  const handleOptionClick = option => {
    if (!answered && !answeredApi) {
      setSelectedValue(option);
      setAnswered(true);
      sendOption(option);
    }
  };

  const getOptionColor = useCallback(
    option => {
      if (answered || answeredApi) {
        if (option === correctAnswer && selectedValue === correctAnswer) {
          return 'green';
        } else if (option === selectedValue) {
          return 'red';
        } else if (option === correctAnswer) {
          return 'green';
        }
      }
      return 'default';
    },
    [answered, answeredApi, correctAnswer, selectedValue]
  );

  useEffect(() => {
    if (answeredApi) {
      setSelectedValue(answeredApi);
      getOptionColor(answeredApi);
    }
  }, [answeredApi, getOptionColor, lesson, module, number, question, user]);

  return (
    <div className={css.options}>
      <p className={css.question}>{question}</p>
      {options.map(option => (
        <div className={css.optionWrap} key={option}>
          <div
            className={css.option}
            onClick={() => handleOptionClick(option)}
            style={{ backgroundColor: getOptionColor(option) }}
          ></div>
          <p className={css.text}>{option}</p>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
