// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { wordsSelector } from '../../../store/dictionary/selectors';
// import useLocales from '../../../hooks/useLocales';
// import { getAllWordsThunk } from '../../../store/dictionary/thunk';
import QuestionCards from './QuestionCards';

// const shuffleArray = array => {
//   const shuffledArray = [...array]; // Создаем копию массива, чтобы не менять оригинал
//   for (let i = shuffledArray.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Перемешиваем элементы
//   }
//   return shuffledArray;
// };

const QuestionCardsTranslate = ({
  // wordsToTranslate,
  translateQuestions,
  isTestEnded,
  updateStatistics,
}) => {
  // const [lessonWords, setLessonWords] = useState('');

  // const dispatch = useDispatch();
  // const { words } = useSelector(wordsSelector);
  // const { currentLang } = useLocales();

  // useEffect(() => {
  //   dispatch(getAllWordsThunk());
  // }, [dispatch]);

  // useEffect(() => {
  //   words.length &&
  //     setLessonWords(
  //       words.filter(word => wordsToTranslate.includes(word.word))
  //     );
  // }, [currentLang, words, wordsToTranslate]);

  const questions =
    // lessonWords &&
    translateQuestions.map((question, i) => {
      // Найдем индекс текущего слова среди слов для перевода
      // const wordIndex = wordsToTranslate.indexOf(lessonWords[i].word);

      // Получаем правильный ответ для текущего вопроса
      // const correctAnswer =
      //   lessonWords[wordIndex][`translation_${currentLang.value}`];

      // Генерируем варианты ответов, включая правильный ответ, и перемешиваем их
      const options = [...question.options];
      const correctAnswer = question.correctAnswer;

      return {
        ...question,
        correctAnswer,
        options,
      };
    });

  return (
    // lessonWords &&
    // (
    <QuestionCards
      questions={questions}
      isTestEnded={isTestEnded}
      updateStatistics={updateStatistics}
    />
  );
  // );
};

export default QuestionCardsTranslate;
