import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wordsSelector } from '../../../store/dictionary/selectors';
import useLocales from '../../../hooks/useLocales';
import { getAllWordsThunk } from '../../../store/dictionary/thunk';
import { mixLetters } from '../../../utils/mixLetters';
import CreateWord from './CreateWord';

const ExerciseCreateWord = ({ defaultWords, number }) => {
  const [lessonWords, setLessonWords] = useState('');

  const dispatch = useDispatch();
  const { words } = useSelector(wordsSelector);
  const { currentLang } = useLocales();

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  useEffect(() => {
    words.length &&
      setLessonWords(
        words.filter(word => defaultWords.includes(word.translation_ua))
      );
  }, [currentLang, defaultWords, words]);
  return (
    lessonWords &&
    lessonWords.map((word, index) => {
      return (
        <CreateWord
          key={index}
          number={number}
          letters={mixLetters(word.word.toUpperCase())}
          correctlyAnswer={word.word.toUpperCase()}
          title={word[`translation_${currentLang.value}`]}
        />
      );
    })
  );
};

export default ExerciseCreateWord;
