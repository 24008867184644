import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import spain from '../../../../assets/images/lesson2/spain.png';
import france from '../../../../assets/images/lesson2/france.png';
import germany from '../../../../assets/images/lesson2/germany.png';
import usa from '../../../../assets/images/lesson2/usa.png';
import sweden from '../../../../assets/images/lesson2/sweden.png';
import belgium from '../../../../assets/images/lesson2/belgium.png';
import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';
import SortableWords from 'components/Exercises/SortableWords/SortableWords';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import WriteWord from 'components/Exercises/WriteWord/WriteWord';
import suizo from '../../../../assets/images/lesson2/suizo.png';
import chino from '../../../../assets/images/lesson2/chino.png';
import húngaro from '../../../../assets/images/lesson2/hungaro.png';
import francesa from '../../../../assets/images/lesson2/francesa.png';
import alemana from '../../../../assets/images/lesson2/alemana.png';
import portuguesa from '../../../../assets/images/lesson2/portuguesa.png';

import foto from '../../../../assets/images/lesson2/foto.png';
import hablar from '../../../../assets/images/lesson2/hablar.png';
import matemáticas from '../../../../assets/images/lesson2/matematicas.png';

import SelectWordsForDialog from 'components/Exercises/SelectWordsForDialog/SelectWordsForDialog';
import SortableLetters from 'components/Exercises/SortableLetters/SortableLetters';
import AddWordsToContainerLibrary from 'components/Exercises/AddWordsToContainerLibrary/AddWordsToContainerLibrary';
import ChooseLetters from 'components/Exercises/ChooseLetters/ChooseLetters';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';

// SelectWord
const correctWord1 = 'Buenos días';
const correctWord2 = '¡Hasta luego!';
const correctWord3 = 'Muchas gracias';
const correctWord4 = '¡Chao!';
const correctWord5 = 'Me llamo María';
const correctWord6 = 'Fatal';

const options1 = ['Buenos días', '¿Qué tal?', 'Muy bien'];
const options2 = ['¡Cuídate!', '¿Cuánto tiempo!', '¡Hasta luego!'];
const options3 = ['Muchas gracias', 'Así así', '¡Buenas!'];
const options4 = ['¿Cómo te va?', '¡Chao!', 'estupendamente'];
const options5 = ['Encantado', 'Fantástico', 'Me llamo María'];
const options6 = ['¡Hasta la próxima!', '¿Te veo luego!', 'Fatal'];

const wordsSelectWord = [
  '¡Hola!',
  '¡Adiós!',
  '¡Que te vaya bien!',
  '¡Nos vemos!',
  '¿Cómo se llama?',
  '¿Cómo va todo?',
];

const correct2Word1 = 'el libro';
const correct2Word2 = 'el trabajo';
const correct2Word3 = 'la foto';
const correct2Word4 = 'la gente';
const correct2Word5 = 'Hungría';
const correct2Word6 = 'el país';

const options7 = ['la libro', 'el libro', 'los libro'];
const options8 = ['el trabajo', 'la trabajo', 'las trabajo'];
const options9 = ['el foto', 'las foto', 'la foto'];
const options10 = ['la gente', 'el gente', 'gentes'];
const options11 = ['Hungría', 'La Hungría', 'Las Hungría'];
const options12 = ['el país', 'la país', 'le país'];

const wordsSelectWord2 = [
  'libro',
  'trabajo',
  'foto',
  'marroquí',
  'Hungría',
  'país',
];

// const correct2Word3 = 'kiwi';
// const correct2Word2 = 'el taxi';
// const correct2Word1 = 'el gato';
// const correct2Word4 = 'la luna';

// CreateWords
const defaultWords = [
  'навчатися',
  'говорити',
  'робити',
  'називатися',
  'прекрасно',
];
const defaultWords2 = [
  'час, погода',
  'навзаєм',
  'знати, знайомитися',
  'американець/американка',
];

// QuestionCardsToTranslate
// const wordsToTranslate = ['el invierno', 'el humo', 'el zapato'];

// WriteWordToImage
const images = [spain, france, germany, usa, belgium, sweden];
const correctWords = [
  'España',
  'Francia',
  'Alemania',
  'Estados Unidos',
  'Bélgica',
  'Suecia',
];

const images2 = [suizo, chino, húngaro, francesa, alemana, portuguesa];
const correctWords2 = [
  'suizo',
  'chino',
  'húngaro',
  'francesa',
  'alemana',
  'portuguesa',
];

const images3 = [foto, hablar, matemáticas];
const correctWords3 = ['la foto', 'hablar', 'las matemáticas'];

// WriteWord
const words = [
  'el español',
  'la italiana',
  'el ucraniano',
  'la inglesa',
  'la china',
  'el húngaro',
];
const correctWordsToWriteWord = [
  'los españoles',
  'las italianas',
  'los ucranianos',
  'las inglesas',
  'las chinas',
  'los húngaros',
];

// WriteTranslation
const correctWordsToTranslation = [
  'por ejemplo',
  'contar',
  'la niña',
  'regular',
  'la estudiante',
  'mal',
];

const correctWordsToTranslation2 = [
  '¿Qué hay?',
  '¿Cómo estás?',
  '¿Qué haces?',
  '¿Qué te cuentas?',
  'como',
  'normal',
];

const correctWordsToTranslation3 = [
  'el estudiante',
  'tirando',
  'Mucho gusto',
  'Suiza',
  'Inglaterra',
  'China',
];

// AddWordsToContainer
const correctWordsInContainer1 = ['libro', 'país', 'trabajo', 'español '];
const correctWordsInContainer2 = [
  'agua',
  'hada',
  'alma',
  'foto',
  'vida',
  'matemáticas',
];

const correctWords2InContainer1 = [
  '¡Hola!',
  '¡Buenos días!',
  '¡Buenas tardes!',
  '¡Buenas noches!',
  '¡Buenas!',
];
const correctWords2InContainer2 = [
  '¡Hasta luego!',
  '¡Nos vemos!',
  '¡Que tengas un buen día!',
  '¡Que te vaya bien!',
  '¡Hasta la próxima!',
  '¡Hasta la vista!',
  '¡Chao!',
  '¡Adiós!',
  '¡Cuídate!',
  '¡Hasta pronto!',
  '¡Te veo luego!',
];

const correctWords3InContainer1 = [
  'bien',
  'muy bien',
  'perfecto',
  'genial',
  'fantástico',
];
const correctWords3InContainer2 = ['así así', 'fatal', 'mal'];

// MoveWordToContainer
const correctWordInContainer1 = ['Mi amiga estudia matemáticas.'];
const correctWordInContainer2 = ['María es estudiante.'];
const correctWordInContainer3 = ['Olga toma el libro.'];
const correctWordInContainer4 = ['Yo hablo muy bien el español.'];

// SelectWordsForDialog
const selectWordsForDialog1 = ['¡Hola!'];
const selectWordsForDialog2 = ['¡Buenos días!'];
const selectWordsForDialog3 = ['¿Qué tal tú?'];
const selectWordsForDialog4 = ['¿Cómo estás?'];
const selectWordsForDialog5 = ['Muy bien'];
const selectWordsForDialog6 = ['¡Hasta luego!'];

const persons = [
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
];

const wordsForDialog = [
  'Soy María',
  'María, soy Pedro. ¡Mucho gusto en conocerte!',
  'Igualmente.',
  'Genial. Y tú,',
  'Me alegro. Nos vemos.',
];

// SortableWords
// const puzzles = [
//   {
//     words: ['¿', 'te', 'cómo', 'llamas', '?'],
//     correctWords: ['¿', 'cómo', 'te', 'llamas', '?'],
//   },
//   {
//     words: ['¡', 'día', 'tengas', 'qué', 'un', 'buen', '!'],
//     correctWords: ['¡', 'qué', 'tengas', 'un', 'buen', 'día', '!'],
//   },
// ];

// SortableWords
// const wordsToSentence = ['¿ te cómo llamas ?', '¡ día tengas qué un buen!'];
// const correctAnswer = ['¿ cómo te llamas ?', '¡ qué tengas un buen día !'];

const sortableWords = [
  {
    words: '¿ te cómo llamas ?',
    correctAnswer: ['¿ Cómo te llamas ?'],
  },
  {
    words: '¡ día tengas qué un buen !',
    correctAnswer: ['¡ Qué tengas un buen día !'],
  },
  {
    words: 'de conocerle encantada',
    correctAnswer: ['Encantada de conocerle'],
  },
  {
    words: '¿ es qué vida de tu ?',
    correctAnswer: ['¿ Qué es de tu vida ?'],
  },
  {
    words: '¿ cuentas qué te ?',
    correctAnswer: ['¿ Qué te cuentas ?'],
  },
];

// ChooseLetters
const correctLetterInContainer1 = ['va'];
const correctLetterInContainer2 = ['pasa'];
const correctLetterInContainer3 = ['te'];
const correctLetterInContainer4 = ['veo'];

const Exercises1Lesson2 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: 'el',
      options: [
        'agua, hada, alma',
        'gente, clase, mano',
        'zanahoria, cabeza, isla',
      ],
      correctAnswer: 'agua, hada, alma',
    },
    {
      question: 'la',
      options: [
        'español, dedo, humo',
        'flor, madre, foto',
        'taxi, yate, nombre',
      ],
      correctAnswer: 'flor, madre, foto',
    },
    {
      question: 'los',
      options: [
        'ucranianos, españoles, húngaros',
        'marroquís, portuguesas, francesas',
        'italianas, alemanas, rusas',
      ],
      correctAnswer: 'ucranianos, españoles, húngaros',
    },
    {
      question: 'las',
      options: [
        'suecos, portugueses, estadounidenses',
        'suizos, chinos, franceses',
        'marroquís, belgas, inglesas',
      ],
      correctAnswer: 'marroquís, belgas, inglesas',
    },
  ];

  // WriteTranslation
  const wordsWriteTranslation = [
    translate('lesson2.exercises.writeTranslation.word1'),
    translate('lesson2.exercises.writeTranslation.word2'),
    translate('lesson2.exercises.writeTranslation.word3'),
    translate('lesson2.exercises.writeTranslation.word4'),
    translate('lesson2.exercises.writeTranslation.word5'),
    translate('lesson2.exercises.writeTranslation.word6'),
  ];

  const wordsWriteTranslation2 = [
    translate('lesson2.exercises.writeTranslation2.word1'),
    translate('lesson2.exercises.writeTranslation2.word2'),
    translate('lesson2.exercises.writeTranslation2.word3'),
    translate('lesson2.exercises.writeTranslation2.word4'),
    translate('lesson2.exercises.writeTranslation2.word5'),
    translate('lesson2.exercises.writeTranslation2.word6'),
  ];

  const wordsWriteTranslation3 = [
    translate('lesson2.exercises.writeTranslation3.word1'),
    translate('lesson2.exercises.writeTranslation3.word2'),
    translate('lesson2.exercises.writeTranslation3.word3'),
    translate('lesson2.exercises.writeTranslation3.word4'),
    translate('lesson2.exercises.writeTranslation3.word5'),
    translate('lesson2.exercises.writeTranslation3.word6'),
  ];

  // QuestionCardsTranslate
  // const translateQuestions = [
  //   {
  //     question: translate('exercises.task7.questionCards2.question1.title'),
  //     options: [
  //       translate('exercises.task7.questionCards2.question1.option1'),
  //       translate('exercises.task7.questionCards2.question1.option2'),
  //     ],
  //   },
  //   {
  //     question: translate('exercises.task7.questionCards2.question2.title'),
  //     options: [
  //       translate('exercises.task7.questionCards2.question2.option1'),
  //       translate('exercises.task7.questionCards2.question2.option2'),
  //     ],
  //   },
  //   {
  //     question: translate('exercises.task7.questionCards2.question3.title'),
  //     options: [
  //       translate('exercises.task7.questionCards2.question3.option1'),
  //       translate('exercises.task7.questionCards2.question3.option2'),
  //     ],
  //   },
  // ];

  // AddWordsToContainer
  const textInLeftContainer = 'masculino';
  const textInRightContainer = 'femenino';

  const textInLeftContainer2 = 'saludos';
  const textInRightContainer2 = 'despedidas';

  const textInLeftContainer3 = 'positivo';
  const textInRightContainer3 = 'negativo';

  // MoveWordToContainer
  const moveWords = [
    translate('lesson2.exercises.task1.word1'),
    translate('lesson2.exercises.task1.word2'),
    translate('lesson2.exercises.task1.word3'),
    translate('lesson2.exercises.task1.word4'),
  ];

  const moveWords3 = [
    '¿Cómo',
    'todo?',
    '¿Qué',
    'tía?',
    '¿Cómo',
    'va?',
    '¡Te',
    'luego!',
  ];

  // SortableWords
  const lettersToWord = [
    'Як Вас звати?',
    'більш-менш',
    'Як же багато часу ми не бачилися!',
    'Італія',
    'Марокко',
  ];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWordsInContainer1}
        correctWordsInContainer2={correctWordsInContainer2}
        textInLeftContainer={textInLeftContainer}
        textInRightContainer={textInRightContainer}
        number={1}
      />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task1')} Elija la opción correcta.
      </h2>

      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={2}
      />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('lesson2.exercises.task6')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        moveWords={moveWords}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('lesson2.exercises.task2')} Escriba el país que
        corresponda a la imagen.
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={6} />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        textInLeftContainer={textInLeftContainer2}
        textInRightContainer={textInRightContainer2}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={8} />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('lesson2.exercises.task7')}
      </h2>
      <SortableWords defaultWords={sortableWords} number={9} />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('lesson2.exercises.task3')} Escriba en plural.
      </h2>
      <WriteWord
        correctWords={correctWordsToWriteWord}
        words={words}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation3}
        correctWordsToTranslation={correctWordsToTranslation3}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task6.title')}
      </h2>
      <ChooseLetters
        correctLetterInContainer1={correctLetterInContainer1}
        correctLetterInContainer2={correctLetterInContainer2}
        correctLetterInContainer3={correctLetterInContainer3}
        correctLetterInContainer4={correctLetterInContainer4}
        moveWords={moveWords3}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords2} number={13} />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords2}
        images={images2}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWords3InContainer1}
        correctWordsInContainer2={correctWords3InContainer2}
        textInLeftContainer={textInLeftContainer3}
        textInRightContainer={textInRightContainer3}
        number={15}
      />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('exercises.task1')} Elija la opción correcta.
      </h2>

      <SelectWordWithoutTranslation
        options1={options7}
        options2={options8}
        options3={options9}
        options4={options10}
        options5={options11}
        options6={options12}
        correctWord1={correct2Word1}
        correctWord2={correct2Word2}
        correctWord3={correct2Word3}
        correctWord4={correct2Word4}
        correctWord5={correct2Word5}
        correctWord6={correct2Word6}
        words={wordsSelectWord2}
        number={16}
      />

      <h2 className={commonStyles.lessonTask}>
        1.17 {translate('lesson2.exercises.task8')}
      </h2>
      <SelectWordsForDialog
        correctWordInContainer1={selectWordsForDialog1}
        correctWordInContainer2={selectWordsForDialog2}
        correctWordInContainer3={selectWordsForDialog3}
        correctWordInContainer4={selectWordsForDialog4}
        correctWordInContainer5={selectWordsForDialog5}
        correctWordInContainer6={selectWordsForDialog6}
        moveWords={wordsForDialog}
        persons={persons}
        number={17}
      />

      <h2 className={commonStyles.lessonTask}>
        1.18 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation2}
        correctWordsToTranslation={correctWordsToTranslation2}
        number={18}
      />

      <h2 className={commonStyles.lessonTask}>
        1.19 {translate('exercises.task3')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords3}
        images={images3}
        number={19}
      />

      <h2 className={commonStyles.lessonTask}>
        1.20 {translate('exercises.task-sortable-letters.title')}
      </h2>
      <SortableLetters defaultWords={lettersToWord} number={20} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson2;
