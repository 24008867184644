import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson7 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. Pedro ... una taza de café.',
      options: ['prefiere', 'puede', 'prefiero'],
      correctAnswer: 'prefiere',
    },
    {
      question: '2. Yo ... a comprar una casa bonita.',
      options: ['va', 'puedo', 'voy'],
      correctAnswer: 'voy',
    },
    {
      question: '3. Tú ... estudiar bien.',
      options: ['piensa', 'piensas', 'pensamos'],
      correctAnswer: 'piensas',
    },
    {
      question: '4. (Nosotros) casa es grande.',
      options: ['Vuestra', 'Mi', 'Nuestra'],
      correctAnswer: 'Nuestra',
    },
    {
      question: '5. ... lápices son verdes.',
      options: ['Estos', 'Estas', 'Este'],
      correctAnswer: 'Estos',
    },
    {
      question: '6. ... chica es mi hermana.',
      options: ['Este', 'Ese', 'Esta'],
      correctAnswer: 'Esta',
    },
    {
      question: '7. Mi madre es alta, y (tú) ... es baja.',
      options: ['la tuya', 'el tuyo', 'la mía'],
      correctAnswer: 'la tuya',
    },
    {
      question: '8. ¿De quién es esta mochila?',
      options: ['Es tuyo', 'Es la mía', 'Es mía'],
      correctAnswer: 'Es mía',
    },
    {
      question: '9. Mi amigo es tan guapo ... el tuyo.',
      options: ['que', 'cual', 'como'],
      correctAnswer: 'como',
    },
    {
      question: '10. José es mi jefe, es ... que yo.',
      options: ['menor', 'superior', 'inferior'],
      correctAnswer: 'superior',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson7;
