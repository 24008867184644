import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllWords, getWordByLesson } from "../../api/dictionaries";

export const getAllWordsThunk = createAsyncThunk("words/getAllWords", () => {
  return getAllWords();
});

export const getWordsByLessonThunk = createAsyncThunk(
  "words/getWordsByLesson",
  (lesson) => {
    return getWordByLesson(lesson);
  }
);
