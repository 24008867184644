import css from './EditBtn.module.css';
import sprite from '../../../assets/svg/sprite-cards.svg';
import { useMediaQuery } from '@react-hook/media-query';

const EditBtn = ({ handleOnClick }) => {
  const isTabletScreen = useMediaQuery('(max-width: 1279px)');
  const isLaptopScreen = useMediaQuery('(min-width: 1280px)');

  return (
    <>
      {isTabletScreen && (
        <button type="button" className={css.editBtn} onClick={handleOnClick}>
          <svg width="24" height="24">
            <use href={`${sprite}#icon-edit`}></use>
          </svg>
        </button>
      )}
      {isLaptopScreen && (
        <button type="button" className={css.editBtn} onClick={handleOnClick}>
          <svg width="30" height="30">
            <use href={`${sprite}#icon-edit`}></use>
          </svg>
        </button>
      )}
    </>
  );
};

export default EditBtn;
