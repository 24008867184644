import React, { useState } from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import WriteTranslationWithoutTranslation from 'components/Exercises/WriteTranslationWithoutTranslation/WriteTranslationWithoutTranslation';

// Write translation

const correctWordsToTranslation2 = [
  'dar',
  'valer',
  'ver',
  'regalar',
  'caer',
  'traer',
];

// CreateWords
const defaultWords = ['дарувати', 'вміщатися', 'падати', 'знати', 'приносити'];

// WriteWordsToText
const correctWordsToText = [
  'son',
  'les gusta',
  'regalar',
  'la televisión',
  'saber',
  'traen',
  'quieren',
  '¡Qué bien!',
  'piensan',
  'los españoles',
];

const wordsToText1 = [
  'Los españoles',
  'y',
  'Les gusta',
  'Les gusta ver',
  'Les gusta',
  'A veces',
  'Les gusta dar abrazos y besos a las personas que',
  'Cuando algo les gusta mucho, dicen',
  'Les gusta caer bien a los demás y les importa mucho lo que',
  'En resumen, a',
];

const wordsToText2 = [
  'muy amables',
  'ayudar a los demás.',
  'cosas bonitas a sus amigos y familiares.',
  'y también les gusta salir a pasear por la ciudad.',
  'muchas cosas sobre diferentes temas.',
  'regalos cuando visitan a alguien.',
  '.',
  '.',
  'en ellos.',
  'les gusta hacer felices a los demás y disfrutan de las pequeñas cosas de la vida.',
];

const correctWordsToText2 = [
  'te',
  'Te',
  'me',
  'nos',
  'les',
  'os',
  'le',
  'le',
  'nos',
];

const words2ToText1 = [
  'A (ti)',
  '(Ti)',
  'A (mí)',
  'A (nosotros)',
  'A (ellos)',
  'A (vosotros)',
  'A (él)',
  'A (ella)',
  'En resumen, a todos (nosotros)',
];

const words2ToText2 = [
  'gusta muchas cosas diferentes.',
  'gusta pasar tiempo con tus amigos y familiares.',
  'gusta leer libros interesantes y ver películas emocionantes.',
  'gusta escuchar música y bailar cuando nadie te está mirando.',
  'gusta probar comida nueva y cocinar platos deliciosos.',
  'gusta viajar y explorar lugares nuevos.',
  'gusta aprender cosas nuevas y mejorar tus habilidades.',
  'gusta estar al aire libre y disfrutar de la naturaleza.',
  'gusta disfrutar de la vida y encontrar la felicidad en las pequeñas cosas.',
];

// AddWordsToContainer
const correctWordsInContainerYo = ['traigo', 'quepo', 'caigo', 'sé'];
const correctWordsInContainerElla = ['vale', 'elige', 'cae', 'sabe', 'trae'];
const correctWordsInContainerTu = [
  'regalas',
  'das',
  'sigues',
  'caes',
  'sabes',
  'traes',
];

const correctWords2InContainerNosotros = [
  'vemos',
  'queremos',
  'nos sentamos',
  'caemos',
  'sabemos',
  'traemos',
];
const correctWords2InContainerEllos = ['saben', 'se ríen', 'caen', 'traen'];
const correctWords2InContainerVosotros = [
  'caéis',
  'sabéis',
  'traéis',
  'competís,',
];

// MoveWordToContainer

const moveWords = ['me', 'te', 'le', 'nos', 'os', 'les'];

const moveWords2 = [
  'Yo',
  'Tú',
  'Él, ella, usted',
  'Nosotros, nosotras',
  'Vosotros, vosotras',
  'Ellos, ellas, ustedes',
];

const correctWordInContainer1 = ['gusta comer'];
const correctWordInContainer2 = ['gustan muchas cosas'];
const correctWordInContainer3 = ['gusta hacer los ejercicios'];
const correctWordInContainer4 = ['gusta ver'];
const correctWordInContainer5 = ['gusta cantar'];
const correctWordInContainer6 = ['gustan los animales'];

const correctWord2InContainer1 = ['valgo'];
const correctWord2InContainer2 = ['vales'];
const correctWord2InContainer3 = ['vale'];
const correctWord2InContainer4 = ['valemos'];
const correctWord2InContainer5 = ['valéis'];
const correctWord2InContainer6 = ['valen'];

const Exercises1Lesson9 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const [words, setWords] = useState(
    correctWordsToText.map(word => ({ text: word, clicked: false }))
  );

  const handleWordClick = index => {
    setWords(currentWords =>
      currentWords.map((word, idx) =>
        idx === index ? { ...word, clicked: !word.clicked } : word
      )
    );
  };

  // QuestionCards
  const defaultQuestions = [
    {
      question: 'Te doy una flor.',
      options: ['Te la doy.', 'Se la doy.', 'Te las doy.'],
      correctAnswer: 'Te la doy.',
    },
    {
      question: 'Le traigo un bolígrafo.',
      options: ['Le lo traigo.', 'Me lo traigo.', 'Se lo traigo.'],
      correctAnswer: 'Se lo traigo.',
    },
    {
      question: 'Nos ... los perros.',
      options: ['gustan', 'gusta', 'gustamos'],
      correctAnswer: 'gustan',
    },
    {
      question: 'Les regalo un coche.',
      options: ['Se lo regalo.', 'Les la regalo.', 'Les lo regalo.'],
      correctAnswer: 'Se lo regalo.',
    },
    {
      question: '¿Qué te gusta hacer?',
      options: ['las flores', 'mi perro', 'leer'],
      correctAnswer: 'leer',
    },
    {
      question: 'Quiero darte las gracias.',
      options: ['Quiero te las dar.', 'Te quiero darlas.', 'Quiero dártelas.'],
      correctAnswer: 'Quiero dártelas.',
    },
  ];

  const defaultQuestions2 = [
    {
      question: 'Por la mañana yo ... las noticias.',
      options: ['ves', 've', 'veo'],
      correctAnswer: 'veo',
    },
    {
      question: 'Mi hijo es muy inteligente, él ... muchas cosas.',
      options: ['sé', 'sabe', 'sabes'],
      correctAnswer: 'sabe',
    },
    {
      question: 'Yo no ... en este bikini.',
      options: ['quepo', 'cabemos', 'cabes'],
      correctAnswer: 'quepo',
    },
    {
      question: 'Mi amigo me ... muchas cosas.',
      options: ['traigo', 'traes', 'trae'],
      correctAnswer: 'trae',
    },
    {
      question: 'Este cuadro no ... tanto.',
      options: ['valgo', 'vale', 'valen'],
      correctAnswer: 'vale',
    },
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>1.1 Elija la opción correcta.</h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 Componga las palabras de estas letras.
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainerYo}
        correctWordsInContainer2={correctWordsInContainerTu}
        correctWordsInContainer3={correctWordsInContainerElla}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task11')}
      </h2>

      <div className={commonStyles.availableWordsWrap}>
        {words.map((word, index) => (
          <div
            className={commonStyles.availableWords}
            key={index}
            onClick={() => handleWordClick(index)}
            style={{
              backgroundColor: word.clicked ? '#e0e3e8' : 'transparent',
            }}
          >
            <p>{word.text}</p>
          </div>
        ))}
      </div>

      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords2}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>1.7 Elija la opción correcta.</h2>
      <QuestionCards questions={defaultQuestions2} number={7} />

      <h2 className={commonStyles.lessonTask}>
        1.8 Arrastre la palabra al contenedor correspondiente.
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainerNosotros}
        correctWordsInContainer2={correctWords2InContainerVosotros}
        correctWordsInContainer3={correctWords2InContainerEllos}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 Escriba la traducción de las palabras.
      </h2>
      <WriteTranslationWithoutTranslation
        correctWordsToTranslation={correctWordsToTranslation2}
        number={9}
      />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('exercises.task13')}
      </h2>
      <WriteWordToText
        correctWords={correctWordsToText2}
        words1={words2ToText1}
        words2={words2ToText2}
        number={10}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson9;
