import React from 'react';
import LessonVideo from './LessonVideo';
import Lesson1Exercises from './Lesson1Exercises/Lesson1Exercises';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';

const Lesson1 = () => {
  // const url =
  //   "https://drive.google.com/file/d/11I0nr1NOqJEVeAE2mBOGXcBlS9djeRph/preview";

  // Формат презентації
  const url =
    'https://drive.google.com/file/d/1GURlGH9_XO5nnr09CROb8m-m-GlKLCzK/preview';

  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>{translate('lesson1.title')}</h1>

      <h2 className={commonStyles.lessonFirstTask}>
        {translate('lesson1.video')}
      </h2>
      <LessonVideo />

      <h2 className={commonStyles.lessonFirstTask}>
        {translate('lesson1.summary')}
      </h2>
      <div>
        <iframe
          title="pdf"
          src={url}
          frameBorder="*"
          allowFullScreen="allowfullscreen"
          width="738"
          height="415"
          className={commonStyles.summary}
        ></iframe>
      </div>

      <h2 className={commonStyles.lessonTask}>{translate('lesson1.task1')}</h2>

      <Lesson1Exercises />
      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Lesson1;
