import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson9 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. A nosotros ... gusta jugar al fútbol.',
      options: ['nos', 'os', 'les'],
      correctAnswer: 'nos',
    },
    {
      question: '2. Jòse a tu padre y a ti ... regala muchos regalos.',
      options: ['nos', 'os', 'te'],
      correctAnswer: 'os',
    },
    {
      question: '3. Lucìa ... pone el abrigo y sale a la calle.',
      options: ['te', 'se', 'les'],
      correctAnswer: 'se',
    },
    {
      question: '4. Hoy ... buen tiempo.',
      options: ['hago', 'haga', 'hace'],
      correctAnswer: 'hace',
    },
    {
      question: '5. Yo te ... las gracias por tu trabajo.',
      options: ['doy', 'das', 'dan'],
      correctAnswer: 'doy',
    },
    {
      question: '6. Marìa y yo ... de casa a las 15.00.',
      options: ['salìs', 'salimos', 'salgo'],
      correctAnswer: 'salimos',
    },
    {
      question: '7. Mark no ... muy bien, lleva gafas.',
      options: ['ves', 'veo', 've'],
      correctAnswer: 've',
    },
    {
      question: '8. Yo siempre ... traigo flores para mi madre.',
      options: ['la', 'le', 'lo'],
      correctAnswer: 'le',
    },
    {
      question: '9. No (yo) ... en este autobús.',
      options: ['cabe', 'cabemos', 'quepo'],
      correctAnswer: 'quepo',
    },
    {
      question: '10. Las hojas ... de los àrboles.',
      options: ['caigo', 'caèis', 'caen'],
      correctAnswer: 'caen',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson9;
