import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson4 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question:
        '1. Persona 1: ¡Buenas noches! ¿Cómo ... ? Persona 2: ! Muy buenas! Fantástico.',
      options: ['es', 'está', 'nos vemos'],
      correctAnswer: 'está',
    },
    {
      question: '2. Las palabras de género masculino.',
      options: [
        'árbol, café, día, chica',
        'sistema, tema, problema',
        'foto, amor, dinero, drama',
      ],
      correctAnswer: 'sistema, tema, problema',
    },
    {
      question: '3. Las palabras de género femenino.',
      options: [
        'árbol, café, problema',
        'sistema, tema, día',
        'foto, chica, facultad, flor',
      ],
      correctAnswer: 'foto, chica, facultad, flor',
    },
    {
      question: translate('lesson4.exercises.test.question4'),
      options: ['el', 'la', 'el, la'],
      correctAnswer: 'el, la',
    },
    {
      question: '5. La facultad es ...',
      options: ['triste', 'famosa', 'caro'],
      correctAnswer: 'famosa',
    },
    {
      question: translate('lesson4.exercises.test.question6'),
      options: [
        'azul, marrón, optimista',
        'hermoso, malo, triste',
        'bonita, caro, famoso',
      ],
      correctAnswer: 'azul, marrón, optimista',
    },
    {
      question: translate('lesson4.exercises.test.question7'),
      options: ['el', 'la', 'el, la'],
      correctAnswer: 'el',
    },
    {
      question: translate('lesson4.exercises.test.question8'),
      options: ['El poeta famoso', 'El masaje cara', 'Los peces triste'],
      correctAnswer: 'El poeta famoso',
    },
    {
      question: '9. El amor es ...',
      options: ['azul', 'marrón', 'bonito'],
      correctAnswer: 'bonito',
    },
    {
      question: '10. El comilón es ...',
      options: [
        translate('lesson4.exercises.test.question10.option1'),
        translate('lesson4.exercises.test.question10.option2'),
        translate('lesson4.exercises.test.question10.option3'),
      ],
      correctAnswer: translate('lesson4.exercises.test.question10.option1'),
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson4;
