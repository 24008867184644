import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import Move5WordsToContainer from 'components/Exercises/Move5WordsToContainer/Move5WordsToContainer';
import QuestionCardsTranslate from 'components/Exercises/QuestionCards/QuestionCardsTranslate';
import lluvia from '../../../../assets/images/lesson1/rain.png';
import árbol from '../../../../assets/images/lesson1/tree.png';
import charco from '../../../../assets/images/lesson1/puddle.png';
import pingüino from '../../../../assets/images/lesson1/pinguin.png';
import casa from '../../../../assets/images/lesson1/home.png';
import tres from '../../../../assets/images/lesson1/three.png';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import AddWordsToContainerLibrary from 'components/Exercises/AddWordsToContainerLibrary/AddWordsToContainerLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';

// CreateWords
const defaultWords = ['плакати', 'бігти', 'угорі, зверху', 'працювати'];
const defaultWords2 = ['чоловік, людина', 'ім’я', 'мені подобається', 'лелека'];

// QuestionCardsToTranslate
const wordsToTranslate = ['el invierno', 'el humo', 'el zapato'];

// WriteWord
const images = [lluvia, árbol, charco];
const correctWords = ['la lluvia', 'el árbol', 'el charco'];

const images2 = [pingüino, casa, tres];
const correctWords2 = ['el pingüino', 'la casa', 'tres'];

// WriteTranslation
const correctWordsToTranslation = [
  'el chico',
  'comer',
  'tomar',
  'fácil',
  'estar',
];

// AddWordsToContainer
const correctWordsInContainer1 = [
  'el charco',
  'llorar',
  'la guitarra',
  'la guerra',
];
const correctWordsInContainer2 = [
  'el plato',
  'el jardín',
  'fantástico',
  'la cabeza',
];

// MoveWordToContainer
const correctWordInContainer1 = ['la tarta'];
const correctWordInContainer2 = ['la cosa'];
const correctWordInContainer3 = ['el padre'];
const correctWordInContainer4 = ['el cine'];
const correctWordInContainer5 = ['siete'];

const Exercises2 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: translate('exercises.questionCards2.question1.title'),
      options: [
        'toman, el amigo, arriba',
        'espectacular, llorar, correr',
        'comer, tomar, estar',
      ],
      correctAnswer: 'toman, el amigo, arriba',
    },
    {
      question: translate('exercises.questionCards2.question2.title'),
      options: [
        'el elefante, humano, el invierno',
        'la cigüeña, la guerra, la amiga',
        'correr, espectacular, trabajar',
      ],
      correctAnswer: 'correr, espectacular, trabajar',
    },
    {
      question: translate('exercises.questionCards2.question3.title'),
      options: [
        'estar, el charco, tomar',
        'el árbol, fácil, el jardín',
        'el plato, la guitarra, el año',
      ],
      correctAnswer: 'el árbol, fácil, el jardín',
    },
  ];

  // WriteTranslation
  const wordsWriteTranslation = [
    translate('exercises.writeTranslation3.word1'),
    translate('exercises.writeTranslation3.word2'),
    translate('exercises.writeTranslation3.word3'),
    translate('exercises.writeTranslation3.word4'),
    translate('exercises.writeTranslation3.word5'),
  ];

  // QuestionCardsTranslate
  const translateQuestions = [
    {
      question: translate('exercises.task7.questionCards2.question1.title'),
      options: [
        translate('exercises.task7.questionCards2.question1.option1'),
        translate('exercises.task7.questionCards2.question1.option2'),
      ],
    },
    {
      question: translate('exercises.task7.questionCards2.question2.title'),
      options: [
        translate('exercises.task7.questionCards2.question2.option1'),
        translate('exercises.task7.questionCards2.question2.option2'),
      ],
    },
    {
      question: translate('exercises.task7.questionCards2.question3.title'),
      options: [
        translate('exercises.task7.questionCards2.question3.option1'),
        translate('exercises.task7.questionCards2.question3.option2'),
      ],
    },
  ];

  // AddWordsToContainer
  const textInLeftContainer = translate('exercises.task5-with-digraphs');
  const textInRightContainer = translate('exercises.task5-without-digraphs');

  // MoveWordToContainer
  const moveWords = [
    translate('exercises.task6.word2-1'),
    translate('exercises.task6.word2-2'),
    translate('exercises.task6.word2-3'),
    translate('exercises.task6.word2-4'),
    translate('exercises.task6.word2-5'),
  ];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      {/* <h2 className={commonStyles.lessonTask}>
        Test {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} /> */}

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('exercises.task3')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWordsInContainer1}
        correctWordsInContainer2={correctWordsInContainer2}
        textInLeftContainer={textInLeftContainer}
        textInRightContainer={textInRightContainer}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <Move5WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        moveWords={moveWords}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task7.title')}
      </h2>
      <QuestionCardsTranslate
        wordsToTranslate={wordsToTranslate}
        translateQuestions={translateQuestions}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task3')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords2}
        images={images2}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords2} number={9} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises2;
