import React from 'react';
import ReactDOM from 'react-dom/client';
// import { App } from 'components/App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { store } from 'store/store';
import App from 'App';

ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <BrowserRouter basename="/cabinet-spanish-school"> */}

      <BrowserRouter>
        {/* <DndProvider backend={HTML5Backend}> */}
        <App />
        {/* </DndProvider> */}
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);
