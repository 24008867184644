import { Chip, Divider, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import Video from './Video';
import { styled } from '@mui/material/styles';
import imgUa from '../../../../assets/images/steps-for-lessons.png';
import imgRu from '../../../../assets/images/steps-for-lessons-ru.png';
import imgPl from '../../../../assets/images/steps-for-lessons-pl.png';
import imgEn from '../../../../assets/images/steps-for-lessons-en.png';

import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import CheckIcon from '@mui/icons-material/Check';
import css from './Instructions.module.css';
import commonStyles from '../../LessonsPage.module.css';
import FullScreenImage from 'components/FullScreenImage/FullScreenImage';
import useLocales from 'hooks/useLocales';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body1,

  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
}));

const Instructions = () => {
  const [isFullScreen, setFullScreen] = useState(false);

  const { translate } = useLocales();
  const { currentLang } = useLocales();

  const currentLanguage = currentLang.value;

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const openFullScreen = () => {
    setFullScreen(true);
  };

  const closeFullScreen = () => {
    setFullScreen(false);
  };

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('instructions.title')}
      </h1>
      <h2 className={commonStyles.lessonFirstTask}>
        {translate('instructions.tasks.task1')}
      </h2>
      <Video />
      <h2 className={commonStyles.lessonTask}>
        {translate('instructions.tasks.task2')}
      </h2>
      <Typography paragraph>
        {translate('instructions.tasks.task2Text')}
      </Typography>
      <ul className={css.list}>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p>{translate('instructions.tasks.task2TextPart1')}</p>
        </li>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p>{translate('instructions.tasks.task2TextPart2')}</p>
        </li>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p>{translate('instructions.tasks.task2TextPart3')}</p>
        </li>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p>{translate('instructions.tasks.task2TextPart4')}</p>
        </li>
      </ul>
      <h2 className={commonStyles.lessonTask}>
        {translate('instructions.tasks.task3')}
      </h2>
      <Typography paragraph>
        {translate('instructions.tasks.task3TextPart1')}
      </Typography>
      <Root sx={{ marginTop: '0px', marginBottom: '0px' }}>
        <Divider
          textAlign="left"
          sx={{
            marginBottom: { mobile: '26px', laptop: '10px' },
            marginTop: { mobile: '26px', laptop: '30px', desktop: '30px' },
          }}
        >
          <Chip
            label={translate('instructions.tasks.dividerLessons')}
            fontSize="24px"
          />
        </Divider>
        {currentLanguage === 'ru' && (
          <img
            src={imgRu}
            srcSet={imgRu}
            alt="lesson-steps"
            loading="lazy"
            className={css.imgSchema}
            onClick={isMobileScreen ? openFullScreen : null}
          />
        )}
        {currentLanguage === 'ua' && (
          <img
            src={imgUa}
            srcSet={imgUa}
            alt="lesson-steps"
            loading="lazy"
            className={css.imgSchema}
            onClick={isMobileScreen ? openFullScreen : null}
          />
        )}
        {currentLanguage === 'pl' && (
          <img
            src={imgPl}
            srcSet={imgPl}
            alt="lesson-steps"
            loading="lazy"
            className={css.imgSchema}
            onClick={isMobileScreen ? openFullScreen : null}
          />
        )}
        {currentLanguage === 'en' && (
          <img
            src={imgEn}
            srcSet={imgEn}
            alt="lesson-steps"
            loading="lazy"
            className={css.imgSchema}
            onClick={isMobileScreen ? openFullScreen : null}
          />
        )}

        {isFullScreen && (
          <FullScreenImage src={imgUa} onClose={closeFullScreen} />
        )}

        <Divider
          textAlign="left"
          sx={{
            marginBottom: { mobile: '26px', laptop: '10px' },
            marginTop: { mobile: '26px', laptop: '30px' },
          }}
        >
          <Chip
            label={translate('instructions.tasks.dividerDictionary')}
            fontSize="24px"
          />
        </Divider>
      </Root>
      <Typography paragraph>
        {translate('instructions.tasks.task3TextPart2')}
      </Typography>
      <ul className={css.listLast}>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p> {translate('instructions.tasks.task3TextPart3')}</p>
        </li>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p>{translate('instructions.tasks.task3TextPart4')}</p>
        </li>
        <li className={css.listItem}>
          <CheckIcon sx={{ color: '#f8bc02' }} />
          <p>{translate('instructions.tasks.task3TextPart5')}</p>
        </li>
      </ul>
      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Instructions;
