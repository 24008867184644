import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import psicologa from '../../../../assets/images/lesson3/psicologa.png';
import arquitecta from '../../../../assets/images/lesson3/arquitecta.png';
import agente from '../../../../assets/images/lesson3/agente.png';
import profesora from '../../../../assets/images/lesson3/profesora.png';
import dependiente from '../../../../assets/images/lesson3/dependiente.png';
import inmobiliario from '../../../../assets/images/lesson3/inmobiliario.png';
import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';
import SortableWords from 'components/Exercises/SortableWords/SortableWords';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import WriteWord from 'components/Exercises/WriteWord/WriteWord';
import AddWordsToContainerLibrary from 'components/Exercises/AddWordsToContainerLibrary/AddWordsToContainerLibrary';
import ChooseLetters from 'components/Exercises/ChooseLetters/ChooseLetters';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import DialogOrdered from 'components/Exercises/DialogOrdered/DialogOrdered';

// SelectWord
const correctWord1 = 'tú';
const correctWord2 = 'nosotros';
const correctWord3 = 'yo';
const correctWord4 = 'él';
const correctWord5 = 'vosotros';
const correctWord6 = 'ellas';

const options1 = ['nosotros', 'tú', 'nosotras'];
const options2 = ['yo', 'nosotros', 'vosotros'];
const options3 = ['yo', 'tú', 'usted'];
const options4 = ['ellos', 'él', 'yo'];
const options5 = ['nosotros', 'ustedes', 'vosotros'];
const options6 = ['ellas', 'nosotros', 'vosotros'];

const wordsSelectWord = ['eres', 'somos', 'soy', 'es', 'sois', 'son'];

const correct2Word1 = 'dos';
const correct2Word2 = 'cinco';
const correct2Word3 = 'dos';
const correct2Word4 = 'cuatro';
const correct2Word5 = 'ocho';
const correct2Word6 = 'uno';

const options7 = ['dos', 'tres', 'uno'];
const options8 = ['cuatro', 'seis', 'cinco'];
const options9 = ['uno', 'tres', 'dos'];
const options10 = ['cinco', 'ocho', 'cuatro'];
const options11 = ['cinco', 'ocho', 'nueve'];
const options12 = ['dos', 'uno', 'cero'];

const wordsSelectWord2 = ['1 + 1', '2 + 3', '4 – 2', '1 + 3', '5 + 3', '6 – 5'];

// CreateWords
const defaultWords = ['десять', 'два', 'один', 'нуль'];

// WriteWordToImage
const images = [
  psicologa,
  arquitecta,
  agente,
  profesora,
  dependiente,
  inmobiliario,
];

const correctWords = [
  'la psicóloga',
  'la arquitecta',
  'el agente comercial',
  'la profesora',
  'el dependiente',
  'el agente inmobiliario',
];

// WriteWord
const words = [
  'el maestro',
  'la taxista',
  'el astronauta',
  'la enfermera',
  'la estudiante',
  'el contable',
];
const correctWordsToWriteWord = [
  'los maestros',
  'las taxistas',
  'los astronautas',
  'las enfermeras',
  'las estudiantes',
  'los contables',
];

// WriteTranslation
const wordsWriteTranslation = [
  'Yo',
  'Tú',
  'Él',
  'Nosotras',
  'Vosotros',
  'Ellos',
];

const correctWordsToTranslation = ['soy', 'eres', 'es', 'somos', 'sois', 'son'];

const correctWordsToTranslation2 = [
  'siete',
  'seis',
  'ocho',
  'siete',
  'nueve',
  'tres',
];

const correctWordsToTranslation3 = ['nosotros', 'vosotros', 'ella', 'ellos'];

// AddWordsToContainer
const correctWordsInContainer1 = [
  'cero',
  'uno',
  'dos',
  'tres',
  'cuatro',
  'cinco',
];
const correctWordsInContainer2 = ['seis', 'siete', 'ocho', 'nueve', 'diez'];

const correctWords2InContainer1 = ['yo', 'tú', 'él', 'ella', 'usted'];
const correctWords2InContainer2 = [
  'nosotros',
  'vosotros',
  'nosotras',
  'vosotras',
  'ellos',
  'ellas',
  'ustedes',
];

const correctWords3InContainer1 = [
  'el médico',
  'los taxistas',
  'el ingeniero',
  'el analista financiero',
  'el técnico informático',
];
const correctWords3InContainer2 = [
  'la abogada',
  'la gerente de proyecto',
  'las estudiantes',
  'la desarrolladora de software',
];

// MoveWordToContainer
const correctWordInContainer1 = ['Nosotros somos amigos.'];
const correctWordInContainer2 = ['Ellos son niños.'];
const correctWordInContainer3 = ['Vosotros sois franceses.'];
const correctWordInContainer4 = ['¿Cómo estás?'];

const correctWord2InContainer1 = ['llamas'];
const correctWord2InContainer2 = ['llama'];
const correctWord2InContainer3 = ['llamamos'];
const correctWord2InContainer4 = ['llamo'];

const correctWord3InContainer1 = ['llamáis'];
const correctWord3InContainer2 = ['llaman'];
const correctWord3InContainer3 = ['son'];
const correctWord3InContainer4 = ['es'];

const moveWords5 = ['os', 'se', 'ustedes', 'ella'];

// SelectWordsForDialog
const selectWordsForDialog1 = ['Muy buenas!'];
const selectWordsForDialog2 = ['Perfecto, y ¿tú?'];
const selectWordsForDialog3 = ['Me llamo María, y ¿tú?'];
const selectWordsForDialog4 = ['Encantada de conocerte, Sara.'];

const persons = [
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
];

const wordsForDialog = [
  '¡Buenas noches!',
  '¿Qué tal?',
  'Genial. ¿Cómo te llamas?',
  'Mucho gusto. Soy Sara.',
  'Igualmente.',
];

const sortableWords = [
  {
    words: 'somos amigos nosotros',
    correctAnswer: ['Nosotros somos amigos'],
  },
  {
    words: 'eres española tú',
    correctAnswer: ['Tú eres española'],
  },
  {
    words: 'cinco somos nosotros personas',
    correctAnswer: ['Nosotros somos cinco personas'],
  },
];

// ChooseLetters
const correctLetterInContainer1 = ['soy'];
const correctLetterInContainer2 = ['sois'];
const correctLetterInContainer3 = ['somos'];
const correctLetterInContainer4 = ['son'];

const correctLetter2InContainer1 = ['uno'];
const correctLetter2InContainer2 = ['tres'];
const correctLetter2InContainer3 = ['ocho'];
const correctLetter2InContainer4 = ['cinco'];

const Exercises1Lesson3 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: 'Yo … ucraniana.',
      options: ['son', 'soy', 'sois'],
      correctAnswer: 'soy',
    },
    {
      question: 'Nosotros … cinco personas.',
      options: ['somos', 'sois', 'son'],
      correctAnswer: 'somos',
    },
    {
      question: 'Tú … estudiante.',
      options: ['es', 'eres', 'ese'],
      correctAnswer: 'eres',
    },
    {
      question: 'Ella … mi madre.',
      options: ['eres', 'es', 'esa'],
      correctAnswer: 'es',
    },
  ];

  // WriteTranslation

  const wordsWriteTranslation2 = [
    'Tres + cuatro = ',
    'Cinco + uno = ',
    'Dos + seis = ',
    'Siete + cero = ',
    'Ocho + uno = ',
    'Nueve – seis = ',
  ];

  const wordsWriteTranslation3 = [
    'Yo y mi madre = ',
    'Tú y el amigo = ',
    'Mi hermana = ',
    'Un inglés y un español = ',
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'Números 0-5';
  const textInRightContainer = 'Números 6-10';

  const textInLeftContainer2 = 'singular';
  const textInRightContainer2 = 'plural';

  const textInLeftContainer3 = 'Profesión masculina';
  const textInRightContainer3 = 'Profesión femenina';

  // MoveWordToContainer
  const moveWords = [
    translate('lesson3.exercises.task2.word1'),
    translate('lesson3.exercises.task2.word2'),
    translate('lesson3.exercises.task2.word3'),
    translate('lesson3.exercises.task2.word4'),
  ];

  const moveWords2 = [
    'Tres + ',
    ' = cuatro',
    'Cinco + ',
    ' = ocho',
    'Uno + ',
    ' = nueve',
    'Seis – ',
    ' = uno',
  ];

  const moveWords3 = [
    'Yo',
    'estudiante.',
    'Vosotras',
    'españolas.',
    'Nosotras',
    'portuguesas.',
    'Ellos',
    'ingleses.',
  ];

  const moveWords4 = ['te', 'se', 'nos', 'me'];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('lesson3.exercises.task1')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={1}
      />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('lesson2.exercises.task6')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        moveWords={moveWords}
        number={2}
      />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={3} />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('exercises.task1')} Elija la opción correcta.
      </h2>

      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task6.title')}
      </h2>
      <ChooseLetters
        correctLetterInContainer1={correctLetterInContainer1}
        correctLetterInContainer2={correctLetterInContainer2}
        correctLetterInContainer3={correctLetterInContainer3}
        correctLetterInContainer4={correctLetterInContainer4}
        moveWords={moveWords3}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        textInLeftContainer={textInLeftContainer2}
        textInRightContainer={textInRightContainer2}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('lesson3.exercises.task6')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation3}
        correctWordsToTranslation={correctWordsToTranslation3}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWordsInContainer1}
        correctWordsInContainer2={correctWordsInContainer2}
        textInLeftContainer={textInLeftContainer}
        textInRightContainer={textInRightContainer}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={9} />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('lesson3.exercises.task10')} Uno + uno = dos
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation2}
        correctWordsToTranslation={correctWordsToTranslation2}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task1')} Elija la opción correcta.
      </h2>

      <SelectWordWithoutTranslation
        options1={options7}
        options2={options8}
        options3={options9}
        options4={options10}
        options5={options11}
        options6={options12}
        correctWord1={correct2Word1}
        correctWord2={correct2Word2}
        correctWord3={correct2Word3}
        correctWord4={correct2Word4}
        correctWord5={correct2Word5}
        correctWord6={correct2Word6}
        words={wordsSelectWord2}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task6.title')}
      </h2>
      <ChooseLetters
        correctLetterInContainer1={correctLetter2InContainer1}
        correctLetterInContainer2={correctLetter2InContainer2}
        correctLetterInContainer3={correctLetter2InContainer3}
        correctLetterInContainer4={correctLetter2InContainer4}
        moveWords={moveWords2}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('exercises.task6.title')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        moveWords={moveWords4}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('lesson2.exercises.task3')} Escriba en plural.
      </h2>
      <WriteWord
        correctWords={correctWordsToWriteWord}
        words={words}
        number={15}
      />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWords3InContainer1}
        correctWordsInContainer2={correctWords3InContainer2}
        textInLeftContainer={textInLeftContainer3}
        textInRightContainer={textInRightContainer3}
        number={16}
      />

      <h2 className={commonStyles.lessonTask}>
        1.17 {translate('lesson2.exercises.task8')}
      </h2>
      <DialogOrdered
        correctWordInContainer1={selectWordsForDialog1}
        correctWordInContainer2={selectWordsForDialog2}
        correctWordInContainer3={selectWordsForDialog3}
        correctWordInContainer4={selectWordsForDialog4}
        moveWords={wordsForDialog}
        persons={persons}
        number={17}
      />

      <h2 className={commonStyles.lessonTask}>
        1.18 {translate('lesson2.exercises.task7')}
      </h2>
      <SortableWords defaultWords={sortableWords} number={18} />

      <h2 className={commonStyles.lessonTask}>
        1.19 {translate('exercises.task6.title')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord3InContainer1}
        correctWordInContainer2={correctWord3InContainer2}
        correctWordInContainer3={correctWord3InContainer3}
        correctWordInContainer4={correctWord3InContainer4}
        moveWords={moveWords5}
        number={19}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson3;
