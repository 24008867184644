import React, { useState, useCallback, useEffect } from 'react';
import css from './TestChooseOption.module.css';
import QuestionCardsTranslate from './QuestionCardsTranslate';
import useLocales from 'hooks/useLocales';
// import Timer from 'components/Timer/Timer';

import { updateProfileThunk } from 'store/auth/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const TestHeader = ({ translateQuestions }) => {
  const { translate } = useLocales();

  const [isTestStarted, setIsTestStarted] = useState(false);
  const [isQuestionVisible, setIsQuestionVisible] = useState(false);
  const [isTestEnded, setIsTestEnded] = useState(false);

  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [withoutAnswer, setWithoutAnswer] = useState(10);

  // eslint-disable-next-line no-unused-vars
  const [_correctAnswersArray, setCorrectAnswersArray] = useState(
    Array(translateQuestions.length).fill(null)
  );

  const [timer, setTimer] = useState(180);
  const [remainingTime, setRemainingTime] = useState(180);
  // const [isTestEndedApi, setIsTestEnded] = useState(false);

  const number = 'test1';

  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = arrPathname[2];
  const lesson = arrPathname[3];

  useEffect(() => {
    const isAnswer =
      user.exercises?.[module]?.[lesson]?.[number]?.testStatistics;

    // console.log(isAnswer);

    if (isAnswer) {
      setCorrectAnswers(isAnswer.correctAnswers);
      setWrongAnswers(isAnswer.wrongAnswers);
      setWithoutAnswer(isAnswer.withoutAnswer);
      setRemainingTime(isAnswer.remainingTime);
      setTimer(isAnswer.remainingTime); // Set the initial timer state

      setIsTestEnded(true);
    }
  }, [lesson, module, number, user.exercises]);

  const endTest = useCallback(() => {
    let withoutAnswerCount = 10;

    setCorrectAnswersArray(prevCorrectAnswersArray => {
      let correctAnswersCount = 0;
      let wrongAnswersCount = 0;

      prevCorrectAnswersArray.forEach(item => {
        if (item) {
          correctAnswersCount++;
        } else if (item !== null) {
          wrongAnswersCount++;
        }
      });

      withoutAnswerCount =
        withoutAnswerCount - correctAnswersCount - wrongAnswersCount;

      setWithoutAnswer(withoutAnswerCount);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: {
            ...user.exercises,
            [module]: {
              ...(user.exercises[module] || {}),
              [lesson]: {
                ...(user.exercises[module]?.[lesson] || {}),
                [number]: {
                  ...(user.exercises[module]?.[lesson]?.[number] || {}),
                  testStatistics: {
                    correctAnswers: correctAnswersCount,
                    wrongAnswers: wrongAnswersCount,
                    withoutAnswer: withoutAnswerCount,
                    scores: correctAnswersCount,
                    remainingTime: remainingTime,
                  },
                },
              },
            },
          },
        })
      );

      setIsTestStarted(false);
      setIsTestEnded(true);

      // Return the updated array
      return prevCorrectAnswersArray.map(item => item);
    });
  }, [dispatch, user.email, user.exercises, module, lesson, remainingTime]);

  const startTest = () => {
    setIsTestStarted(true);
    setIsQuestionVisible(true);
  };

  //   const endTest = () => {
  //     setIsTestStarted(false);
  //     setIsTestEnded(true);
  //   };

  const updateStatistics = (option, answer, index) => {
    if (option === answer) {
      setCorrectAnswersArray(prev => prev.toSpliced(index, 1, true));
    } else {
      setCorrectAnswersArray(prev => prev.toSpliced(index, 1, false));
    }
  };

  useEffect(() => {
    let timerInterval;

    if (isTestStarted) {
      timerInterval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(timerInterval);
            endTest(remainingTime); // Pass remainingTime to endTest
          } else {
            setRemainingTime(prevRemainingTime => prevRemainingTime - 1);
          }
          return prevTimer > 0 ? prevTimer - 1 : 0;
        });
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [endTest, isTestStarted, remainingTime]);

  return (
    <div className={css.testContainer}>
      <div className={css.testHeaderWrap}>
        <div className={css.testHeaderListWrap}>
          <ul className={css.testHeaderList}>
            <li className={css.testHeaderItem}>
              <p>{translate('lesson1-test.correct')}:</p>
              <p>{isTestEnded ? correctAnswers : 0}</p>
            </li>
            <li className={css.testHeaderItem}>
              <p>{translate('lesson1-test.wrong')}:</p>
              <p>{isTestEnded ? wrongAnswers : 0}</p>
            </li>
            <li className={css.testHeaderItem}>
              <p>{translate('lesson1-test.without-answer')}:</p>
              <p>{isTestEnded ? withoutAnswer : 0}</p>
            </li>
            <li className={css.testHeaderItem}>
              <p>{translate('lesson1-test.scores')}:</p>
              <p>{isTestEnded ? correctAnswers : 0}</p>
            </li>
          </ul>
          <div className={css.testHeaderTimeWrap}>
            <p className={css.timeText}>{translate('lesson1-test.time')}:</p>
            <p className={css.time}>
              {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}
              {timer % 60}
              {/* <Timer
                time={180}
                endTest={endTest}
                isTestStarted={isTestStarted}
                // remainingTime={remainingTime}
              /> */}
            </p>
          </div>
        </div>

        <div className={css.testButtonWrap}>
          <button
            className={css.testButton}
            onClick={startTest}
            disabled={isTestStarted || isTestEnded}
          >
            {translate('lesson1-test.start')}
          </button>
        </div>
      </div>

      {(isQuestionVisible || isTestEnded) && (
        <>
          <QuestionCardsTranslate
            // wordsToTranslate={wordsToTranslate}
            translateQuestions={translateQuestions}
            isTestEnded={isTestEnded}
            updateStatistics={updateStatistics} // Передаем функцию обновления статистики
          />

          <div className={css.testButtonWrapEnd}>
            <button
              className={css.testButton}
              onClick={endTest}
              disabled={isTestEnded}
            >
              {translate('lesson1-test.end')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TestHeader;
