import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BlogPage from './pages/Blog/BlogPage';
import LessonsPage from './pages/Lessons/LessonsPage';
import LoginPage from './pages/Login/LoginPage';
import GuardsPublic from './guards/GuardsPublic';
import GuardsAuth from './guards/GuardsAuth';
import ProfilePage from './pages/Profile/ProfilePage';
import BlogEditPage from './pages/Blog/BlogEditPage/BlogEditPage';
import GuardsAdmin from './guards/GuardsAdmin';
import DictionaryPage from './pages/Dictionary/DictionaryPage';
import IsOnLearning from './components/Dictionary/IsOnLearning/IsOnLearning';
import IsLearned from './components/Dictionary/IsLearned/IsLearned';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogDetailedPage from './pages/Blog/BlogDetailedPage/BlogDetailedPage';
import Layout from './components/Layout/Layout';
import BurgerProvider from './context/BurgerProvider';
import LessonWrap from 'components/Layout/LessonWrap/LessonWrap';
import ForgetPassword from 'pages/ForgetPassword/ForgetPassword';
import RestorePassword from 'pages/RestorePassword/RestorePassword';

const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <GuardsPublic>
              <LoginPage />
            </GuardsPublic>
          }
        />
        <Route
          path="/forget-password"
          element={
            <GuardsPublic>
              <ForgetPassword />
            </GuardsPublic>
          }
        />
        <Route
          path="/restore-password"
          element={
            <GuardsPublic>
              <RestorePassword />
            </GuardsPublic>
          }
        />
        <Route
          path="/"
          element={
            <GuardsAuth>
              <BurgerProvider>
                <Layout />
              </BurgerProvider>
            </GuardsAuth>
          }
        >
          <Route index element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/lessons/:module" element={<LessonsPage />}>
            <Route path=":lesson" element={<LessonWrap />} />
          </Route>

          <Route path="/blog" element={<BlogPage />} />
          <Route
            path="/blog/edit/:id"
            element={
              <GuardsAdmin>
                <BlogEditPage />
              </GuardsAdmin>
            }
          />
          <Route path="/blog/:id" element={<BlogDetailedPage />} />
          <Route path="/dictionary" element={<DictionaryPage />}>
            <Route index element={<IsOnLearning />} />
            <Route path="isLearned" element={<IsLearned />} />
          </Route>
          <Route
            path="/settings"
            element={
              <GuardsAdmin>
                <SettingsPage />
              </GuardsAdmin>
            }
          />
        </Route>
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progress={undefined}
        theme="light"
      />
    </>
  );
};

export default App;
