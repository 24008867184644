import { createSlice } from "@reduxjs/toolkit";
import { getAllWordsThunk, getWordsByLessonThunk } from "./thunk";

const handleGetFullfiled = (state, { payload }) => {
  state.words = payload.toSorted((a, b) => a.word.localeCompare(b.word));
};

const handleFulfilled = (state) => {
  state.isLoading = false;
  state.error = null;
};

const handlePending = (state) => {
  state.isLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.isLoading = false;
  state.error = payload;
};

const dictionarySlice = createSlice({
  name: "dictionary",
  initialState: {
    words: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWordsByLessonThunk.fulfilled, handleGetFullfiled)
      .addCase(getAllWordsThunk.fulfilled, handleGetFullfiled)
      .addMatcher((action) => action.type.endsWith("/pending"), handlePending)
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        handleFulfilled
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        handleRejected
      );
  },
});

export const wordsReducer = dictionarySlice.reducer;
