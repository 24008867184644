import React, { useEffect } from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';

// import QuestionCardsTranslate from '../../../../components/Exercises/QuestionCards/QuestionCardsTranslate';
import tarta from '../../../../assets/images/lesson1/cake.png';
import niño from '../../../../assets/images/lesson1/boy.png';
import uva from '../../../../assets/images/lesson1/grape.png';
import vaca from '../../../../assets/images/lesson1/cow.png';
import madre from '../../../../assets/images/lesson1/mother.png';
import zanahoria from '../../../../assets/images/lesson1/carrot.png';
import flor from '../../../../assets/images/lesson1/flower.png';
import yate from '../../../../assets/images/lesson1/yacht.png';
import guitarra from '../../../../assets/images/lesson1/guitarra.png';
import elefante from '../../../../assets/images/lesson1/elephant.png';
import zapato from '../../../../assets/images/lesson1/shoe.png';
import invierno from '../../../../assets/images/lesson1/winter.png';

import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import Move5WordsToContainer from 'components/Exercises/Move5WordsToContainer/Move5WordsToContainer';
import QuestionCardsTranslate from 'components/Exercises/QuestionCards/QuestionCardsTranslate';
import SelectWord from 'components/Exercises/SelectWord/SelectWord';
// import { WordPuzzle } from 'components/Exercises/WordPuzzle/WordPuzzle';
import SortableLetters from 'components/Exercises/SortableLetters/SortableLetters';
import { useDispatch, useSelector } from 'react-redux';
import { wordsSelector } from 'store/dictionary/selectors';
import { getAllWordsThunk } from 'store/dictionary/thunk';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import AddWordsToContainerLibrary from 'components/Exercises/AddWordsToContainerLibrary/AddWordsToContainerLibrary';
import ChooseSameLetters from 'components/Exercises/ChooseSameLetters/ChooseSameLetters';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';

const defaultWords = ['пити', 'видовищний, дивовижний', 'що', 'я йду'];
const defaultWords2 = ['людський', 'фантастичний', 'великий'];

// QuestionsToTranslate
const wordsToTranslate = ['el hijo', 'la gota', 'el dedo'];

// WriteWord
const images = [tarta, niño, uva, vaca, madre, zanahoria];
const correctWords = [
  'la tarta',
  'el niño',
  'la uva',
  'la vaca',
  'la madre',
  'la zanahoria',
];
const images2 = [flor, yate, guitarra, elefante, zapato, invierno];
const correctWords2 = [
  'la flor',
  'el yate',
  'la guitarra',
  'el elefante',
  'el zapato',
  'el invierno',
];

// WriteTranslation
// const words = ['рік', 'вісім', 'де?', 'літера', 'люди', 'кіоск'];
const correctWordsToTranslation = [
  'el año',
  'ocho',
  '¿dónde?',
  'la letra',
  'la gente',
  'el kiosko',
];

const correctWordsToTranslation2 = [
  'el kilo',
  'yo',
  'el examen',
  'wi-fi',
  'la cabeza',
  'el giro',
];

// AddWordsToContainer
const correctWordsInContainer1 = ['casa', 'cosa', 'clase', 'cuatro'];
const correctWordsInContainer2 = ['cena', 'cine'];

const correctWords2InContainer1 = ['gato', 'gota', 'grande', 'me gusta'];
const correctWords2InContainer2 = ['giro', 'gente'];

// MoveWordToContainer
const correctWordInContainer1 = ['el humo'];
const correctWordInContainer2 = ['el tío'];
const correctWordInContainer3 = ['tres'];
const correctWordInContainer4 = ['el hombre'];
const correctWordInContainer5 = ['el jardín'];

const correctWord2InContainer1 = ['el nombre'];
const correctWord2InContainer2 = ['la opera'];
const correctWord2InContainer3 = ['Roma'];
const correctWord2InContainer4 = ['la amiga'];
const correctWord2InContainer5 = ['la cigüeña'];

// CreateSentence
// const wordsToSentenceCorrect = ['m', 'e ', ' ', 'g', 'u', 's', 't', 'a'];
// const wordsToSentence = ['g', 'm', 'u', 't', 'e ', ' ', 's', 'a'];

// WordPuzzle
// const puzzles = [
//   {
//     words: ['g', 'm', 'u', 't', 'e ', ' ', 's', 'a'],
//     correctWords: ['m', 'e ', ' ', 'g', 'u', 's', 't', 'a'],
//   },
//   {
//     words: ['c', 'p', 'r', 'c', 'a', 't', 'i', 'e'],
//     correctWords: ['p', 'r', 'a', 'c', 't', 'i', 'c', 'e'],
//   },
// ];

// SortableWords
const lettersToWord = [
  'тарілка',
  'батько',
  'рука',
  'веселка',
  'мені подобається',
];

// SelectWord
const correctWord4 = 'quien';
const correctWord1 = 'la gota';
const correctWord2 = 'la guerra';
const correctWord3 = 'la isla';

const options4 = ['quien', 'quen', 'qien'];
const options1 = ['la hota', 'la fota', 'la gota'];
const options2 = ['la guerra', 'la guiera', 'la guera'];
const options3 = ['la icla', 'la izla', 'la isla'];

const correct2Word3 = 'kiwi';
const correct2Word2 = 'el taxi';
const correct2Word1 = 'el gato';
const correct2Word4 = 'la luna';

const optionsSecond3 = ['kiewi', 'kiwi', 'kiwie'];
const optionsSecond2 = ['el taksi', 'el takci', 'el taxi'];
const optionsSecond1 = ['el gano', 'el gato', 'el hato'];
const optionsSecond4 = ['la luna', 'la lyna', 'la lyuna'];

// ChooseLetter
const correctLetterInContainer1 = ['ü'];
const correctLetterInContainer2 = ['u'];
const correctLetterInContainer3 = ['u'];
const correctLetterInContainer4 = ['ü'];

const Exercises = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // SelectWord
  const dispatch = useDispatch();
  const { words } = useSelector(wordsSelector);

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  const filteredWords = words.filter(
    word =>
      word.word === 'la guerra' ||
      word.word === 'la isla' ||
      word.word === 'la gota' ||
      word.word === 'quien'
  );
  const wordsSelectWord = filteredWords;

  const filteredWords2 = words.filter(
    word =>
      word.word === 'kiwi' ||
      word.word === 'la luna' ||
      word.word === 'el gato' ||
      word.word === 'el taxi'
  );
  const wordsSelectWord2 = filteredWords2;

  // QuestionCards
  const defaultQuestions = [
    {
      question: translate('exercises.questionCards.question1.title'),
      options: ['32', '27', '29'],
      correctAnswer: '27',
    },
    {
      question: translate('exercises.questionCards.question2.title'),
      options: ['H', 'G', 'Ñ'],
      correctAnswer: 'H',
    },
    {
      question: translate('exercises.questionCards.question3.title'),
      options: [
        translate('exercises.questionCards.question3.option1'),
        translate('exercises.questionCards.question3.option2'),
        translate('exercises.questionCards.question3.option3'),
      ],
      correctAnswer: translate(
        'exercises.questionCards.question3.correctAnswer'
      ),
    },
  ];

  // WriteTranslation
  const wordsWriteTranslation = [
    translate('exercises.writeTranslation.word1'),
    translate('exercises.writeTranslation.word2'),
    translate('exercises.writeTranslation.word3'),
    translate('exercises.writeTranslation.word4'),
    translate('exercises.writeTranslation.word5'),
    translate('exercises.writeTranslation.word6'),
  ];
  const wordsWriteTranslation2 = [
    translate('exercises.writeTranslation2.word1'),
    translate('exercises.writeTranslation2.word2'),
    translate('exercises.writeTranslation2.word3'),
    translate('exercises.writeTranslation2.word4'),
    translate('exercises.writeTranslation2.word5'),
    translate('exercises.writeTranslation2.word6'),
  ];

  // QuestionCardsTranslate
  const translateQuestions = [
    {
      question: translate('exercises.task7.questionCards.question1.title'),
      options: [
        translate('exercises.task7.questionCards.question1.option1'),
        translate('exercises.task7.questionCards.question1.option2'),
      ],
    },
    {
      question: translate('exercises.task7.questionCards.question2.title'),
      options: [
        translate('exercises.task7.questionCards.question2.option1'),
        translate('exercises.task7.questionCards.question2.option2'),
      ],
    },
    {
      question: translate('exercises.task7.questionCards.question3.title'),
      options: [
        translate('exercises.task7.questionCards.question3.option1'),
        translate('exercises.task7.questionCards.question3.option2'),
      ],
    },
  ];

  // AddWordsToContainer
  const textInLeftContainer = translate('exercises.task5-asK');
  const textInRightContainer = translate('exercises.task5-asC');

  const textInLeftContainer2 = translate('exercises.task11-asG');
  const textInRightContainer2 = translate('exercises.task11-asH');

  // MoveWordToContainer
  const moveWords = [
    translate('exercises.task6.word1'),
    translate('exercises.task6.word2'),
    translate('exercises.task6.word3'),
    translate('exercises.task6.word4'),
    translate('exercises.task6.word5'),
  ];
  const moveWords2 = [
    translate('exercises.task12.word1'),
    translate('exercises.task12.word2'),
    translate('exercises.task12.word3'),
    translate('exercises.task12.word4'),
    translate('exercises.task12.word5'),
  ];
  const moveWords3 = ['cig', 'eña', 'g', 'erra', 'g', 'itarra', 'ping', 'ino'];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('exercises.task3')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWordsInContainer1}
        correctWordsInContainer2={correctWordsInContainer2}
        textInLeftContainer={textInLeftContainer}
        textInRightContainer={textInRightContainer}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task-select-word.title')}
      </h2>

      <SelectWord
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        words={wordsSelectWord}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task6.title')}
      </h2>
      <Move5WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        moveWords={moveWords}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task7.title')}
      </h2>
      <QuestionCardsTranslate
        wordsToTranslate={wordsToTranslate}
        translateQuestions={translateQuestions}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords2} number={9} />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('exercises.task3')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords2}
        images={images2}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task4')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation2}
        correctWordsToTranslation={correctWordsToTranslation2}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task5')}
      </h2>
      <AddWordsToContainerLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        textInLeftContainer={textInLeftContainer2}
        textInRightContainer={textInRightContainer2}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('exercises.task-select-word.title')}
      </h2>

      <SelectWord
        options1={optionsSecond1}
        options2={optionsSecond2}
        options3={optionsSecond3}
        options4={optionsSecond4}
        correctWord1={correct2Word1}
        correctWord2={correct2Word2}
        correctWord3={correct2Word3}
        correctWord4={correct2Word4}
        words={wordsSelectWord2}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <Move5WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        moveWords={moveWords2}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <ChooseSameLetters
        correctLetterInContainer1={correctLetterInContainer1}
        correctLetterInContainer2={correctLetterInContainer2}
        correctLetterInContainer3={correctLetterInContainer3}
        correctLetterInContainer4={correctLetterInContainer4}
        moveWords={moveWords3}
        number={15}
      />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('exercises.task-sortable-letters.title')}
      </h2>
      <SortableLetters defaultWords={lettersToWord} number={16} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises;
