import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson1 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: translate('lesson1-test.question1.title'),
      options: [
        translate('lesson1-test.question1.option1'),
        translate('lesson1-test.question1.option2'),
        translate('lesson1-test.question1.option3'),
      ],
      correctAnswer: 'N, R, G',
    },
    {
      question: translate('lesson1-test.question2.title'),
      options: [
        translate('lesson1-test.question2.option1'),
        translate('lesson1-test.question2.option2'),
        translate('lesson1-test.question2.option3'),
      ],
      correctAnswer: 'C, Z',
    },
    {
      question: translate('lesson1-test.question3.title'),
      options: [
        translate('lesson1-test.question3.option1'),
        translate('lesson1-test.question3.option2'),
        translate('lesson1-test.question3.option3'),
      ],
      correctAnswer: 'casa, cuatro, cosa',
    },
    {
      question: translate('lesson1-test.question4.title'),
      options: [
        translate('lesson1-test.question4.option1'),
        translate('lesson1-test.question4.option2'),
        translate('lesson1-test.question4.option3'),
      ],
      correctAnswer: 'chico, llover, arriba',
    },

    {
      question: translate('lesson1-test.question5.title'),
      options: [
        translate('lesson1-test.question5.option1'),
        translate('lesson1-test.question5.option2'),
        translate('lesson1-test.question5.option3'),
      ],
      correctAnswer: 'la guerra, la guitarra',
    },
    {
      question: translate('lesson1-test.question6.title'),
      options: [
        translate('lesson1-test.question6.option1'),
        translate('lesson1-test.question6.option2'),
        translate('lesson1-test.question6.option3'),
      ],
      correctAnswer: translate('lesson1-test.question6.option2'),
    },
    {
      question: translate('lesson1-test.question7.title'),
      options: [
        translate('lesson1-test.question7.option1'),
        translate('lesson1-test.question7.option2'),
        translate('lesson1-test.question7.option3'),
      ],
      correctAnswer: 'J, G',
    },
    {
      question: translate('lesson1-test.question8.title'),
      options: [
        translate('lesson1-test.question8.option1'),
        translate('lesson1-test.question8.option2'),
        translate('lesson1-test.question8.option3'),
      ],
      correctAnswer: 'humo',
    },
    {
      question: translate('lesson1-test.question9.title'),
      options: [
        translate('lesson1-test.question9.option1'),
        translate('lesson1-test.question9.option2'),
        translate('lesson1-test.question9.option3'),
      ],
      correctAnswer: 'jardin',
    },
    {
      question: translate('lesson1-test.question10.title'),
      options: [
        translate('lesson1-test.question10.option1'),
        translate('lesson1-test.question10.option2'),
        translate('lesson1-test.question10.option3'),
      ],
      correctAnswer: 'Ñ',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson1;
