import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
// import UserBtn from "../../../Buttons/UserBtn/UserBtn";
import Logout from '../../Logout/Logout';
import css from './UserNav.module.css';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import useLocales from '../../../../hooks/useLocales';
import { useBurgerContext } from '../../../../context/BurgerProvider';
import sprite from '../../../../assets/svg/sprite-cards.svg';

const UserNav = () => {
  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { setMenuOpen } = useBurgerContext();

  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { translate } = useLocales();

  const profileText = translate('header.profile');
  // const dashboardText = translate("header.dashboard");
  // const logoutText = translate("header.logout");

  const handleProfile = () => {
    handleCloseUserMenu();
    navigate('/profile');
  };

  const handleMobileProfile = () => {
    navigate('/profile');
    setMenuOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery(
    '(min-width: 768px) and (max-width: 1279px)'
  );
  const isDesktopScreen = useMediaQuery('(min-width: 1280px)');

  return (
    <div className={css.userNavWrap}>
      <nav className={css.userNav}>
        {isMobileScreen && (
          <>
            <IconButton key="Profile" onClick={handleMobileProfile}>
              <svg width="32" height="32">
                <use href={`${sprite}#icon-user-btn-header`}></use>
              </svg>
            </IconButton>
            {user.name && <p className={css.userName}>{user.name}</p>}
          </>
        )}

        {isTabletScreen && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  p: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Avatar alt="avatar" src={user.avatar} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="Profile" onClick={handleProfile}>
                <Typography textAlign="center">{profileText}</Typography>
              </MenuItem>
              {/* <MenuItem key="Dashboard" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{dashboardText}</Typography>
              </MenuItem> */}
              {/* <MenuItem key="Logout" onClick={logout}>
                <Typography textAlign="center">{logoutText}</Typography>
              </MenuItem> */}
              <Logout />
            </Menu>
          </Box>
        )}

        {isDesktopScreen && (
          <>
            <IconButton key="Profile" onClick={handleProfile}>
              <Avatar alt="Remy Sharp" src={user.avatar} />
            </IconButton>
            {user.name && <p className={css.userName}>{user.name}</p>}
          </>
        )}
      </nav>

      {isDesktopScreen && <Logout />}
    </div>
  );
};

export default UserNav;
