import React from 'react';
import Video from './Video';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import Vocabulary from './Vocabulary';

const Countries = () => {
  const url =
    'https://drive.google.com/file/d/1h7VTamJZMsmVM62vMSwiY3P449qFE_ga/preview';

  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson2.lesson2-countries.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        {translate('lesson1.video')}
      </h2>
      <Video />

      <h2 className={commonStyles.lessonTask}>
        {translate('lesson1.summary')}
      </h2>
      <div>
        <iframe
          title="pdf"
          src={url}
          frameBorder="*"
          allowFullScreen="allowfullscreen"
          width="738"
          height="415"
          className={commonStyles.summary}
        ></iframe>
      </div>

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('lesson1.task3')} Vocabulario. Puede añadir las palabras
        a su propio vocabulario.
      </h2>
      <Vocabulary />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Countries;
