export const createLetterBoxesState = string => {
  return string.split('').map((item, index) => ({
    id: index + 1,
    letterForUser: '',
    order: index + 1,
    text: item,
    letterForAnswer: item,
    draggable: true,
    from: '',
  }));
};

export const createLetterBoxes = string => {
  return string.split('').map((item, index) => ({
    id: index + 1,
    letterForUser: '',
    order: index + 1,
    text: item,
    letterForAnswer: item,
    visible: true,
    from: '',
  }));
};

export const createWordBoxesState = (string, isDraggable = true) => {
  return string.split(' ').map((item, index) => ({
    id: index + 1,
    letterForUser: '',
    order: index + 1,
    text: item,
    letterForAnswer: item,
    draggable: isDraggable, // Устанавливаем значение в зависимости от флага
    from: '',
  }));
};

// export const createCardList = array => {
//   return array.map((item, index) => ({
//     id: index + 1,
//     order: index + 1,
//     text: item,
//   }));
// };

export const createCorrectAnswer = string => {
  return string.split('').map((item, index) => ({
    id: index + 1,
    letterForUser: item,
    letterForAnswer: item,
    draggable: false,
    from: '',
  }));
};

export const createCorrectAnswerSortableWords = string => {
  return string.split(' ').map((item, index) => ({
    id: index + 1,
    letterForUser: item,
    letterForAnswer: item,
    draggable: false,
    from: '',
  }));
};
