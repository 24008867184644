import { createSlice } from '@reduxjs/toolkit';

import i18n from '../../configs/i18n';

import {
  createNewPasswordThunk,
  forgetPasswordThunk,
  loginThunk,
  logoutThunk,
  refreshThunk,
  updateAvatarThunk,
  updateFavoritesThunk,
  updatePasswordThunk,
  updateProfileThunk,
  userToggleWordThunk,
} from './thunks';

const fullfiled = (state, { payload }) => {
  state.user = payload.user;
  state.token = payload.token;
  const currentLanguage = localStorage.getItem('i18nextLng');
  if (currentLanguage !== payload.user.language) {
    i18n.changeLanguage(payload.user.language);
    localStorage.setItem('i18nextLng', payload.user.language);
  }
};

const logoutFullfiled = state => {
  state.user = {};
  state.token = '';
};

const addWordFullfiled = (state, { payload }) => {
  state.user.dictionary = payload.user.dictionary;
};

const handleFulfilled = state => {
  state.isLoading = false;
  state.error = null;
};

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.isLoading = false;
  state.error = payload;
};

const updateFullfiled = (state, { payload }) => {
  state.user = payload.user;

  const currentLanguage = localStorage.getItem('i18nextLng');
  if (currentLanguage !== payload.user.language) {
    i18n.changeLanguage(payload.user.language);
    localStorage.setItem('i18nextLng', payload.user.language);
  }
};

const updateFullfiledAvatar = (state, { payload }) => {
  state.user = { ...state.user, avatar: payload.avatar };
};

const updateFullfiledPassword = (state, { payload }) => {
  state.user = { ...state.user, password: payload.password };
};

const updateFavorites = (state, { payload }) => {
  state.user = {
    ...state.user,
    favoritePosts: state.user.favoritePosts.includes(payload)
      ? state.user.favoritePosts.filter(item => item !== payload)
      : [...state.user.favoritePosts, payload],
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    isLoading: false,
    token: localStorage.getItem('token'),
    error: null,
  },
  extraReducers: builder => {
    builder
      .addCase(loginThunk.fulfilled, fullfiled)
      .addCase(refreshThunk.fulfilled, fullfiled)
      .addCase(forgetPasswordThunk.fulfilled, state => ({
        ...state,
        forgetPasswordSend: true,
      }))
      .addCase(createNewPasswordThunk.fulfilled, state => ({
        ...state,
        restorePasswordSend: true,
      }))
      .addCase(refreshThunk.rejected, state => {
        state.token = null;
      })

      .addCase(logoutThunk.fulfilled, logoutFullfiled)
      .addCase(updateProfileThunk.fulfilled, updateFullfiled)
      .addCase(updateAvatarThunk.fulfilled, updateFullfiledAvatar)
      .addCase(updatePasswordThunk.fulfilled, updateFullfiledPassword)

      .addCase(userToggleWordThunk.fulfilled, addWordFullfiled)
      .addCase(updateFavoritesThunk.fulfilled, updateFavorites)

      .addMatcher(action => action.type.endsWith('/pending'), handlePending)
      .addMatcher(action => action.type.endsWith('/fulfilled'), handleFulfilled)
      .addMatcher(action => action.type.endsWith('/rejected'), handleRejected);
  },
});

export const authReducer = authSlice.reducer;
