import { createPortal } from 'react-dom';
import css from './Loader.module.css';

const Loader = () => {
  return createPortal(
    <div className={css.container}>
      <div className={css.wrap}>
        <div className={css.loading}>
          <div className={css.bounceball}></div>
          <div className={css.text}>LOADING</div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Loader;
