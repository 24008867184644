import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import WriteTranslation from '../../../../components/Exercises/WriteTranslation/WriteTranslation';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import notebook from '../../../../assets/images/lesson6/notebook.png';
import window from '../../../../assets/images/lesson6/window.png';
import pensil from '../../../../assets/images/lesson6/pensil.png';
import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import SortableWords from 'components/Exercises/SortableWords/SortableWords';
import MoveSentenceToContainer from 'components/Exercises/MoveSentenceToContainer/MoveSentenceToContainer';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import SortableLetters from 'components/Exercises/SortableLetters/SortableLetters';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ChooseLetters from 'components/Exercises/ChooseLetters/ChooseLetters';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import DialogOrdered from 'components/Exercises/DialogOrdered/DialogOrdered';

// WriteWordToImage
const images = [notebook, window, pensil];

const correctWords = ['el cuaderno', 'la ventana', 'el lápiz'];

// ChooseLetters
const correctLetterInContainer1 = ['dónde'];
const correctLetterInContainer2 = ['quién'];
const correctLetterInContainer3 = ['cómo'];
const correctLetterInContainer4 = ['qué'];

const moveWords3 = [
  '¿A',
  'vas esta mañana? – Voy al trabajo.',
  '¿A',
  'llamas?- A mi madre.',
  '¿',
  'es tu padre? – Él es famoso, hermoso, optimista.',
  '¿Con',
  'escribes? - Escribo con un bolígrafo.',
];

const correctLetter3InContainer1 = ['dónde'];
const correctLetter3InContainer2 = ['qué'];
const correctLetter3InContainer3 = ['quién'];
const correctLetter3InContainer4 = ['cómo'];

const moveWords6 = [
  '¿De',
  'es tu familia? – Mi familia es de Ucrania.',
  '¿De',
  'habláis?- Hablamos de la historia de España.',
  '¿A',
  'dicen toda la verdad?',
  '¿',
  'estás?',
];

// SelectWordsForDialog
const selectWordsForDialog1 = ['Porque me gusta este país.'];
const selectWordsForDialog2 = ['Soy de Francia.'];
const selectWordsForDialog3 = ['Voy a la Universidad.'];
const selectWordsForDialog4 = ['Vivo en la calle Cabonell.'];

const persons = [
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
  'Persona 2:',
  'Persona 1:',
];

const wordsForDialog = [
  '¿Por qué estás en España?',
  '¿De dónde eres?',
  '¿A dónde vas?',
  '¿Dónde vives?',
  'Mucho gusto.',
];

// SortableWords
const lettersToWord = [
  'Куди?',
  'Кому?',
  'Який він? Як це виглядає?',
  'Чим? З чим?',
  'Коли?',
  'Звідки?',
  'Про що?',
  'Навіщо?',
];

// WriteWordsToText
const correctWordsToText = ['o', 'o', 'o', 'y', 'a', 'as', 'o', 'y', 'o'];

const wordsToText1 = [
  'Cuando yo cierr',
  'siempre piens',
  'Entiend',
  'Cuando me vo',
  'mi madre me mir',
  '¡Que teng',
  'En la Universidad estudi',
  'Luego vo',
  'No pierd',
];

const wordsToText2 = [
  'la ventana por la mañana,',
  'en mis cosas.',
  'que asistir a clases es muy importante.',
  'a la universidad',
  'y me dice: ¡Hasta luego, hijo!',
  'un buen día!',
  ', escribo y leo mucho.',
  'a casa y descanso.',
  'el tiempo y me siento a estudiar los deberes.',
];

const sortableWords = [
  {
    words: 'nada habitación en la no hay',
    correctAnswer: ['En la habitación no hay nada'],
  },
  {
    words: 'hay carpeta en la mesa una',
    correctAnswer: ['En la mesa hay una carpeta'],
  },
  {
    words: 'no aquí hay nada',
    correctAnswer: ['Aquí no hay nada'],
  },
  {
    words: 'la estudiar me por mañana gusta',
    correctAnswer: ['Me gusta estudiar por la mañana'],
  },
];

// SelectWord
const correctWord1 = 'cierro';
const correctWord2 = 'cierras';
const correctWord3 = 'cierra';
const correctWord4 = 'cerramos';
const correctWord5 = 'cerráis';
const correctWord6 = 'cierran';

const options1 = ['cierro', 'cerro', 'cierra'];
const options2 = ['cierro', 'cierras', 'cierra'];
const options3 = ['cierra', 'cierro', 'cierras'];
const options4 = ['cierramos', 'cerramas', 'cerramos'];
const options5 = ['cerramos', 'cerráis', 'cierráis'];
const options6 = ['cierran', 'cerramos', 'cerráis'];

const wordsSelectWord = [
  'yo',
  'tú',
  'él, ella, usted',
  'nosotros, nosotras',
  'vosotros, vosotras',
  'ellos, ellas, ustedes',
];

// CreateWords
const defaultWords = [
  'тут',
  'cклянка',
  'папка',
  'кімната',
  'нічого',
  'збирати',
];

const correctWordsToTranslation = [
  'cerrar',
  'entender',
  'la mañana',
  'perder',
  'sentarse',
];

// AddWordsToContainer
const correctWordsInContainerYo = [
  'cierro',
  'pierdo',
  'recojo',
  'siento',
  'entiendo',
];
const correctWordsInContainerElla = ['es', 'siente', 'entiende'];
const correctWordsInContainerTu = [
  'piensas',
  'prefieres',
  'asistes',
  'sientes',
  'entiendes',
  'metes',
  'vives',
];

const correctWords2InContainer1 = ['empezamos', 'sentimos', 'nos llamamos'];
const correctWords2InContainer3 = ['comienzan', 'tienen', 'compran'];
const correctWords2InContainer2 = ['entendéis', 'os sentáis', 'estáis'];

// MoveWordToContainer
const correctWordInContainer1 = ['recojo'];
const correctWordInContainer2 = ['recoges'];
const correctWordInContainer3 = ['recoge'];
const correctWordInContainer4 = ['recogemos'];
const correctWordInContainer5 = ['recogéis'];
const correctWordInContainer6 = ['recogen'];

const correctWord2InContainer1 = ['empiezo'];
const correctWord2InContainer2 = ['empiezas'];
const correctWord2InContainer3 = ['empieza'];
const correctWord2InContainer4 = ['empezamos'];
const correctWord2InContainer5 = ['empezáis'];
const correctWord2InContainer6 = ['empiezan'];

const correctWord4InContainer1 = ['empezar'];
const correctWord4InContainer2 = ['sentir'];
const correctWord4InContainer3 = ['pensar'];
const correctWord4InContainer4 = ['preferir'];

const correct2Word1 = 'la ventana';
const correct2Word2 = 'la carpeta';
const correct2Word3 = 'el lápiz';
const correct2Word4 = 'el vaso';
const correct2Word5 = 'la habitación';
const correct2Word6 = 'el cuaderno';

const options7 = ['la ventana', 'el ventana'];
const options8 = ['el carpeta', 'la carpeta'];
const options9 = ['la lápiz', 'el lápiz'];
const options10 = ['el vaso', 'la vaso'];
const options11 = ['la habitación', 'el habitación'];
const options12 = ['la cuaderno', 'el cuaderno'];

const wordsSelectWord2 = [
  'ventana',
  'carpeta',
  'lápiz',
  'vaso',
  'habitación',
  'cuaderno',
];

const Exercises1Lesson6 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: '¿A quién dicen toda la verdad?',
      options: [
        'Digo que me gusta saber la verdad',
        'No hablamos con ellos',
        'Dicen a María y a su amiga',
      ],
      correctAnswer: 'Dicen a María y a su amiga',
    },
    {
      question: '¿A dónde vais esta tarde?',
      options: [
        'Vivimos en la calle García López',
        'Vamos al teatro',
        'Voy a la opera',
      ],
      correctAnswer: 'Vamos al teatro',
    },
    {
      question: '¡Cuánto tiempo!',
      options: ['Sí, mucho', '¡Que te vaya bien!', '¡Te veo luego!'],
      correctAnswer: 'Sí, mucho',
    },
    {
      question: '¿De dónde recibes estas noticias?',
      options: [
        'Las recibo de mi madre',
        'Las noticias son interesantes',
        'Me gusta recibir las noticias',
      ],
      correctAnswer: 'Las recibo de mi madre',
    },
    {
      question: '¿Cómo estás?',
      options: ['Estudio mucho', 'Trabajo mucho', 'Perfecto'],
      correctAnswer: 'Perfecto',
    },
    {
      question: '¿Qué compramos?',
      options: [
        'Compro muchos libros',
        'Compráis cuadernos',
        'Compramos muchas cosas',
      ],
      correctAnswer: 'Compramos muchas cosas',
    },
    {
      question: '¿Cuándo estudias?',
      options: ['En casa', 'Por la mañana', 'En la Universidad.'],
      correctAnswer: 'Por la mañana',
    },
    {
      question: '¿Por qué tienes prisa?',
      options: [
        'Porque llego tarde',
        'Porque bailo bien',
        'Porque estudio bien',
      ],
      correctAnswer: 'Porque llego tarde',
    },
  ];

  const defaultQuestions2 = [
    {
      question: 'En la calle ... mucha gente.',
      options: ['hay', 'es', 'está'],
      correctAnswer: 'hay',
    },
    {
      question: 'Aquí no ... nada.',
      options: ['hay', 'es', 'está'],
      correctAnswer: 'hay',
    },
    {
      question: '¿ Cómo ... usted? - Gracias, estoy bien.',
      options: ['hay', 'es', 'está'],
      correctAnswer: 'está',
    },
    {
      question: '¿ De dónde ... Pietro?',
      options: ['hay', 'es', 'está'],
      correctAnswer: 'es',
    },
    {
      question: '¿ Cómo ... tu amigo? – Es inteligente, guapo y simpático.',
      options: ['hay', 'es', 'está'],
      correctAnswer: 'es',
    },
  ];

  // WriteTranslation

  const wordsWriteTranslation = [
    translate('lesson6.exercises.writeTranslation.word1'),
    translate('lesson6.exercises.writeTranslation.word2'),
    translate('lesson6.exercises.writeTranslation.word3'),
    translate('lesson6.exercises.writeTranslation.word4'),
    translate('lesson6.exercises.writeTranslation.word5'),
  ];

  // MoveWordToContainer
  const moveWords4 = [
    translate('lesson6.exercises.task14.word1'),
    translate('lesson6.exercises.task14.word2'),
    translate('lesson6.exercises.task14.word3'),
    translate('lesson6.exercises.task14.word4'),
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  // MoveWordToContainer
  const moveWords = [
    'Yo',
    'Tú',
    'Él, ella, usted',
    'Nosotros, nosotras',
    'Vosotros, vosotras',
    'Ellos, ellas, ustedes',
  ];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('lesson5.exercises.writeTranslation.title')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions2} number={6} />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task6.title')}
      </h2>
      <ChooseLetters
        correctLetterInContainer1={correctLetterInContainer1}
        correctLetterInContainer2={correctLetterInContainer2}
        correctLetterInContainer3={correctLetterInContainer3}
        correctLetterInContainer4={correctLetterInContainer4}
        moveWords={moveWords3}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainerYo}
        correctWordsInContainer2={correctWordsInContainerTu}
        correctWordsInContainer3={correctWordsInContainerElla}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('lesson2.exercises.task8')}
      </h2>
      <DialogOrdered
        correctWordInContainer1={selectWordsForDialog1}
        correctWordInContainer2={selectWordsForDialog2}
        correctWordInContainer3={selectWordsForDialog3}
        correctWordInContainer4={selectWordsForDialog4}
        moveWords={wordsForDialog}
        persons={persons}
        number={9}
      />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('lesson2.exercises.task7')}
      </h2>
      <SortableWords defaultWords={sortableWords} number={10} />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        correctWordsInContainer3={correctWords2InContainer3}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('lesson2.exercises.task6')}
      </h2>
      <MoveSentenceToContainer
        correctWordInContainer1={correctWord4InContainer1}
        correctWordInContainer2={correctWord4InContainer2}
        correctWordInContainer3={correctWord4InContainer3}
        correctWordInContainer4={correctWord4InContainer4}
        moveWords={moveWords4}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('lesson5.exercises.task16')}
      </h2>
      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={15}
      />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('exercises.task-sortable-letters.title')}
      </h2>
      <SortableLetters defaultWords={lettersToWord} number={16} />

      <h2 className={commonStyles.lessonTask}>
        1.17 {translate('exercises.task6.title')}
      </h2>
      <ChooseLetters
        correctLetterInContainer1={correctLetter3InContainer1}
        correctLetterInContainer2={correctLetter3InContainer2}
        correctLetterInContainer3={correctLetter3InContainer3}
        correctLetterInContainer4={correctLetter3InContainer4}
        moveWords={moveWords6}
        number={17}
      />

      <h2 className={commonStyles.lessonTask}>
        1.18 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <SelectWordWithoutTranslation
        options1={options7}
        options2={options8}
        options3={options9}
        options4={options10}
        options5={options11}
        options6={options12}
        correctWord1={correct2Word1}
        correctWord2={correct2Word2}
        correctWord3={correct2Word3}
        correctWord4={correct2Word4}
        correctWord5={correct2Word5}
        correctWord6={correct2Word6}
        words={wordsSelectWord2}
        number={18}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson6;
