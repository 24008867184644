import commonStyles from '../../LessonsPage.module.css';

export default function Video() {
  return (
    <div className="responsive-video">
      <iframe
        title="Урок 1. Диграфи"
        // ref={iframeRef}
        width="738"
        height="415"
        src="https://player.vimeo.com/video/879948477?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={commonStyles.lessonVideo}
      ></iframe>
    </div>
  );
}
