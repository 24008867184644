import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enUS, plPL, ruRU, ukUA } from '@mui/material/locale';

import transEN from '../locales/en.json';
import transUA from '../locales/ua.json';
import transPL from '../locales/pl.json';
import transRU from '../locales/ru.json';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    code: 'gb',
  },
  {
    label: 'Українська',
    value: 'ua',
    systemValue: ukUA,
    code: 'ua',
  },
  {
    label: 'Polski',
    value: 'pl',
    systemValue: plPL,
    code: 'pl',
  },
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    code: 'ru',
  },
];

export const defaultLang = allLangs[1];

const resources = {
  en: {
    translation: transEN,
  },

  ua: {
    translation: transUA,
  },

  pl: {
    translation: transPL,
  },
  ru: {
    translation: transRU,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || defaultLang.value,
    fallbackLng: defaultLang.value,
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler: key => `${key}`,
  });
export default i18n;
