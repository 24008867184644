import css from './EditCloseBtn.module.css';
import sprite from '../../../assets/svg/sprite-cards.svg';

const EditCloseBtn = ({ handleOnClick }) => {
  return (
    <button type="button" className={css.closeEditBtn} onClick={handleOnClick}>
      <svg width="24" height="24">
        <use href={`${sprite}#icon-close`}></use>
      </svg>
    </button>
  );
};

export default EditCloseBtn;
