import { useMediaQuery } from '@react-hook/media-query';
import css from './Footer.module.css';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import SwipeableTemporaryDrawer from 'components/Buttons/OpenLessonsListBtn/OpenLessonsListBtn';
import { useBurgerContext } from 'context/BurgerProvider';

const Footer = () => {
  // eslint-disable-next-line
  const { menuOpen, _setMenuOpen } = useBurgerContext();

  const isMenuOpen = menuOpen;
  const isMobileScreen = useMediaQuery('(max-width: 767px)');

  return (
    <>
      {isMobileScreen && !isMenuOpen ? (
        <div className={css.footer}>
          <SwipeableTemporaryDrawer anchor="bottom" />
          <ChangePageBtn />
        </div>
      ) : null}
    </>
  );
};

export default Footer;
