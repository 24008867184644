import React from "react";
import css from "./Audio.module.css";

export default function Audio(props) {
  function handleClick() {
    const audioElement = document.createElement("audio");
    audioElement.src = props.audioUrl;
    audioElement.play();
  }

  return (
    <div className={css.button}>
      <button
        data-playing="false"
        role="switch"
        aria-checked="false"
        onClick={handleClick}
      >
        {/* <i className="icon fas fa-volume-up"></i> */}
      </button>
    </div>
  );
}
