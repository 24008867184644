import CreateSentence from './CreateSentence';

const SortableWords = ({ defaultWords, number }) => {
  return defaultWords.map((sentence, index) => {
    return (
      <CreateSentence
        number={number}
        index={index}
        key={sentence.words}
        sentence={sentence.words}
        correctlyAnswer={sentence.correctAnswer}
      />
    );
  });
};

export default SortableWords;
