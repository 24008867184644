import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BoxWithWord } from './BoxWithWord/BoxWithWord';
import { createWordBoxesState } from '../helpers/createLetterBoxesState';
import { setTryStatus } from '../helpers/setTryStatus';
import style from './SortableWords.module.css';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const CreateSentence = ({ correctlyAnswer, sentence, number }) => {
  const [letterBoxes, setLetterBoxes] = useState(
    createWordBoxesState(sentence)
  );

  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);
  const [id, setId] = useState(1);
  const [currentCard, setCurrentCard] = useState(null);

  const isAnswerShownRef = useRef(false);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const userAnswer = user.exercises?.[module]?.[lesson]?.[number]?.[sentence];

    if (userAnswer) {
      setTryCounter(Object.values(userAnswer)[0].tryCounterData);

      // if (Object.keys(userAnswer)[0] === correctlyAnswer) {
      //   setLetterBoxes(createWordBoxesState(correctlyAnswer));
      // }
    }
  }, [correctlyAnswer, lesson, module, number, sentence, user.exercises]);

  const onSelectedWordClick = card => {
    if (!card.draggable) return;
    setCurrentCard(card);

    if (currentCard) {
      setLetterBoxes(prevLetterBoxes => {
        return prevLetterBoxes.map(item => {
          if (item.id === card.id) {
            return { ...item, order: currentCard.order };
          }
          if (item.id === currentCard.id) {
            return { ...item, order: card.order };
          }
          return item;
        });
      });
      setCurrentCard(null);
    }
  };

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              [sentence]: {
                ...(user.exercises[module]?.[lesson]?.[number]?.[sentence] ||
                  {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.[sentence]
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, sentence, user.exercises]
  );

  const onCheckBtnClick = useCallback(() => {
    const userAnswer = letterBoxes.map(item => item.text).join(' ');
    const correctAnswer = correctlyAnswer.map(item => item).join(' ');
    // .toLowerCase();

    if (correctAnswer === userAnswer) {
      setTryCounter(setTryStatus(id, true));

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );

      if (!isAnswerShownRef.current) {
        const updatedLetterBoxes = letterBoxes.map(item => ({
          ...item,
          draggable: false,
        }));
        setLetterBoxes(updatedLetterBoxes);
        isAnswerShownRef.current = true;
      }
    } else {
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  }, [
    correctlyAnswer,
    dispatch,
    exerciseData,
    id,
    letterBoxes,
    tryCounter,
    user.email,
  ]);

  useEffect(() => {
    const showAnswer = () =>
      tryCounter.some(item => item.tryStatus === true) ||
      tryCounter.every(item => item.tryStatus === false);

    const checkAnswer = showAnswer();

    if (checkAnswer && !isAnswerShownRef.current) {
      setLetterBoxes(
        createWordBoxesState(
          correctlyAnswer.map(item => item).join(' '),
          // .toLowerCase(),
          false
        )
      );
      isAnswerShownRef.current = true;
    }
  }, [tryCounter, correctlyAnswer, setLetterBoxes]);

  const sortedLetterBoxes = letterBoxes.sort((a, b) => a.order - b.order);

  return (
    <div className={style.container}>
      <div className={style.boxContainer}>
        {sortedLetterBoxes.map((item, index) => (
          <BoxWithWord
            key={item.id}
            item={item}
            index={index}
            onSelectedWordClick={onSelectedWordClick}
            isSelected={currentCard === item}
          />
        ))}
      </div>
      <CheckAnswerBtn
        tryCounter={tryCounter}
        onCheckBtnClick={() => onCheckBtnClick()}
      />
    </div>
  );
};

export default CreateSentence;
