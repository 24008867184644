import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useLocales from 'hooks/useLocales';
import styled from '@emotion/styled';
// import { theme } from 'theme/theme';

const LoginButton = styled(Button)({
  '&:hover': {
    backgroundColor: '#364f6a',
    color: '#f8bc02',
  },
});

const ForgetPassword = ({ handleSubmit }) => {
  const { translate } = useLocales();

  return (
    <Formik
      initialValues={{ email: ' ', password: ' ' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(translate('login.email-invalid'))
          .required(translate('login.email-required')),
        password: Yup.string().required(translate('login.password-required')),
      })}
      onSubmit={values => handleSubmit(values)}
    >
      {formik => (
        <Stack
          sx={{
            width: {
              mobile: '290px',
              iphone: '320px',
              tablet: '460px',
              laptop: '500px',
              desktop: '600px',
            },
            m: '0 auto',
          }}
        >
          <Form>
            <Stack>
              <CardContent
                sx={{
                  padding: {
                    mobile: '18px 12px 18px 12px',
                    tablet: '32px 60px',
                    laptop: '32px 80px 32px 80px ',
                    desktop: '32px 110px 32px 110px ',
                  },
                }}
              >
                <Stack
                  sx={{
                    gap: {
                      mobile: '14px',
                      tablet: '16px',
                      laptop: '20px',
                    },
                  }}
                >
                  <Stack>
                    <TextField
                      name="email"
                      type="email"
                      id="email"
                      label={translate('login.email')}
                      fullWidth
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                    />
                    {formik.errors.email && (
                      <Typography m={0} p={0} variant="subtitle1" color="red">
                        {formik.errors.email}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Stack>
            <Stack
              sx={{
                justifyContent: 'center',
                marginTop: { mobile: '0px', laptop: '0px' },
              }}
            >
              <CardActions
                sx={{
                  justifyContent: 'center',
                  padding: {
                    mobile: '4px 18px 8px 18px',
                    tablet: '8px 18px 0px 18px',
                    laptop: '16px 18px 8px 18px',
                  },
                }}
              >
                <LoginButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={!formik.isValid}
                  sx={{
                    minWidth: {
                      mobile: '160px',
                      tablet: '180px',
                      laptop: '220px',
                    },
                    fontSize: { tablet: '16px' },
                  }}
                >
                  {translate('login.forgot-password-btn')}
                </LoginButton>
              </CardActions>
            </Stack>
          </Form>
        </Stack>
      )}
    </Formik>
  );
};

export default ForgetPassword;
