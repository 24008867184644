import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import kilo from '../../../../assets/images/lesson8/kilo.jpeg';
import manzana from '../../../../assets/images/lesson8/apple.jpeg';
import cama from '../../../../assets/images/lesson8/bed.jpeg';
import shower from '../../../../assets/images/lesson8/take-shower.png';
import dry from '../../../../assets/images/lesson8/dry-off.png';
import puton from '../../../../assets/images/lesson8/put-on-cloth.png';

import comb from '../../../../assets/images/lesson8/comb.png';
import takebath from '../../../../assets/images/lesson8/take-bath.png';
import getup from '../../../../assets/images/lesson8/get-up.png';

import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import WriteTranslation from 'components/Exercises/WriteTranslation/WriteTranslation';
import Move6WToCWithoutTranslation from 'components/Exercises/Move6WToCWithoutTranslation/Move6WToCWithoutTranslation';
import WriteTranslationWithoutTranslation from 'components/Exercises/WriteTranslationWithoutTranslation/WriteTranslationWithoutTranslation';
import QuestionCardsWithAudio from 'components/Exercises/QuestionCardsWithAudio/QuestionCardsWithAudio';

// WriteWordToImage
const images = [kilo, manzana, cama];
const correctWords = ['kilo', 'la manzana', 'la cama'];

const images2 = [shower, dry, puton, comb, takebath, getup];
const correctWords2 = [
  'ducharse',
  'secarse',
  'vestirse',
  'peinarse',
  'bañarse',
  'despertarse',
];

// WriteTranslation
const wordsWriteTranslation = [
  'Trescientos + cuatrocientos = ',
  'Quinientos + cien = ',
  'Doscientos + seiscientos = ',
  'Setecientos+ cincuenta = ',
  'Ochocientos + ciento uno = ',
  'Novecientos – seiscientos = ',
];

const correctWordsToTranslation = [
  'sietecientos',
  'seiscientos',
  'ochocientos',
  'setecientos cincuenta',
  'novecientos uno',
  'trescientos',
];

const correctWordsToTranslation2 = [
  'calzar',
  'desnudar',
  'despedir',
  'bañar',
  'despedirse',
  'calzarse',
];

const wordsWriteTranslation3 = ['789', '891', '912', '1000', '1 000 000'];
const correctWordsToTranslation3 = [
  'setecientos ochenta y nueve',
  'ochocientos noventa y uno',
  'novecientos doce',
  'mil',
  'un millón',
];

const wordsWriteTranslation4 = [
  'dos..., tres..., cuatro..., seis..., sete..., ocho..., nove...',
];
const correctWordsToTranslation4 = ['cientos'];

// CreateWords
const defaultWords = [
  'сімсот',
  'одна тисяча',
  'шістсот',
  'один мільйон',
  'сторінка',
];

// WriteWordsToText
const correctWordsToText = [
  'despierta',
  'lava',
  'ducha',
  'viste',
  'despide',
  'va',
  'afeita',
];

const wordsToText1 = [
  'Cada mañana, a las 7:00 de la mañana, Ramón se (despertarse)',
  'Se (lavarse)',
  'se (ducharse)',
  'se (vestirse)',
  'A las 8:30 de la mañana, se (despedirse)',
  'se (irse)',
  'Alrededor de las 12:00 de la mañana, se (afeitarse)',
];

const wordsToText2 = [
  'y se dirige al baño.',
  ',',
  'y',
  'a las 8:00 de la mañana.',
  'y',
  'al trabajo.',
  '.',
];

// SelectWord
const correctWord1 = 'ducho';
const correctWord2 = 'duchas';
const correctWord3 = 'ducha';
const correctWord4 = 'duchamos';
const correctWord5 = 'ducháis';
const correctWord6 = 'duchan';

const options1 = ['ducho', 'ducha', 'duche'];
const options2 = ['duchas', 'duchan', 'duche'];
const options3 = ['ducho', 'ducha', 'duche'];
const options4 = ['duchamois', 'duchamas', 'duchamos'];
const options5 = ['ducháis', 'duchamos', 'vduchamas'];
const options6 = ['ducháis', 'duchan', 'duchamas'];

const wordsSelectWord = ['Me', 'Te', 'Se', 'Nos', 'Os', 'Se'];

// AddWordsToContainer
const correctWordsInContainerYo = ['Me peino', 'Me visto', 'Me siento'];
const correctWordsInContainerElla = ['Se peina', 'Se viste', 'Se sienta'];
const correctWordsInContainerTu = ['Te peinas', 'Te vistes', 'Te sientas'];

const correctWords2InContainerNosotros = [
  'nos peinamos',
  'nos vestimos',
  'nos sentamos',
];
const correctWords2InContainerEllos = ['se peinan', 'se visten', 'se sientan'];
const correctWords2InContainerVosotros = [
  'os peináis',
  'os vestís',
  'os sentáis',
];

// MoveWordToContainer

const moveWords = ['me', 'te', 'se', 'nos', 'os', 'se'];
const moveWords2 = [
  'competir',
  'pedir',
  'perseguir',
  'seguir',
  'jugar',
  'impedir',
];
const moveWords3 = ['123', '234', '345', '456', '567', '678'];

const correctWordInContainer1 = ['lavo'];
const correctWordInContainer2 = ['lavas'];
const correctWordInContainer3 = ['lava'];
const correctWordInContainer4 = ['lavamos'];
const correctWordInContainer5 = ['laváis'];
const correctWordInContainer6 = ['lavan'];

const correctWord2InContainer1 = ['competir'];
const correctWord2InContainer2 = ['pedir'];
const correctWord2InContainer3 = ['perseguir'];
const correctWord2InContainer4 = ['seguir'];
const correctWord2InContainer5 = ['jugar'];
const correctWord2InContainer6 = ['impedir'];

const correctWord3InContainer1 = ['ciento veinte y tres'];
const correctWord3InContainer2 = ['doscientos treinta y cuatro'];
const correctWord3InContainer3 = ['trescientos cuarenta cinco'];
const correctWord3InContainer4 = ['cuatrocientos cincuenta y seis'];
const correctWord3InContainer5 = ['quinientos sesenta y siete'];
const correctWord3InContainer6 = ['seiscientos setenta y ocho'];

const Exercises1Lesson8 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: '160 + 150 = ',
      options: ['doscientos diez', 'trescientos diez', 'quinientos sesenta'],
      correctAnswer: 'trescientos diez',
    },
    {
      question: '200 + 300 = ',
      options: ['cuatrocientos', 'seiscientos', 'quinientos'],
      correctAnswer: 'quinientos',
    },
    {
      question: '400 – 200 = ',
      options: ['cien', 'mil', 'doscientos'],
      correctAnswer: 'doscientos',
    },
    {
      question: '156 + 378 = ',
      options: [
        'quinientos treinta y cuatro',
        'ochocientos cincuenta y cuatro',
        'cuatrocientos treinta y cuatro',
      ],
      correctAnswer: 'quinientos treinta y cuatro',
    },
    {
      question: '535 + 344 = ',
      options: [
        'quinientos treinta y cinco',
        'ochocientos setenta y nueve',
        'novecientos cuarenta y cuatro',
      ],
      correctAnswer: 'ochocientos setenta y nueve',
    },
    {
      question: '600 – 500 = ',
      options: ['doscientos', 'cien', 'mil cien'],
      correctAnswer: 'cien',
    },
    {
      question: '900 – 600 = ',
      options: ['cuatrocientos', 'seiscientos', 'trescientos'],
      correctAnswer: 'trescientos',
    },
    {
      question: '491 + 29 = ',
      options: [
        'quinientos veinte',
        'setecientos veinte',
        'ochocientos veinte',
      ],
      correctAnswer: 'quinientos veinte',
    },
    {
      question: '963 – 223 = ',
      options: ['seiscientos treinta', 'setecientos cuarenta', 'cinco'],
      correctAnswer: 'setecientos cuarenta',
    },
  ];

  const defaultQuestions2 = [
    {
      question: 'novecientos',
      options: ['90', '900', '9'],
      correctAnswer: '900',
    },
    {
      question: 'quinientos',
      options: ['500', '800', '700'],
      correctAnswer: '500',
    },
    {
      question: 'setecientos',
      options: ['600', '700', '500'],
      correctAnswer: '700',
    },
    {
      question: 'ciento treinta y nueve',
      options: ['539', '598', '139'],
      correctAnswer: '139',
    },
    {
      question: 'ochocientos treinta y dos',
      options: ['724', '832', '634'],
      correctAnswer: '832',
    },
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 {translate('lesson3.exercises.task10')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation}
        correctWordsToTranslation={correctWordsToTranslation}
        number={1}
      />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={2}
      />

      <h2 className={commonStyles.lessonTask}>
        1.3 Escriba los pronombres demostrativos en la forma correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={3} />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={4} />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainerYo}
        correctWordsInContainer2={correctWordsInContainerTu}
        correctWordsInContainer3={correctWordsInContainerElla}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('lesson7.exercises.task4')}
      </h2>
      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords2}
        images={images2}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={9}
      />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('exercises.task5')}
      </h2>
      <Move6WToCWithoutTranslation
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords2}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('exercises.task8')}
      </h2>
      <WriteTranslationWithoutTranslation
        correctWordsToTranslation={correctWordsToTranslation2}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainerNosotros}
        correctWordsInContainer2={correctWords2InContainerVosotros}
        correctWordsInContainer3={correctWords2InContainerEllos}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord3InContainer1}
        correctWordInContainer2={correctWord3InContainer2}
        correctWordInContainer3={correctWord3InContainer3}
        correctWordInContainer4={correctWord3InContainer4}
        correctWordInContainer5={correctWord3InContainer5}
        correctWordInContainer6={correctWord3InContainer6}
        moveWords={moveWords3}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>1.14 Escriba la palabra.</h2>
      <WriteTranslation
        words={wordsWriteTranslation3}
        correctWordsToTranslation={correctWordsToTranslation3}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('exercises.task9')}
      </h2>
      <WriteTranslation
        words={wordsWriteTranslation4}
        correctWordsToTranslation={correctWordsToTranslation4}
        number={15}
      />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('exercises.task10')}
      </h2>
      <QuestionCardsWithAudio questions={defaultQuestions2} number={16} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson8;
