import TryCounter from '../../TryCounter/TryCounter';
import style from './checkAnswerBtn.module.css';

const CheckAnswerWithoutBtn = ({ tryCounter }) => {
  return (
    <div className={style.checkBoxContainer}>
      <TryCounter tryCounter={tryCounter} />
    </div>
  );
};

export default CheckAnswerWithoutBtn;
