import React, { useState } from 'react';
import { Grid } from '@mui/material';
import CheckSelectWord from './CheckSelectWord';

const SelectWordWithoutTranslation = ({
  options1,
  options2,
  options3,
  options4,
  options5,
  options6,
  correctWord1,
  correctWord2,
  correctWord3,
  correctWord4,
  correctWord5,
  correctWord6,
  words,
  number,
}) => {
  const [isCorrect, setIsCorrect] = useState(
    Array(options1.length).fill(false)
  );

  return (
    <div>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {words.map((word, index) => (
          <CheckSelectWord
            number={number}
            key={index}
            index={index}
            isCorrect={isCorrect}
            word={word}
            options1={options1}
            options2={options2}
            options3={options3}
            options4={options4}
            options5={options5}
            options6={options6}
            setIsCorrect={setIsCorrect}
            correctWord1={correctWord1}
            correctWord2={correctWord2}
            correctWord3={correctWord3}
            correctWord4={correctWord4}
            correctWord5={correctWord5}
            correctWord6={correctWord6}
          />
        ))}
      </Grid>
    </div>
  );
};

export default SelectWordWithoutTranslation;
