import React from 'react';
import { Outlet } from 'react-router-dom';
import ContainerHeader from '../Containers/ContainerHeader/ContainerHeader';
import { useBurgerContext } from '../../context/BurgerProvider';
import Header from '../Header/Header';

const Layout = () => {
  const { menuOpen } = useBurgerContext();

  return (
    <div>
      <ContainerHeader>
        <Header />
      </ContainerHeader>
      {!menuOpen && <Outlet />}
    </div>
  );
};

export default Layout;
