import React from 'react';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';
import { useMediaQuery } from '@react-hook/media-query';
import TestChooseOption from 'components/Tests/TestChooseOption/TestChooseOption';

const TestLesson3 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  const translateQuestions = [
    {
      question: '1. Todos nosotros ... amigos.',
      options: ['son', 'somos', 'soy'],
      correctAnswer: 'somos',
    },
    {
      question: '2. Pedro ... español.',
      options: ['somos', 'son', 'es'],
      correctAnswer: 'es',
    },
    {
      question: '3. Yo ... estudiante.',
      options: ['soy', 'es', 'sois'],
      correctAnswer: 'soy',
    },
    {
      question: '4. Tres + cuatro son',
      options: ['siete', 'cinco', 'ocho'],
      correctAnswer: 'siete',
    },
    {
      question: '5. 6',
      options: ['cinco', 'siete', 'seis'],
      correctAnswer: 'seis',
    },
    {
      question: '6. ocho – dos son',
      options: ['uno', 'diez', 'seis'],
      correctAnswer: 'seis',
    },
    {
      question: '7. Mi hija tiene 7 años.',
      options: ['siete', 'ocho', 'nueve'],
      correctAnswer: 'siete',
    },
    {
      question: '8. ... llamo María.',
      options: ['Te', 'Se', 'Me'],
      correctAnswer: 'Me',
    },
    {
      question: '9. ¿Cómo ... esta chica?',
      options: ['te llamas', 'se llama', 'me llamo'],
      correctAnswer: 'se llama',
    },
    {
      question: translate('lesson3.test.question1.title'),
      options: ['el dependiente', 'el contable', 'el enfermero'],
      correctAnswer: 'el dependiente',
    },
  ];

  return (
    <div className={commonStyles.lessonWrap}>
      <h1 className={commonStyles.lessonTitle}>
        {translate('lesson1-test.title')}
      </h1>

      <h2 className={commonStyles.lessonFirstTask}>
        1.1 {translate('lesson1-test.task1')} Haga la prueba.
      </h2>
      <TestChooseOption translateQuestions={translateQuestions} />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default TestLesson3;
