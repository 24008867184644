import React from 'react';
import QuestionCards from '../../../../components/Exercises/QuestionCards/QuestionCards';
import useLocales from '../../../../hooks/useLocales';
import ChangePageBtn from 'components/Buttons/ChangePageBtn/ChangePageBtn';
import commonStyles from '../../LessonsPage.module.css';
import { useMediaQuery } from '@react-hook/media-query';
import volar from '../../../../assets/images/lesson7/to-fly.png';
import dormir from '../../../../assets/images/lesson7/to-sleep.png';
import doler from '../../../../assets/images/lesson7/to-hurt.png';
import acordar from '../../../../assets/images/lesson7/to-negotiate.png';
import bailar from '../../../../assets/images/lesson7/to-dance.png';
import cantar from '../../../../assets/images/lesson7/to-sing.png';
import SelectWordWithoutTranslation from 'components/Exercises/SelectWordWithoutTranslation/SelectWordWithoutTranslation';
import WriteWordToImage from '../../../../components/Exercises/WriteWordToImage/WriteWordToImage';
import Move6WordsToContainer from 'components/Exercises/Move6WordsToContainer/Move6WordsToContainer';
import SortableWords from 'components/Exercises/SortableWords/SortableWords';
import WriteWordToText from 'components/Exercises/WriteWordToText/WriteLetterToWord';
import AddWordsTo3ContainersLibrary from 'components/Exercises/AddWordsTo3ContainersLibrary/AddWordsTo3ContainersLibrary';
import ExerciseCreateWord from 'components/Exercises/CreateWord/ExerciseCreateWord';
import WriteWord from 'components/Exercises/WriteWord/WriteWord';

// CreateWords
const defaultWords = [
  'віддати перевагу',
  'могти',
  'хотіти',
  // 'йти, їхати',
  'необхідно',
  'думати',
];

// WriteWordToImage
const images = [volar, dormir, doler];
const correctWords = ['volar', 'dormir', 'doler'];

const images2 = [acordar, bailar, cantar];
const correctWords2 = ['acordar', 'bailar', 'cantar'];

// WriteWord
const words = ['mi', 'tu', 'nuestro', 'vuestra', 'su', 'nuestra'];
const correctWordsToWriteWord = [
  'mis',
  'tus',
  'nuestros',
  'vuestras',
  'sus',
  'nuestras',
];

// WriteWordsToText
const correctWordsToText = [
  'voy',
  'preferimos',
  'piensas',
  'quiere',
  'podéis',
  'necesito',
];

const wordsToText1 = [
  'Yo (ir)',
  'Nosotros (preferir)',
  'Tú (pensar)',
  'Ella (querer)',
  'Vosotras (poder)',
  'Yo (necesitar)',
];

const wordsToText2 = [
  'a clase del español.',
  'estar en casa esta noche.',
  'mucho.',
  'ser astronauta.',
  'estudiar bien.',
  'viajar a España.',
];

const correctWordsToText2 = ['mía', 'tuyo', 'vuestros', 'suya', 'mío'];

const words2ToText1 = [
  'Su casa es grande y la (yo)',
  'Mi lápiz es gris y el (tú)',
  'Nuestros vasos son azules y los (vosotros)',
  'Tu vida es interesante y la (él)',
  '¡Amigo (yo)',
];

const words2ToText2 = [
  'es pequeña.',
  'es verde.',
  'son marrones.',
  'es más interesante.',
  '!',
];

const correctWordsToText3 = [
  'es',
  'acuerdo',
  'disfrutamos',
  'recordamos',
  'nos reímos',
  'volvemos',
  'encontramos',
  'volamos',
  'me duele',
  'duermo',
];

const words3ToText1 = [
  'Hoy (ser)',
  'Por la mañana, (acordar yo)',
  'Nos reunimos en un bonito lugar y (nosotros disfrutar)',
  'Después de comer, (nosotros recordar)',
  'y (nosotros reirse)',
  'Luego, (nosotros volver)',
  'pero en el camino, (nosotros encontrar)',
  '(Nosotros volar)',
  'Más tarde, ya en casa, (yo dolerme)',
  '(Yo dormir)',
];

const words3ToText2 = [
  'un día especial.',
  'encontrarme con mis amigos para almorzar.',
  'de un delicioso almuerzo juntos.',
  'viejos tiempos',
  'mucho.',
  ' a casa,',
  'un parque hermoso.',
  'cometas y pasamos un rato divertido.',
  'un poco la cabeza.',
  'un poco para sentirme mejor.',
];

const sortableWords = [
  {
    words: 'a casa vas de salir',
    correctAnswer: ['vas a salir de casa'],
  },
  {
    words: 'mucho necesitamos pensar',
    correctAnswer: ['necesitamos pensar mucho'],
  },
  {
    words: 'comer no mucho puedes',
    correctAnswer: ['no puedes comer mucho'],
  },
  {
    words: 'a una comprar van casa',
    correctAnswer: ['van a comprar una casa'],
  },
];

// SelectWord
const correctWord1 = 'voy';
const correctWord2 = 'vas';
const correctWord3 = 'va';
const correctWord4 = 'vamos';
const correctWord5 = 'vais';
const correctWord6 = 'van';

const options1 = ['voy', 'va', 'vas'];
const options2 = ['vas', 'va', 'van'];
const options3 = ['vais', 'va', 'van'];
const options4 = ['vais', 'van', 'vamos'];
const options5 = ['vais', 'vamos', 'vas'];
const options6 = ['va', 'van', 'vas'];

const wordsSelectWord = [
  'yo',
  'tú',
  'él, ella, usted',
  'nosotros, nosotras',
  'vosotros, vosotras',
  'ellos, ellas, ustedes',
];

// AddWordsToContainer
const correctWordsInContainerYo = ['puedo', 'quiero', 'necesito', 'pienso'];
const correctWordsInContainerElla = ['puede', 'quiere', 'necesita', 'piensa'];
const correctWordsInContainerTu = ['puedes', 'quieres', 'necesitas', 'piensas'];

const correctWords2InContainer1 = [
  'podemos',
  'queremos',
  'necesitamos',
  'pensamos',
];
const correctWords2InContainer3 = ['pueden', 'quieren', 'necesitan', 'piensan'];
const correctWords2InContainer2 = [
  'podéis',
  'queréis',
  'necesitáis',
  'pensáis',
];

// MoveWordToContainer
const correctWordInContainer1 = ['prefiero'];
const correctWordInContainer2 = ['prefieres'];
const correctWordInContainer3 = ['prefiere'];
const correctWordInContainer4 = ['preferimos'];
const correctWordInContainer5 = ['preferís'];
const correctWordInContainer6 = ['prefieren'];

const correctWord2InContainer1 = ['mi vecino'];
const correctWord2InContainer2 = ['mis cuadernos'];
const correctWord2InContainer3 = ['tu ventana'];
const correctWord2InContainer4 = ['tus lápices'];
const correctWord2InContainer5 = ['su vaso'];
const correctWord2InContainer6 = ['sus carpetas'];

const correctWord3InContainer1 = ['nuestra casa'];
const correctWord3InContainer2 = ['nuestros coches'];
const correctWord3InContainer3 = ['vuestra habitación'];
const correctWord3InContainer4 = ['vuestros gatos'];
const correctWord3InContainer5 = ['sus amigos'];
const correctWord3InContainer6 = ['su guitarra'];

const Exercises1Lesson7 = () => {
  const { translate } = useLocales();
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  // QuestionCards
  const defaultQuestions = [
    {
      question: '... artículos son caros.',
      options: ['estos', 'esta', 'esa'],
      correctAnswer: 'estos',
    },
    {
      question: '... bocadillos están ricos.',
      options: ['estos', 'estas', 'esas'],
      correctAnswer: 'estos',
    },
    {
      question: '... parque es grande.',
      options: ['estas', 'este', 'aquella'],
      correctAnswer: 'este',
    },
    {
      question: '... chica es bonita.',
      options: ['estas', 'este', 'aquella'],
      correctAnswer: 'aquella',
    },
    {
      question: '... historia no me gusta.',
      options: ['esa', 'este', 'aquel'],
      correctAnswer: 'esa',
    },
    {
      question: '... es sobre ti.',
      options: ['aquella', 'esto', 'aquel'],
      correctAnswer: 'esto',
    },
    {
      question: '... mochilas son marrones.',
      options: ['estas', 'estos', 'aquellos'],
      correctAnswer: 'estas',
    },
  ];

  const defaultQuestions2 = [
    {
      question: 'Yo tengo 18 años, mi hermano tiene 23 años, él es ... que yo.',
      options: ['mayor', 'superior', 'menor'],
      correctAnswer: 'mayor',
    },
    {
      question:
        'Mi hija tiene 3 años, mi sobrina tiene 5 años. Mi hija es ... que mi sobrina.',
      options: ['mejor', 'peor', 'menor'],
      correctAnswer: 'menor',
    },
    {
      question:
        'Mi amiga estudia muy bien, pero estudio ... por eso tengo malas notas.',
      options: ['inferior', 'mejor', 'peor'],
      correctAnswer: 'peor',
    },
    {
      question:
        'No me gusta jugar al tenis con Pedro porque siempre me gana. Él es ... tenista que yo.',
      options: ['menor', 'mejor', 'mayor'],
      correctAnswer: 'mejor',
    },
    {
      question: 'María juega muy bien al fútbol, ella juega ... que su padre.',
      options: ['tan', 'igual', 'superior'],
      correctAnswer: 'igual',
    },
  ];

  // MoveWordToContainer

  const moveWords2 = [
    translate('lesson7.exercises.task7.word1'),
    translate('lesson7.exercises.task7.word2'),
    translate('lesson7.exercises.task7.word3'),
    translate('lesson7.exercises.task7.word4'),
    translate('lesson7.exercises.task7.word5'),
    translate('lesson7.exercises.task7.word6'),
  ];

  const moveWords3 = [
    translate('lesson7.exercises.task12.word1'),
    translate('lesson7.exercises.task12.word2'),
    translate('lesson7.exercises.task12.word3'),
    translate('lesson7.exercises.task12.word4'),
    translate('lesson7.exercises.task12.word5'),
    translate('lesson7.exercises.task12.word6'),
  ];

  // AddWordsToContainer
  const textInLeftContainer = 'yo';
  const textInRightContainer = 'tú';
  const textInCenterContainer = 'él, ella, usted';

  const textInLeftContainer2 = 'nosotros/as';
  const textInRightContainer2 = 'vosotros/as';
  const textInCenterContainer2 = 'ellos, ellas, ustedes';

  // MoveWordToContainer
  const moveWords = [
    'Yo',
    'Tú',
    'Él, ella, usted',
    'Nosotros, nosotras',
    'Vosotros, vosotras',
    'Ellos, ellas, ustedes',
  ];

  return (
    <div>
      <h1 className={commonStyles.lessonTitle}>
        {translate('exercises.title')}
      </h1>

      <h2 className={commonStyles.lessonTask}>
        1.1 Escriba los pronombres demostrativos en la forma correcta.
      </h2>
      <QuestionCards questions={defaultQuestions} number={1} />

      <h2 className={commonStyles.lessonTask}>
        1.2 {translate('exercises.task2')}
      </h2>
      <ExerciseCreateWord defaultWords={defaultWords} number={2} />

      <h2 className={commonStyles.lessonTask}>
        1.3 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWordInContainer1}
        correctWordInContainer2={correctWordInContainer2}
        correctWordInContainer3={correctWordInContainer3}
        correctWordInContainer4={correctWordInContainer4}
        correctWordInContainer5={correctWordInContainer5}
        correctWordInContainer6={correctWordInContainer6}
        moveWords={moveWords}
        number={3}
      />

      <h2 className={commonStyles.lessonTask}>
        1.4 {translate('lesson7.exercises.task4')}
      </h2>
      <WriteWordToText
        correctWords={correctWordsToText}
        words1={wordsToText1}
        words2={wordsToText2}
        number={4}
      />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords}
        images={images}
        number={5}
      />

      <h2 className={commonStyles.lessonTask}>
        1.6 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <SelectWordWithoutTranslation
        options1={options1}
        options2={options2}
        options3={options3}
        options4={options4}
        options5={options5}
        options6={options6}
        correctWord1={correctWord1}
        correctWord2={correctWord2}
        correctWord3={correctWord3}
        correctWord4={correctWord4}
        correctWord5={correctWord5}
        correctWord6={correctWord6}
        words={wordsSelectWord}
        number={6}
      />

      <h2 className={commonStyles.lessonTask}>
        1.7 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord2InContainer1}
        correctWordInContainer2={correctWord2InContainer2}
        correctWordInContainer3={correctWord2InContainer3}
        correctWordInContainer4={correctWord2InContainer4}
        correctWordInContainer5={correctWord2InContainer5}
        correctWordInContainer6={correctWord2InContainer6}
        moveWords={moveWords2}
        number={7}
      />

      <h2 className={commonStyles.lessonTask}>
        1.8 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWordsInContainerYo}
        correctWordsInContainer2={correctWordsInContainerTu}
        correctWordsInContainer3={correctWordsInContainerElla}
        textInLeftContainer={textInLeftContainer}
        textInCenterContainer={textInCenterContainer}
        textInRightContainer={textInRightContainer}
        number={8}
      />

      <h2 className={commonStyles.lessonTask}>
        1.9 {translate('exercises.task1')} Elija la opción correcta.
      </h2>
      <QuestionCards questions={defaultQuestions2} number={9} />

      <h2 className={commonStyles.lessonTask}>
        1.10 {translate('lesson2.exercises.task5')}
      </h2>
      <WriteWordToImage
        correctWords={correctWords2}
        images={images2}
        number={10}
      />

      <h2 className={commonStyles.lessonTask}>
        1.11 {translate('lesson7.exercises.task4')}
      </h2>
      <WriteWordToText
        correctWords={correctWordsToText2}
        words1={words2ToText1}
        words2={words2ToText2}
        number={11}
      />

      <h2 className={commonStyles.lessonTask}>
        1.12 {translate('exercises.task5')}
      </h2>
      <Move6WordsToContainer
        correctWordInContainer1={correctWord3InContainer1}
        correctWordInContainer2={correctWord3InContainer2}
        correctWordInContainer3={correctWord3InContainer3}
        correctWordInContainer4={correctWord3InContainer4}
        correctWordInContainer5={correctWord3InContainer5}
        correctWordInContainer6={correctWord3InContainer6}
        moveWords={moveWords3}
        number={12}
      />

      <h2 className={commonStyles.lessonTask}>
        1.13 {translate('lesson2.exercises.task3')} Escriba en plural.
      </h2>
      <WriteWord
        correctWords={correctWordsToWriteWord}
        words={words}
        number={13}
      />

      <h2 className={commonStyles.lessonTask}>
        1.14 {translate('exercises.task5')}
      </h2>
      <AddWordsTo3ContainersLibrary
        correctWordsInContainer1={correctWords2InContainer1}
        correctWordsInContainer2={correctWords2InContainer2}
        correctWordsInContainer3={correctWords2InContainer3}
        textInLeftContainer={textInLeftContainer2}
        textInCenterContainer={textInCenterContainer2}
        textInRightContainer={textInRightContainer2}
        number={14}
      />

      <h2 className={commonStyles.lessonTask}>
        1.15 {translate('lesson2.exercises.task7')}
      </h2>
      <SortableWords defaultWords={sortableWords} number={15} />

      <h2 className={commonStyles.lessonTask}>
        1.16 {translate('lesson7.exercises.task4')}
      </h2>

      <div className={commonStyles.textTitleWrap}>
        <p className={commonStyles.textTitle}>Un día en la vida.</p>
      </div>

      <WriteWordToText
        correctWords={correctWordsToText3}
        words1={words3ToText1}
        words2={words3ToText2}
        number={16}
      />

      {isTabletScreen && <ChangePageBtn />}
    </div>
  );
};

export default Exercises1Lesson7;
