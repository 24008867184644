import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import style from './AddWordsTo3Containers.module.css';
import { setTryStatus } from '../helpers/setTryStatus';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AddWordsTo3ContainersLibrary = ({
  correctWordsInContainer1,
  correctWordsInContainer2,
  correctWordsInContainer3,
  textInLeftContainer,
  textInCenterContainer,
  textInRightContainer,
  number,
}) => {
  const [containers, setContainers] = useState({
    left: [],
    right: [],
    center: [],
  });

  const [allWords, setAllWords] = useState(() =>
    correctWordsInContainer1
      .concat(correctWordsInContainer2)
      .concat(correctWordsInContainer3)
      .sort()
      .map((word, index) => ({
        id: index + 1,
        order: index + 1,
        text: word,
        draggable: true,
      }))
  );

  const correctWords = correctWordsInContainer1
    .concat(correctWordsInContainer2)
    .concat(correctWordsInContainer3)
    .sort()
    .map((word, index) => ({
      id: index + 1,
      order: index + 1,
      text: word,
    }));

  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);
  const [isLeftContainerCorrect, setIsLeftContainerCorrect] = useState(false);
  const [isRightContainerCorrect, setIsRightContainerCorrect] = useState(false);
  const [isCenterContainerCorrect, setIsCenterContainerCorrect] =
    useState(false);
  const [id, setId] = useState(1);

  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  // useRef for showCorrectAnswer
  const showCorrectAnswerRef = useRef(false);

  useEffect(() => {
    const userAnswer = user.exercises?.[module]?.[lesson]?.[number]?.containers;

    if (userAnswer) {
      setTryCounter(Object.values(userAnswer)[0].tryCounterData);
    }
  }, [lesson, module, number, user.exercises]);

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              containers: {
                ...(user.exercises[module]?.[lesson]?.[number]?.containers ||
                  {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.containers
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises]
  );

  const onDragEnd = result => {
    if (result.source.droppableId === 'start') {
      const containerName = result.destination.droppableId;

      // Проверяем, что контейнер назначения не является контейнером с доступными словами
      if (containerName !== 'start') {
        const element = allWords.find(
          (_, index) => index === result.source.index
        );

        setContainers(prevContainers => ({
          ...prevContainers,
          [containerName]: [...prevContainers[containerName], element],
        }));

        setAllWords(prevWords =>
          prevWords.filter((_, index) => index !== result.source.index)
        );
      }
    }

    if (result.source.droppableId !== 'start') {
      const from = result.source.droppableId;
      const to = result.destination.droppableId;

      if (to !== 'start') {
        if (from !== to) {
          const element = containers[from].find(
            (_, index) => index === result.source.index
          );

          setContainers(prevContainers => ({
            ...prevContainers,
            [from]: prevContainers[from].filter(
              (_, index) => index !== result.source.index
            ),
            [to]: [...prevContainers[to], element],
          }));
        }
      }
    }
  };

  const onCheckBtnClick = useCallback(() => {
    const allWordsInLeftContainer = containers.left.map(item => item.text);
    const allWordsInRightContainer = containers.right.map(item => item.text);
    const allWordsInCenterContainer = containers.center.map(item => item.text);

    const isLeftContainerCorrect = correctWordsInContainer1.every(word =>
      allWordsInLeftContainer.includes(word)
    );

    const isRightContainerCorrect = correctWordsInContainer2.every(word =>
      allWordsInRightContainer.includes(word)
    );

    const isCenterContainerCorrect = correctWordsInContainer3.every(word =>
      allWordsInCenterContainer.includes(word)
    );

    if (
      isLeftContainerCorrect &&
      isRightContainerCorrect &&
      isCenterContainerCorrect
    ) {
      setIsLeftContainerCorrect(true);
      setIsRightContainerCorrect(true);
      setIsCenterContainerCorrect(true);

      setTryCounter(setTryStatus(id, true));
      showCorrectAnswerRef.current = true;

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      setTryCounter(setTryStatus(id, false));
      setId(prevId => prevId + 1);
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  }, [
    containers.center,
    containers.left,
    containers.right,
    correctWordsInContainer1,
    correctWordsInContainer2,
    correctWordsInContainer3,
    dispatch,
    exerciseData,
    id,
    tryCounter,
    user.email,
  ]);

  //   e.preventDefault();
  // };

  // const touchEndHandler = (e, targetContainer) => {
  //   e.preventDefault();

  //   if (!showCorrectAnswerRef.current && currentCard) {
  //     const isCardInLeftContainer = containers.left.some(
  //       card => card.id === currentCard.id
  //     );

  //     const isCardInRightContainer = containers.right.some(
  //       card => card.id === currentCard.id
  //     );

  //     if (targetContainer === 'left' && !isCardInLeftContainer) {
  //       setContainers(prevContainers => ({
  //         left: [...prevContainers.left, currentCard],
  //         right: prevContainers.right.filter(
  //           card => card.id !== currentCard.id
  //         ),
  //       }));
  //     } else if (targetContainer === 'right' && !isCardInRightContainer) {
  //       setContainers(prevContainers => ({
  //         left: prevContainers.left.filter(card => card.id !== currentCard.id),
  //         right: [...prevContainers.right, currentCard],
  //       }));
  //     }
  //   }

  //   setCurrentCard(null);
  // };

  useEffect(() => {
    const showAnswer = () =>
      tryCounter.some(item => item.tryStatus === true) ||
      tryCounter.every(item => item.tryStatus === false);

    const checkAnswer = showAnswer();

    if (checkAnswer && !showCorrectAnswerRef.current) {
      // const correctLeftWords = allWords.filter(word =>
      //   correctWordsInContainer1.includes(word.text)
      // );
      // const correctRightWords = allWords.filter(word =>
      //   correctWordsInContainer2.includes(word.text)
      // );
      // const correctCenterWords = allWords.filter(word =>
      //   correctWordsInContainer3.includes(word.text)
      // );

      const correctLeftWords = correctWords.filter(word =>
        correctWordsInContainer1.includes(word.text)
      );
      const correctRightWords = correctWords.filter(word =>
        correctWordsInContainer2.includes(word.text)
      );
      const correctCenterWords = correctWords.filter(word =>
        correctWordsInContainer3.includes(word.text)
      );

      setContainers({
        left: correctLeftWords,
        right: correctRightWords,
        center: correctCenterWords,
      });

      setIsLeftContainerCorrect(true);
      setIsRightContainerCorrect(true);
      setIsCenterContainerCorrect(true);
      setAllWords([]);

      // Use the ref to update showCorrectAnswer
      showCorrectAnswerRef.current = true;
    }
  }, [
    allWords,
    correctWords,
    correctWordsInContainer1,
    correctWordsInContainer2,
    correctWordsInContainer3,
    tryCounter,
  ]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <div className={style.addWordsToContainer}>
          <Droppable droppableId="start">
            {provided => (
              <div
                className={style.availableWords}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {allWords.map((card, index) => (
                  <Draggable
                    key={card.id}
                    index={index}
                    draggableId={String(card.id)}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={style.card}
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {card.text}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>

          <div className={style.container}>
            <div className={style.leftContainerWrap}>
              <div>
                <p className={style.text}>{textInLeftContainer}</p>
              </div>
              <Droppable droppableId="left">
                {provided => (
                  <div
                    className={`${style.leftContainer} ${style.cardWrap}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {containers.left.map((card, index) => (
                      <Draggable
                        key={card.id}
                        index={index}
                        draggableId={String(card.id)}
                        isDragDisabled={
                          isLeftContainerCorrect &&
                          isRightContainerCorrect &&
                          isCenterContainerCorrect
                            ? true
                            : false
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={card.id}
                            className={`${style.card} ${
                              showCorrectAnswerRef.current
                                ? style.correct
                                : style.card
                            }`}
                          >
                            {card.text}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>

            <div className={style.leftContainerWrap}>
              <p className={style.text}>{textInRightContainer}</p>

              <Droppable droppableId="right">
                {provided => (
                  <div
                    className={`${style.rightContainer} ${style.cardWrap}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {containers.right.map((card, index) => (
                      <Draggable
                        key={card.id}
                        index={index}
                        draggableId={String(card.id)}
                        isDragDisabled={
                          isLeftContainerCorrect &&
                          isRightContainerCorrect &&
                          isCenterContainerCorrect
                            ? true
                            : false
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={card.id}
                            className={`${style.card} ${
                              showCorrectAnswerRef.current
                                ? style.correct
                                : style.card
                            }`}
                          >
                            {card.text}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>

            <div className={style.leftContainerWrap}>
              <p className={style.text}>{textInCenterContainer}</p>
              <Droppable droppableId="center">
                {provided => (
                  <div
                    className={`${style.rightContainer} ${style.cardWrap}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {' '}
                    {containers.center.map((card, index) => (
                      <Draggable
                        key={card.id}
                        index={index}
                        draggableId={String(card.id)}
                        isDragDisabled={
                          isLeftContainerCorrect &&
                          isRightContainerCorrect &&
                          isCenterContainerCorrect
                            ? true
                            : false
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={card.id}
                            className={`${style.card} ${
                              showCorrectAnswerRef.current
                                ? style.correct
                                : style.card
                            }`}
                          >
                            {card.text}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          </div>

          <CheckAnswerBtn
            tryCounter={tryCounter}
            onCheckBtnClick={onCheckBtnClick}
            disabled={isLoading}
          />
        </div>
      </div>
    </DragDropContext>
  );
};

export default AddWordsTo3ContainersLibrary;
