import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPassword,
  forgetPassword,
  login,
  logout,
  refresh,
} from '../../api/auth';
import {
  updateAvatar,
  updateFavorites,
  updatePassword,
  updateProfile,
  userAddWord,
  userDeleteWord,
  userLearnedWord,
} from '../../api/profile';

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (body, { rejectWithValue }) => {
    try {
      const data = await login(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const forgetPasswordThunk = createAsyncThunk(
  'auth/forget-password',
  async (body, { rejectWithValue }) => {
    try {
      const data = await forgetPassword(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createNewPasswordThunk = createAsyncThunk(
  'auth/restore-password',
  async (body, { rejectWithValue }) => {
    try {
      const data = await createPassword(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const refreshThunk = createAsyncThunk(
  'auth/refresh',
  async (token, { rejectWithValue }) => {
    try {
      const data = await refresh(token);
      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const logoutThunk = createAsyncThunk(
  'auth/logout',
  async (userId, { rejectWithValue }) => {
    try {
      const data = await logout(userId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateProfileThunk = createAsyncThunk(
  'profile/update',
  async (body, { rejectWithValue }) => {
    try {
      const data = await updateProfile(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateFavoritesThunk = createAsyncThunk(
  'profile/updateFavoritePosts',
  async (postId, { rejectWithValue }) => {
    try {
      /* eslint-disable no-unused-vars */
      const data = await updateFavorites(postId);
      return postId;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateAvatarThunk = createAsyncThunk(
  'profile/avatar',
  async (body, { rejectWithValue }) => {
    try {
      const data = await updateAvatar(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updatePasswordThunk = createAsyncThunk(
  'profile/updatePassword',
  async (body, { rejectWithValue }) => {
    try {
      const data = await updatePassword(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const userToggleWordThunk = createAsyncThunk(
  'profile/userAddWord',
  async ({ id, remove, toggle }, { rejectWithValue, getState }) => {
    try {
      const data = remove
        ? await userDeleteWord(id)
        : toggle
        ? await userLearnedWord(id)
        : await userAddWord(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
