import { Button, ButtonGroup, Stack, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userToggleWordThunk } from '../../../../store/auth/thunks';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { theme } from '../../../../theme/theme';
import { wordsSelector } from '../../../../store/dictionary/selectors';
import { getAllWordsThunk } from '../../../../store/dictionary/thunk';
import useLocales from '../../../../hooks/useLocales';
import { getAudio } from 'api/textToSpeech';
import commonStyles from '../../LessonsPage.module.css';

const Vocabulary100 = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { words } = useSelector(wordsSelector);

  const { currentLang } = useLocales();

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    dispatch(getAllWordsThunk());
  }, [dispatch]);

  const handleClick = id => {
    dispatch(userToggleWordThunk({ id }));
  };

  const filteredWords = words.filter(word => word.lesson === 'nùmeros 10-100');

  return (
    <div>
      <Stack direction="column" spacing={2}>
        {filteredWords.map(word => (
          <Stack direction="row" spacing={1} alignItems="center" key={word._id}>
            <ButtonGroup variant="text" aria-label="text button group">
              <Button
                onClick={() => handleClick(word._id)}
                disabled={
                  user.dictionary.isOnLearning.find(
                    item => item._id === word._id
                  ) ||
                  user.dictionary.isLearned.find(item => item._id === word._id)
                }
              >
                {user.dictionary.isOnLearning.find(
                  item => item._id === word._id
                ) ||
                user.dictionary.isLearned.find(
                  item => item._id === word._id
                ) ? (
                  <LibraryAddCheckIcon
                    sx={{ color: theme.palette.primary.blue }}
                  />
                ) : (
                  <LibraryAddIcon sx={{ color: theme.palette.primary.blue }} />
                )}
              </Button>
              <Button onClick={async () => await getAudio({ text: word.word })}>
                <VolumeUpIcon sx={{ color: theme.palette.primary.blue }} />
              </Button>
            </ButtonGroup>

            {isMobileScreen && (
              <Stack>
                <p className={commonStyles.spanishWord}>{word.word}</p>
                <p className={commonStyles.spanishWordTranslation}>
                  {word[`translation_${currentLang.value}`]}
                </p>
              </Stack>
            )}
            {isTabletScreen && (
              <>
                <p className={commonStyles.spanishWord}>{word.word}</p>
                <p className={commonStyles.spanishWordTranslation}>
                  - {word[`translation_${currentLang.value}`]}
                </p>
              </>
            )}
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default Vocabulary100;
