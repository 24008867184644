import React from 'react';
import style from './boxWithWord.module.css';

export const BoxWithWord = ({
  index,
  item,
  onSelectedWordClick,
  isSelected,
}) => {
  return (
    <div
      index={index}
      key={item.id}
      draggable={item.draggable}
      onClick={() => onSelectedWordClick(item)}
      className={`${style.box} ${isSelected ? style.selected : ''} ${
        !item.clickable ? style.box : ''
      }`}
      style={{ order: item.order }}
    >
      {item.letterForAnswer}
    </div>
  );
};
