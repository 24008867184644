import React from 'react';
import BlogList from './BlogList';
import { Box, CssBaseline } from '@mui/material';

const BlogPage = () => {
  return (
    <Box
      sx={{
        padding: {
          mobile: '90px 10px 80px 10px',
          iphone: '90px 16px 80px 16px',
          tablet: '100px 16px 80px 16px',

          laptop: '110px 50px 200px 50px',
        },
        backgroundColor: '#e0e3e8',
      }}
    >
      <CssBaseline />
      <BlogList />
    </Box>
  );
};

export default BlogPage;
