import { useMediaQuery } from '@react-hook/media-query';
import logo from '../../../assets/images/logo-home-card.png';

const Logo = () => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const isMediumScreen = useMediaQuery('(min-width: 768px)');
  return (
    <>
      {isSmallScreen && (
        <img
          alt="123"
          src={logo}
          width={52}
          height={52}
          //   style={{ marginRight: "90px" }}
        />
      )}
      {isMediumScreen && (
        <img
          alt="123"
          // src="assets/images/logo-home-card.png"
          src={logo}
          width={70}
          height={70}
        />
      )}
    </>
  );
};

export default Logo;
