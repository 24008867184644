import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalApproveAction from '../../Modals/ModalApproveAction/ModalApproveAction';
import { useBurgerContext } from '../../../context/BurgerProvider';
import sprite from '../../../assets/svg/sprite-cards.svg';
import { logoutThunk } from '../../../store/auth/thunks';
import {
  Box,
  Button,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';
import LogoutIcon from '@mui/icons-material/Logout';
import useLocales from '../../../hooks/useLocales';

const LogoutButton = styled(Button)({
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.yellow,
  },
});

const Logout = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { menuOpen, setMenuOpen } = useBurgerContext();
  const { user } = useSelector(state => state.auth);

  const { translate } = useLocales();

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery(
    '(min-width: 768px) and (max-width: 1279px)'
  );
  const isDesktopScreen = useMediaQuery('(min-width: 1280px)');

  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const logout = () => {
    dispatch(logoutThunk(user._id));
    setIsOpenModal(false);
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  const handleClick = () => {
    setIsOpenModal(true);
  };

  return (
    <>
      {isMobileScreen && (
        <Box
          sx={{
            display: { mobile: 'flex', tablet: 'flex' },
            justifyContent: { mobile: 'center' },
            alignItems: { mobile: 'center' },
          }}
        >
          <LogoutButton
            key="Logout"
            onClick={handleClick}
            sx={{
              display: 'flex',
              gap: '6px',
              position: 'fixed',
              bottom: '14px',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: 1.75,
              letterSpacing: '0.02857em',
              border: '1px solid #364f6a',
              width: { mobile: '300px', iphone: '360px' },
              backgroundColor: '#364f6a',
              color: '#90ADC6',
              padding: '8px',
            }}
          >
            {translate('header.logout')}
            <LogoutIcon sx={{ width: '22px', height: '22px' }} />
          </LogoutButton>
        </Box>
      )}
      {isTabletScreen && (
        <MenuItem key="Logout" onClick={handleClick}>
          <Typography textAlign="center">
            {translate('header.logout')}
          </Typography>
        </MenuItem>
      )}
      {isDesktopScreen && (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <LogoutButton
            key="Logout"
            onClick={handleClick}
            sx={{
              // my: 2,
              color: 'white',
              display: 'flex',
              gap: '6px',
            }}
          >
            {translate('header.logout')}
            <LogoutIcon />
          </LogoutButton>
        </Box>
      )}

      {isOpenModal && (
        <ModalApproveAction
          handleSuccess={logout}
          handleCancel={handleCancel}
          successButtonText={
            <>
              <p> {translate('logoutModal.yesBtn')}</p>
              <svg width="24" height="24">
                <use href={`${sprite}#icon-logout-white`} />
              </svg>
            </>
          }
        >
          <div>
            <p> {translate('logoutModal.text')}</p>
          </div>
        </ModalApproveAction>
      )}
    </>
  );
};

export default Logout;
