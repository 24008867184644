import { instance } from './api';

export const updateProfile = async body => {
  const { data } = await instance.put(`profiles/updateProfile`, body);
  return data;
};

export const updateFavorites = async postId => {
  const { data } = await instance.patch(`posts/${postId}/favorite`);
  return data;
};

export const userAddWord = async wordId => {
  const { data } = await instance.post(
    `profiles/dictionary/learning/${wordId}`
  );
  return data;
};

export const userDeleteWord = async wordId => {
  const { data } = await instance.delete(
    `profiles/dictionary/learned/${wordId}`
  );
  return data;
};

export const userLearnedWord = async wordId => {
  const { data } = await instance.post(`profiles/dictionary/learned/${wordId}`);
  return data;
};

export const updateAvatar = async body => {
  const { data } = await instance.put('profiles/avatar', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

export const updatePassword = async body => {
  const { data } = await instance.post(`auth/changePassword`, body);
  return data;
};
