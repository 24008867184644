import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { refreshThunk } from 'store/auth/thunks';

const GuardsAuth = ({ children }) => {
  const { token, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const isLogin = Boolean(Object.keys(user).length);

  useEffect(() => {
    if (!isLogin && token) {
      dispatch(refreshThunk(token));
    }
  }, [dispatch, isLogin, token]);

  if (!isLogin && token) return <Loader />;

  return token ? children : <Navigate to="/login" />;
};

export default GuardsAuth;
