import { combineReducers } from "@reduxjs/toolkit";
import { postsReducer } from "./posts/slice";
import { authReducer } from "./auth/slice";
import { wordsReducer } from "./dictionary/slice";

export const reducer = combineReducers({
  posts: postsReducer,
  auth: authReducer,
  words: wordsReducer,
});
