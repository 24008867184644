import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import { useBurgerContext } from '../../../context/BurgerProvider';
import Nav from '../Navigation/Nav/Nav';
import UserNav from '../Navigation/UserNav/UserNav';
import Logout from '../Logout/Logout';
import sprite from '../../../assets/svg/sprite-cards.svg';
import css from './BurgerMenu.module.css';
// import clouds from '../../../assets/images/profile-background.png';

const BurgerMenu = () => {
  const { menuOpen, setMenuOpen } = useBurgerContext();
  // const { user } = useSelector((state) => state.auth);

  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const isMediumScreen = useMediaQuery(
    '(min-width: 768px) and (max-width: 1279px)'
  );

  const openBurgerMenu = event => {
    setMenuOpen(true);
  };

  const closeBurgerMenu = event => {
    setMenuOpen(false);
  };

  return (
    <>
      {menuOpen ? (
        <>
          <button
            type="button"
            onClick={closeBurgerMenu}
            className={css.burgerMenuBtn}
          >
            <svg width="28" height="28">
              <use href={`${sprite}#icon-menu-hamburger-cross`}></use>
            </svg>
          </button>

          <div className={css.burgerOpenNavigation}>
            {isSmallScreen && (
              <>
                <UserNav closeBurgerMenu={closeBurgerMenu} />
                <Nav />
                <Logout />
                {/* <img src={clouds} alt="clouds" width="200px" /> */}
              </>
            )}

            {/* {isSmallScreen && (
              <div className={css.userNav}>
                <NavLink to="/user">
                  <UserBtn closeBurgerMenu={closeBurgerMenu} />
                </NavLink>
                <p>{user.email}</p>
              </div>
            )} */}

            {/* <Nav closeBurgerMenu={closeBurgerMenu} /> */}

            {/* {isSmallScreen && <Logout closeBurgerMenu={closeBurgerMenu} />} */}
          </div>
        </>
      ) : (
        <>
          {isSmallScreen && <NavLink to="/user">{/* <UserBtn /> */}</NavLink>}

          {/* {isMediumScreen && <AuthNav />} */}
          {isMediumScreen && (
            <div className={css.userNav}>
              <UserNav />
            </div>
          )}

          <div className={css.burgerHeader}>
            <button
              type="button"
              className={css.burgerMenuBtn}
              onClick={openBurgerMenu}
            >
              <svg width="28" height="28">
                <use href={`${sprite}#icon-menu-hamburger-yellow`}></use>
              </svg>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default BurgerMenu;
