import { IconButton, Stack, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { LESSON } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileThunk } from '../../../store/auth/thunks';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CustomChangePageButton } from 'customized/customized';
import useLocales from 'hooks/useLocales';
import { useMemo } from 'react';

const ChangePageBtn = () => {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { module, lesson } = useParams();
  const moduleNumber = Number(module.slice(6));
  const lessonNumber = Number(lesson);

  const previousModule =
    lessonNumber - 1
      ? moduleNumber
      : moduleNumber !== 0
      ? moduleNumber - 1
      : moduleNumber;

  const isLastModule = useMemo(
    () => moduleNumber === Object.keys(LESSON).length,
    [moduleNumber]
  );
  const isLastLesson = lessonNumber === LESSON[moduleNumber].length;
  const isShowPreviousBtn =
    (moduleNumber > 1 && lessonNumber >= 1) ||
    (moduleNumber >= 1 && lessonNumber > 1);

  const baseRoute = `/lessons/lesson`;

  const nextRoute = `${baseRoute}${
    isLastLesson ? moduleNumber + 1 : moduleNumber
  }/${isLastLesson ? 1 : lessonNumber + 1}`;

  const previousRoute =
    moduleNumber === 1 && !(lessonNumber - 1)
      ? `${baseRoute}${moduleNumber}/1`
      : `${baseRoute}${lessonNumber - 1 ? moduleNumber : previousModule}/${
          lessonNumber - 1 ? lessonNumber - 1 : LESSON[moduleNumber - 1].length
        }`;

  const handlePrevious = () => {
    navigate(previousRoute);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    const lessonPage = nextRoute.slice(15);
    const userLesson = Number(
      user.lessonPage.slice(user.lessonPage.indexOf('/') + 1)
    );
    const userModule = Number.parseInt(user.lessonPage);

    if (userLesson <= lessonNumber && userModule <= moduleNumber) {
      dispatch(
        updateProfileThunk({
          email: user.email,
          lessonPage,
        })
      )
        .unwrap()
        .then(() => {
          navigate(nextRoute);
          window.scrollTo(0, 0);
        });
    } else {
      navigate(nextRoute);
      window.scrollTo(0, 0);
    }
  };

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isTabletScreen = useMediaQuery('(min-width: 768px)');

  return (
    <>
      {isMobileScreen && (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {isShowPreviousBtn ? (
            <IconButton
              onClick={handlePrevious}
              sx={{
                padding: { mobile: '2px 0px 2px 2px' },
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
          ) : (
            <IconButton disabled>
              <NavigateBeforeIcon />
            </IconButton>
          )}
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: { mobile: '90px' },
              padding: { mobile: '4px' },

              height: { mobile: '36px' },
              backgroundColor: { mobile: 'white' },
              borderRadius: '4px',
              color: { mobile: '#364f6a' },
              gap: { mobile: '16px' },
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',

                color: { mobile: '#364f6a' },
                gap: { mobile: '6px' },
              }}
            >
              {lessonNumber}
              <span> {translate('footer.from')}</span>
              {LESSON[moduleNumber].length}
            </Stack>
          </Stack>
          {!(isLastModule && isLastLesson) && (
            <IconButton
              onClick={handleNext}
              sx={{
                padding: { mobile: '2px 0px 2px 2px' },
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          )}
        </Stack>
      )}

      {isTabletScreen && (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '50px',
            width: '100%',
          }}
        >
          {isShowPreviousBtn ? (
            <CustomChangePageButton onClick={handlePrevious}>
              {translate('pageChangeBtnPrev')}
            </CustomChangePageButton>
          ) : (
            <CustomChangePageButton disabled>
              {translate('pageChangeBtnPrev')}
            </CustomChangePageButton>
          )}
          {!(isLastModule && isLastLesson) && (
            <CustomChangePageButton onClick={handleNext}>
              {translate('pageChangeBtnNext')}
            </CustomChangePageButton>
          )}
        </Stack>
      )}
    </>
  );
};

export default ChangePageBtn;
