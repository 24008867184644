/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import css from "./Nav.module.css";
import { useSelector } from 'react-redux';
import useLocales from '../../../../hooks/useLocales';
import styled from '@emotion/styled';
import { Box, Button, Stack } from '@mui/material';
import { theme } from '../../../../theme/theme';
import { useMediaQuery } from '@react-hook/media-query';
import Logo from '../../Logo/Logo';
import { useBurgerContext } from '../../../../context/BurgerProvider';
// import { LESSON } from 'utils/constants';

const NavButton = styled(Button)({
  '&:hover': {
    backgroundColor: theme.palette.main,
    color: theme.palette.primary.yellow,
  },
});

const Nav = ({ closeBurgerMenu }) => {
  const [_anchorElNav, setAnchorElNav] = React.useState(null);
  const { _menuOpen, setMenuOpen } = useBurgerContext();

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { translate } = useLocales();

  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const isMediumScreen = useMediaQuery('(min-width: 768px)');

  const pagesBurgerMenu = [
    { title: translate('header.home'), path: 'home' },
    {
      title: translate('header.lessons'),
      path: `lessons/lesson${user.lessonPage}`,
    },

    { title: translate('header.blog'), path: 'blog' },
    { title: translate('header.dictionary'), path: 'dictionary' },
  ];

  const pages = [
    { title: translate('header.home'), path: 'home' },
    {
      title: translate('header.lessons'),
      path: `lessons/lesson${user.lessonPage}`,
    },
    { title: translate('header.blog'), path: 'blog' },
    { title: translate('header.dictionary'), path: 'dictionary' },
  ];

  const adminPages = {
    title: translate('header.settings'),
    path: 'settings',
  };

  const handleCloseNavMenu = path => {
    setAnchorElNav(null);
    navigate(path === 'home' ? '/' : `/${path}`);
  };

  const handleCloseBurgerMenu = path => {
    setAnchorElNav(null);
    navigate(path === 'home' ? '/' : `/${path}`);
    setMenuOpen(false);
  };

  return (
    <>
      {isSmallScreen && (
        // <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pt: '60px',
            pb: '198px',
            // pt: '78px',
            // pb: '160px',
            gap: '10px',
          }}
        >
          {pagesBurgerMenu.map(page => (
            <NavButton
              key={page.path}
              onClick={() => handleCloseBurgerMenu(page.path)}
              sx={{
                my: 0,
                color: '#364f6a',
                display: 'block',
                fontWeight: '500',
                fontSize: '22px',
                // lineHeight: 1.75,
                // letterSpacing: '0.02857em',
              }}
            >
              {page.title}
            </NavButton>
          ))}
          {user.role === 'Admin' && (
            <NavButton
              key={adminPages.path}
              onClick={() => handleCloseBurgerMenu(adminPages.path)}
              sx={{
                my: 0,
                color: '#364f6a',
                display: 'block',
                fontWeight: '500',
                fontSize: '22px',
              }}
            >
              {adminPages.title}
            </NavButton>
          )}
        </Stack>
        // </Box>
      )}
      {isMediumScreen && (
        <Box sx={{ flexGrow: 1, display: { mobile: 'flex', tablet: 'flex' } }}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mr: '38px',
              pt: '4px',
              pb: '5px',
            }}
          >
            <Logo />
          </Stack>

          {pages.map(page => (
            <NavButton
              key={page.path}
              onClick={() => handleCloseNavMenu(page.path)}
              sx={{
                my: 2,
                color: 'white',
                display: 'block',
              }}
            >
              {page.title}
            </NavButton>
          ))}
          {user.role === 'Admin' && (
            <NavButton
              key={adminPages.path}
              onClick={() => handleCloseNavMenu(adminPages.path)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {adminPages.title}
            </NavButton>
          )}
        </Box>
      )}
    </>
  );
};

export default Nav;
