import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './ChooseLetter.module.css';
import { setTryStatus } from '../helpers/setTryStatus';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const ChooseLetters = ({
  correctLetterInContainer1,
  correctLetterInContainer2,
  correctLetterInContainer3,
  correctLetterInContainer4,
  moveWords,
  number,
}) => {
  const [allWords] = useState(() =>
    correctLetterInContainer2
      .concat(correctLetterInContainer4)
      .concat(correctLetterInContainer1)
      .concat(correctLetterInContainer3)
      .map((word, index) => {
        return {
          id: index + 1,
          order: index + 1,
          text: word,
        };
      })
  );

  const [firstContainer, setFirstContainer] = useState([]);
  const [secondContainer, setSecondContainer] = useState([]);
  const [thirdContainer, setThirdContainer] = useState([]);
  const [fourthContainer, setFourthContainer] = useState([]);

  // const [currentCard, setCurrentCard] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);

  const [targetContainer, setTargetContainer] = useState(null);

  const [checkAnswerDone, setCheckAnswerDone] = useState(false);

  const [, setIsFirstContainerCorrect] = useState(false);
  const [, setIsSecondContainerCorrect] = useState(false);
  const [, setIsThirdContainerCorrect] = useState(false);
  const [, setIsFourthContainerCorrect] = useState(false);

  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [id, setId] = useState(1);

  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const isAnswer = user.exercises?.[module]?.[lesson]?.[number]?.containers;

    if (isAnswer) {
      setTryCounter(Object.values(isAnswer)[0].tryCounterData);
    }
  }, [lesson, module, number, tryCounter, user.exercises]);

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              containers: {
                ...(user.exercises[module]?.[lesson]?.[number]?.containers ||
                  {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.containers
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises]
  );

  const onCheckBtnClick = () => {
    const allWordsInFirstContainer = firstContainer.map(item => item.text);
    const allWordsInSecondContainer = secondContainer.map(item => item.text);
    const allWordsInThirdContainer = thirdContainer.map(item => item.text);
    const allWordsInFourthContainer = fourthContainer.map(item => item.text);

    const isFirstContainerCorrect = correctLetterInContainer1.every(word =>
      allWordsInFirstContainer.includes(word)
    );

    const isSecondContainerCorrect = correctLetterInContainer2.every(word =>
      allWordsInSecondContainer.includes(word)
    );

    const isThirdContainerCorrect = correctLetterInContainer3.every(word =>
      allWordsInThirdContainer.includes(word)
    );

    const isFourthContainerCorrect = correctLetterInContainer4.every(word =>
      allWordsInFourthContainer.includes(word)
    );

    if (
      isFirstContainerCorrect &&
      isSecondContainerCorrect &&
      isThirdContainerCorrect &&
      isFourthContainerCorrect
    ) {
      setIsFirstContainerCorrect(true);
      setIsSecondContainerCorrect(true);
      setIsThirdContainerCorrect(true);
      setIsFourthContainerCorrect(true);

      setTryCounter(setTryStatus(id, true));
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);
      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  const handleWordClick = word => {
    if (selectedWord && selectedWord.id === word.id) {
      // Если выбранная фраза уже выбрана снова, сбрасываем выбор
      setSelectedWord(null);
      setTargetContainer(null);
    } else if (selectedWord && targetContainer === null) {
      // Если выбранная фраза уже находится в контейнере, удаляем её из контейнера и помещаем обратно в список
      if (firstContainer.some(card => card.id === selectedWord.id)) {
        setFirstContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      } else if (secondContainer.some(card => card.id === selectedWord.id)) {
        setSecondContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      } else if (thirdContainer.some(card => card.id === selectedWord.id)) {
        setThirdContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      } else if (fourthContainer.some(card => card.id === selectedWord.id)) {
        setFourthContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      }

      setSelectedWord(word);
    } else {
      setSelectedWord(word);
    }
  };

  const handleContainerClick = (card, targetContainer) => {
    if (!showCorrectAnswer && selectedWord) {
      const isCardInFirstContainer = firstContainer.some(
        card => card.id === selectedWord.id
      );

      const isCardInSecondContainer = secondContainer.some(
        card => card.id === selectedWord.id
      );

      const isCardInThirdContainer = thirdContainer.some(
        card => card.id === selectedWord.id
      );

      const isCardInFourthContainer = fourthContainer.some(
        card => card.id === selectedWord.id
      );

      if (targetContainer === 'first') {
        setFirstContainer([selectedWord]);
        if (isCardInSecondContainer) {
          setSecondContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInThirdContainer) {
          setThirdContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInFourthContainer) {
          setFourthContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      } else if (targetContainer === 'second') {
        setSecondContainer([selectedWord]);
        if (isCardInFirstContainer) {
          setFirstContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInThirdContainer) {
          setThirdContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInFourthContainer) {
          setFourthContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      } else if (targetContainer === 'third') {
        setThirdContainer([selectedWord]);
        if (isCardInFirstContainer) {
          setFirstContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInSecondContainer) {
          setSecondContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInFourthContainer) {
          setFourthContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      } else if (targetContainer === 'fourth') {
        setFourthContainer([selectedWord]);
        if (isCardInFirstContainer) {
          setFirstContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInSecondContainer) {
          setSecondContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInThirdContainer) {
          setThirdContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      }
    } else {
      setSelectedWord(card[0]);

      if (selectedWord.id && card.id) {
        const isCardInFirstContainer = firstContainer.some(
          card => card.id === selectedWord.id
        );

        const isCardInSecondContainer = secondContainer.some(
          card => card.id === selectedWord.id
        );

        const isCardInThirdContainer = thirdContainer.some(
          card => card.id === selectedWord.id
        );

        const isCardInFourthContainer = fourthContainer.some(
          card => card.id === selectedWord.id
        );
        if (targetContainer === 'first') {
          setFirstContainer([selectedWord]);
          if (isCardInSecondContainer) {
            setSecondContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInThirdContainer) {
            setThirdContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInFourthContainer) {
            setFourthContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        } else if (targetContainer === 'second') {
          setSecondContainer([selectedWord]);
          if (isCardInFirstContainer) {
            setFirstContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInThirdContainer) {
            setThirdContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInFourthContainer) {
            setFourthContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        } else if (targetContainer === 'third') {
          setThirdContainer([selectedWord]);
          if (isCardInFirstContainer) {
            setFirstContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInSecondContainer) {
            setSecondContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInFourthContainer) {
            setFourthContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        } else if (targetContainer === 'fourth') {
          setFourthContainer([selectedWord]);
          if (isCardInFirstContainer) {
            setFirstContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInSecondContainer) {
            setSecondContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInThirdContainer) {
            setThirdContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        }
      }
    }
    setSelectedWord(null);
  };

  useEffect(() => {
    if (!checkAnswerDone) {
      const showAnswer = () =>
        tryCounter.some(item => item.tryStatus === true) ||
        tryCounter.every(item => item.tryStatus === false);

      const checkAnswer = showAnswer();
      if (checkAnswer) {
        const correctFirstWord = allWords.filter(
          word => word.text === correctLetterInContainer1[0]
        );
        const correctSecondWord = allWords.filter(
          word => word.text === correctLetterInContainer2[0]
        );
        const correctThirdWord = allWords.filter(
          word => word.text === correctLetterInContainer3[0]
        );
        const correctFourthWord = allWords.filter(
          word => word.text === correctLetterInContainer4[0]
        );

        setFirstContainer(correctFirstWord);
        setSecondContainer(correctSecondWord);
        setThirdContainer(correctThirdWord);
        setFourthContainer(correctFourthWord);

        setIsFirstContainerCorrect(true);
        setIsSecondContainerCorrect(true);
        setIsThirdContainerCorrect(true);
        setIsFourthContainerCorrect(true);

        setShowCorrectAnswer(true);
        setCheckAnswerDone(true);
      }
    }
  }, [
    allWords,
    tryCounter,
    checkAnswerDone,
    correctLetterInContainer1,
    correctLetterInContainer2,
    correctLetterInContainer3,
    correctLetterInContainer4,
  ]);

  return (
    <div>
      <div className={style.addWordsToContainer}>
        <div className={style.availableWords}>
          {allWords
            .filter(
              word =>
                !firstContainer.some(card => card.id === word.id) &&
                !secondContainer.some(card => card.id === word.id) &&
                !thirdContainer.some(card => card.id === word.id) &&
                !fourthContainer.some(card => card.id === word.id)
            )
            .map(card => (
              <div
                key={card.id}
                className={`${style.card} ${
                  selectedWord && selectedWord.id === card.id
                    ? style.selected // Применяем стиль, если фраза выбрана
                    : ''
                }`}
                onClick={() => handleWordClick(card)}
              >
                {card.text}
              </div>
            ))}
        </div>

        <div className={style.container}>
          <div className={style.wordContainerWrap}>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[0]}</p>
            </div>
            <div
              className={`${style.wordContainer} ${
                showCorrectAnswer ? style.correct : style.wordContainer
              } `}
              onClick={() => handleContainerClick(firstContainer, 'first')}
            >
              {firstContainer.map(card => (
                <div key={card.id} className={style.cardInContainer}>
                  {card.text}
                </div>
              ))}
            </div>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[1]}</p>
            </div>
          </div>

          <div className={style.wordContainerWrap}>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[2]}</p>
            </div>
            <div
              className={`${style.wordContainer} ${
                showCorrectAnswer ? style.correct : style.wordContainer
              }`}
              onClick={() => handleContainerClick(secondContainer, 'second')}
            >
              {secondContainer.map(card => (
                <div key={card.id} className={style.cardInContainer}>
                  {card.text}
                </div>
              ))}
            </div>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[3]}</p>
            </div>
          </div>

          <div className={style.wordContainerWrap}>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[4]}</p>
            </div>
            <div
              className={`${style.wordContainer} ${
                showCorrectAnswer ? style.correct : style.wordContainer
              }`}
              onClick={() => handleContainerClick(thirdContainer, 'third')}
            >
              {thirdContainer.map(card => (
                <div key={card.id} className={style.cardInContainer}>
                  {card.text}
                </div>
              ))}
            </div>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[5]}</p>
            </div>
          </div>

          <div className={style.wordContainerWrap}>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[6]}</p>
            </div>
            <div
              className={`${style.wordContainer} ${
                showCorrectAnswer ? style.correct : style.wordContainer
              }`}
              onClick={() => handleContainerClick(fourthContainer, 'fourth')}
            >
              {fourthContainer.map(card => (
                <div key={card.id} className={style.cardInContainer}>
                  {card.text}
                </div>
              ))}
            </div>
            <div className={style.textWrap}>
              <p className={style.text}>{moveWords[7]}</p>
            </div>
          </div>
        </div>
        <CheckAnswerBtn
          tryCounter={tryCounter}
          onCheckBtnClick={onCheckBtnClick}
        />
      </div>
    </div>
  );
};

export default ChooseLetters;
