import React from "react";
import HomeCard from "../components/HomeCard/HomeCard";
import { Box, CssBaseline, Stack } from "@mui/material";

const HomePage = () => {
  return (
    <Box
      sx={{
        pt: 8,
        backgroundColor: "#e0e3e8",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <Stack sx={{ padding: { tablet: "32px" } }}>
        <HomeCard />
      </Stack>
    </Box>
  );
};

export default HomePage;
