import React from 'react';
import Lesson1Alphabet from '../Lesson1Alphabet';
import Lesson1NewWords from '../Lesson1NewWords/Lesson1NewWords';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { theme } from '../../../../../theme/theme';
import Lesson1NW2 from '../Lesson1NewWords/Lesson1NW2';
import Lesson1NW3 from '../Lesson1NewWords/Lesson1NW3';
import Lesson1NW4 from '../Lesson1NewWords/Lesson1NW4';
import Lesson1NW5 from '../Lesson1NewWords/Lesson1NW5';
import css from './Lesson1Exercises.module.css';
import Lesson1NW6 from '../Lesson1NewWords/Lesson1NW6';
import Lesson1NW7 from '../Lesson1NewWords/Lesson1NW7';
import Lesson1NW8 from '../Lesson1NewWords/Lesson1NW8';
import Lesson1NW1 from '../Lesson1NewWords/Lesson1NW1';
import commonStyles from '../../../LessonsPage.module.css';
import useLocales from 'hooks/useLocales';

const Lesson1Exercises = () => {
  const { translate } = useLocales();

  return (
    <div>
      {/* 1.1 Озвучка літер алфавіту */}
      <Lesson1Alphabet />

      <h2 className={commonStyles.lessonTask}>
        {translate('lesson1.task2')} Aprenda las reglas de la lectura de las
        letras. Puede añadir las palabras a su propio vocabulario.
      </h2>
      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule1')}</p>
        </div>
      </div>

      <Lesson1NW1 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule2')}</p>
        </div>
      </div>

      <Lesson1NW2 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule3')}</p>
        </div>
      </div>

      <Lesson1NW3 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule4')}</p>
        </div>
      </div>

      <Lesson1NW4 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule5')}</p>
        </div>
      </div>

      <Lesson1NW5 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule6')}</p>
        </div>
      </div>

      <Lesson1NW6 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule7')}</p>
        </div>
      </div>

      <Lesson1NW7 />

      <div className={css.ruleWrap}>
        <div className={css.rule}>
          <PriorityHighIcon sx={{ color: theme.palette.primary.red }} />
          <p>{translate('lesson1.rule8')}</p>
        </div>
      </div>

      <Lesson1NW8 />

      <h2 className={commonStyles.lessonTask}>
        1.5 {translate('lesson1.task3')} Vocabulario. Puede añadir las palabras
        a su propio vocabulario.
      </h2>

      <Lesson1NewWords />
    </div>
  );
};

export default Lesson1Exercises;
