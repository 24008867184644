import { instance } from "./api";

export const createWord = async (body) => {
  const { data } = await instance.post(`words`, body);
  return data;
};

export const getWordByLesson = async (lesson) => {
  const { data } = await instance(`words/lesson/${lesson}`);
  return data;
};

export const getAllWords = async () => {
  const { data } = await instance(`words`);
  return data;
};
