import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './DialogOrdered.module.css';
import { setTryStatus } from '../helpers/setTryStatus';
import CheckAnswerBtn from '../../Buttons/CheckAnswerBtn/CheckAnswerBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateProfileThunk } from 'store/auth/thunks';

const DialogOrdered = ({
  correctWordInContainer1,
  correctWordInContainer2,
  correctWordInContainer3,
  correctWordInContainer4,
  moveWords,
  persons,
  number,
}) => {
  const [allWords] = useState(() =>
    correctWordInContainer2
      .concat(correctWordInContainer4)
      .concat(correctWordInContainer1)
      .concat(correctWordInContainer3)
      // .sort()
      .map((word, index) => ({
        id: index + 1,
        order: index + 1,
        text: word,
        // draggable: true,
      }))
  );

  const [selectedWord, setSelectedWord] = useState(null);

  // Состояние для хранения информации о том, в какой контейнер мы хотим поместить фразу
  const [targetContainer, setTargetContainer] = useState(null);

  const [firstContainer, setFirstContainer] = useState([]);
  const [secondContainer, setSecondContainer] = useState([]);
  const [thirdContainer, setThirdContainer] = useState([]);
  const [fourthContainer, setFourthContainer] = useState([]);

  const [tryCounter, setTryCounter] = useState([
    { id: 1, tryStatus: null },
    { id: 2, tryStatus: null },
    { id: 3, tryStatus: null },
  ]);

  const [, setIsFirstContainerCorrect] = useState(false);
  const [, setIsSecondContainerCorrect] = useState(false);
  const [, setIsThirdContainerCorrect] = useState(false);
  const [, setIsFourthContainerCorrect] = useState(false);

  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [id, setId] = useState(1);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const arrPathname = pathname.split('/');
  const module = useMemo(() => arrPathname[2], [arrPathname]);
  const lesson = useMemo(() => arrPathname[3], [arrPathname]);

  useEffect(() => {
    const isAnswer = user.exercises?.[module]?.[lesson]?.[number]?.containers;

    if (isAnswer) {
      setTryCounter(Object.values(isAnswer)[0].tryCounterData);
    }
  }, [lesson, module, number, tryCounter, user.exercises]);

  const exerciseData = useCallback(
    tryCounterData => {
      return {
        ...user.exercises,
        [module]: {
          ...(user.exercises[module] || {}),
          [lesson]: {
            ...(user.exercises[module]?.[lesson] || {}),
            [number]: {
              ...(user.exercises[module]?.[lesson]?.[number] || {}),
              containers: {
                ...(user.exercises[module]?.[lesson]?.[number]?.containers ||
                  {}),
                userAnswer: {
                  ...(user.exercises[module]?.[lesson]?.[number]?.containers
                    ?.userAnswer || {}),
                  tryCounterData,
                },
              },
            },
          },
        },
      };
    },
    [lesson, module, number, user.exercises]
  );

  const onCheckBtnClick = () => {
    const allWordsInFirstContainer = firstContainer.map(item => item.text);
    const allWordsInSecondContainer = secondContainer.map(item => item.text);
    const allWordsInThirdContainer = thirdContainer.map(item => item.text);
    const allWordsInFourthContainer = fourthContainer.map(item => item.text);

    const isFirstContainerCorrect = correctWordInContainer1.every(word =>
      allWordsInFirstContainer.includes(word)
    );

    const isSecondContainerCorrect = correctWordInContainer2.every(word =>
      allWordsInSecondContainer.includes(word)
    );

    const isThirdContainerCorrect = correctWordInContainer3.every(word =>
      allWordsInThirdContainer.includes(word)
    );

    const isFourthContainerCorrect = correctWordInContainer4.every(word =>
      allWordsInFourthContainer.includes(word)
    );

    if (
      isFirstContainerCorrect &&
      isSecondContainerCorrect &&
      isThirdContainerCorrect &&
      isFourthContainerCorrect
    ) {
      setIsFirstContainerCorrect(true);
      setIsSecondContainerCorrect(true);
      setIsThirdContainerCorrect(true);
      setIsFourthContainerCorrect(true);

      setTryCounter(setTryStatus(id, true));

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: true,
                };
              }
              return item;
            })
          ),
        })
      );
    } else {
      setTryCounter(setTryStatus(id, false));
      setId(prevState => prevState + 1);

      dispatch(
        updateProfileThunk({
          email: user.email,
          exercises: exerciseData(
            tryCounter.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  tryStatus: false,
                };
              }
              return item;
            })
          ),
        })
      );
    }
  };

  useEffect(() => {
    const showAnswer = () => {
      return tryCounter.every(
        item => !item.tryStatus && item.tryStatus !== null
      );
    };

    const checkAnswer = showAnswer();

    if (checkAnswer && !showCorrectAnswer) {
      const correctFirstWord = allWords.filter(
        word => word.text === correctWordInContainer1[0]
      );
      const correctSecondWord = allWords.filter(
        word => word.text === correctWordInContainer2[0]
      );
      const correctThirdWord = allWords.filter(
        word => word.text === correctWordInContainer3[0]
      );
      const correctFourthWord = allWords.filter(
        word => word.text === correctWordInContainer4[0]
      );
      // const correctFifthWord = allWords.filter(
      //   word => word.text === correctWordInContainer5[0]
      // );

      setFirstContainer(correctFirstWord);
      setSecondContainer(correctSecondWord);
      setThirdContainer(correctThirdWord);
      setFourthContainer(correctFourthWord);
      // setFifthContainer(correctFifthWord);

      setIsFirstContainerCorrect(true);
      setIsSecondContainerCorrect(true);
      setIsThirdContainerCorrect(true);
      setIsFourthContainerCorrect(true);

      setShowCorrectAnswer(true);
    }
  }, [
    allWords,
    correctWordInContainer1,
    correctWordInContainer2,
    correctWordInContainer3,
    correctWordInContainer4,
    // correctWordInContainer5,

    showCorrectAnswer,
    tryCounter,
  ]);

  const handleWordClick = word => {
    if (selectedWord && selectedWord.id === word.id) {
      // Если выбранная фраза уже выбрана снова, сбрасываем выбор
      setSelectedWord(null);
      setTargetContainer(null);
    } else if (selectedWord && targetContainer === null) {
      // Если выбранная фраза уже находится в контейнере, удаляем её из контейнера и помещаем обратно в список
      if (firstContainer.some(card => card.id === selectedWord.id)) {
        setFirstContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      } else if (secondContainer.some(card => card.id === selectedWord.id)) {
        setSecondContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      } else if (thirdContainer.some(card => card.id === selectedWord.id)) {
        setThirdContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      } else if (fourthContainer.some(card => card.id === selectedWord.id)) {
        setFourthContainer(prevContainer =>
          prevContainer.filter(card => card.id !== selectedWord.id)
        );
      }
      setSelectedWord(word);
    } else {
      setSelectedWord(word);
    }
  };

  const handleContainerClick = (card, targetContainer) => {
    if (!showCorrectAnswer && selectedWord) {
      const isCardInFirstContainer = firstContainer.some(
        card => card.id === selectedWord.id
      );

      const isCardInSecondContainer = secondContainer.some(
        card => card.id === selectedWord.id
      );

      const isCardInThirdContainer = thirdContainer.some(
        card => card.id === selectedWord.id
      );

      const isCardInFourthContainer = fourthContainer.some(
        card => card.id === selectedWord.id
      );

      if (targetContainer === 'first') {
        setFirstContainer([selectedWord]);
        if (isCardInSecondContainer) {
          setSecondContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInThirdContainer) {
          setThirdContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInFourthContainer) {
          setFourthContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      } else if (targetContainer === 'second') {
        setSecondContainer([selectedWord]);
        if (isCardInFirstContainer) {
          setFirstContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInThirdContainer) {
          setThirdContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInFourthContainer) {
          setFourthContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      } else if (targetContainer === 'third') {
        setThirdContainer([selectedWord]);
        if (isCardInFirstContainer) {
          setFirstContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInSecondContainer) {
          setSecondContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInFourthContainer) {
          setFourthContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      } else if (targetContainer === 'fourth') {
        setFourthContainer([selectedWord]);
        if (isCardInFirstContainer) {
          setFirstContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInSecondContainer) {
          setSecondContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
        if (isCardInThirdContainer) {
          setThirdContainer(prevContainer =>
            prevContainer.filter(card => card.id !== selectedWord.id)
          );
        }
      }
    } else {
      setSelectedWord(card[0]);

      if (selectedWord.id && card.id) {
        const isCardInFirstContainer = firstContainer.some(
          card => card.id === selectedWord.id
        );

        const isCardInSecondContainer = secondContainer.some(
          card => card.id === selectedWord.id
        );

        const isCardInThirdContainer = thirdContainer.some(
          card => card.id === selectedWord.id
        );

        const isCardInFourthContainer = fourthContainer.some(
          card => card.id === selectedWord.id
        );
        if (targetContainer === 'first') {
          setFirstContainer([selectedWord]);
          if (isCardInSecondContainer) {
            setSecondContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInThirdContainer) {
            setThirdContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInFourthContainer) {
            setFourthContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        } else if (targetContainer === 'second') {
          setSecondContainer([selectedWord]);
          if (isCardInFirstContainer) {
            setFirstContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInThirdContainer) {
            setThirdContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInFourthContainer) {
            setFourthContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        } else if (targetContainer === 'third') {
          setThirdContainer([selectedWord]);
          if (isCardInFirstContainer) {
            setFirstContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInSecondContainer) {
            setSecondContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInFourthContainer) {
            setFourthContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        } else if (targetContainer === 'fourth') {
          setFourthContainer([selectedWord]);
          if (isCardInFirstContainer) {
            setFirstContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInSecondContainer) {
            setSecondContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
          if (isCardInThirdContainer) {
            setThirdContainer(prevContainer =>
              prevContainer.filter(card => card.id !== selectedWord.id)
            );
          }
        }
      }
    }
    setSelectedWord(null);
  };

  return (
    <div>
      <div className={style.addWordsToContainer}>
        <div className={style.availableWords}>
          {allWords
            .filter(
              word =>
                !firstContainer.some(card => card.id === word.id) &&
                !secondContainer.some(card => card.id === word.id) &&
                !thirdContainer.some(card => card.id === word.id) &&
                !fourthContainer.some(card => card.id === word.id)
            )
            .map(card => (
              <div
                key={card.id}
                className={`${style.card} ${
                  selectedWord && selectedWord.id === card.id
                    ? style.selected // Применяем стиль, если фраза выбрана
                    : ''
                }`}
                onClick={() => handleWordClick(card)}
              >
                {card.text}
              </div>
            ))}
        </div>

        {/* #1 */}
        <div className={style.container}>
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[0]}</p>
            </div>
            <div className={style.wordContainers}>
              <div className={style.textWrap}>
                <p className={style.text}>{moveWords[0]}</p>
              </div>
            </div>
          </div>

          {/* #2 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[1]}</p>
            </div>

            <div className={style.wordContainers}>
              <div
                className={`${style.wordContainer} ${
                  showCorrectAnswer ? style.correct : style.wordContainer
                }`}
                onClick={() => handleContainerClick(firstContainer, 'first')}
              >
                {firstContainer.map(card => (
                  <div key={card.id} className={style.cardInContainer}>
                    {card.text}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* #3 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[2]}</p>
            </div>

            <div className={style.wordContainers}>
              <div className={style.textWrap}>
                <p className={style.text}>{moveWords[1]}</p>
              </div>
            </div>
          </div>

          {/* #4 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[3]}</p>
            </div>

            <div className={style.wordContainers}>
              <div
                className={`${style.wordContainer} ${
                  showCorrectAnswer ? style.correct : style.wordContainer
                }`}
                onClick={() => handleContainerClick(secondContainer, 'second')}
              >
                {secondContainer.map(card => (
                  <div key={card.id} className={style.cardInContainer}>
                    {card.text}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* #5 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[4]}</p>
            </div>

            <div className={style.wordContainers}>
              <div className={style.textWrap}>
                <p className={style.text}>{moveWords[2]}</p>
              </div>
            </div>
          </div>

          {/* #6 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[5]}</p>
            </div>

            <div className={style.wordContainers}>
              <div
                className={`${style.wordContainer} ${
                  showCorrectAnswer ? style.correct : style.wordContainer
                }`}
                onClick={() => handleContainerClick(thirdContainer, 'third')}
              >
                {thirdContainer.map(card => (
                  <div key={card.id} className={style.cardInContainer}>
                    {card.text}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* #7 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[0]}</p>
            </div>
            <div className={style.wordContainers}>
              <div className={style.textWrap}>
                <p className={style.text}>{moveWords[3]}</p>
              </div>
            </div>
          </div>

          {/* #8 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[1]}</p>
            </div>

            <div className={style.wordContainers}>
              <div
                className={`${style.wordContainer} ${
                  showCorrectAnswer ? style.correct : style.wordContainer
                }`}
                onClick={() => handleContainerClick(fourthContainer, 'fourth')}
              >
                {fourthContainer.map(card => (
                  <div key={card.id} className={style.cardInContainer}>
                    {card.text}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* #9 */}
          <div className={style.wordContainerWrap}>
            <div className={style.persons}>
              <p className={style.textPersons}>{persons[2]}</p>
            </div>

            <div className={style.wordContainers}>
              <div className={style.textWrap}>
                <p className={style.text}>{moveWords[4]}</p>
              </div>
            </div>
          </div>
        </div>
        <CheckAnswerBtn
          tryCounter={tryCounter}
          onCheckBtnClick={onCheckBtnClick}
        />
      </div>
    </div>
  );
};

export default DialogOrdered;
