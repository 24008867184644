import { instance } from './api';

export const getAudio = async body => {
  try {
    const { data } = await instance.post('textToSpeech', body, {
      responseType: 'blob',
    });
    if (data) {
      console.log(data);
      //   const blob = data.blob();
      const blobUrl = URL.createObjectURL(data);
      const audio = new Audio(blobUrl);
      audio.play();
    } else {
      console.log('error');
    }
  } catch (error) {
    console.log(error);
  }
};
